
import styled from "styled-components";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

export const StyledButton = styled(Button)(({ theme }) => ({
    "&.MuiButton-root": {
      backgroundColor: 'rgb(221, 221, 221)',
      textTransform: 'none',
      fontWeight: '600',
      color: 'rgb(72, 72, 72)',
      boxShadow: 'none',
      padding: '9px 16px',
      "&:hover": {
        backgroundColor: 'rgb(221, 221, 221)'
      }
    },
  }))
  
export const StyledConfirmButton = styled(Button)(({ theme }) => ({
    "&.MuiButton-root": {
      backgroundColor: 'rgb(204, 0, 0)',
      textTransform: 'none',
      fontWeight: '600',
      color: 'white',
      padding: '9px 16px',
      "&:hover": {
        backgroundColor: 'rgb(204, 0, 0)'
      }
    },
  }))
  
export const StyledNameCustom = styled(Typography)(({ isActive }) => ({
    "&.MuiTypography-h5": {
      margin: '8px 0px',
      fontSize: '17px',
      lineHeight: '1.268',
      color: 'black',
      textAlign: 'center'
    }
  }))
  
export const StyledName = styled(Typography)(({ custom }) => ({
    "&.MuiTypography-h3": {
      fontSize: '12px',
      color: 'rgb(102, 102, 102)',
      width: custom ? '100%' : '27%',
      fontWeight: 400,
      lineHeight: 1.167
    }
  }))
  
export const StyledDisplayRange = styled(Typography)(({ custom }) => ({
    "&.MuiTypography-body1": {
      fontSize: '12px',
      color: 'rgb(102, 102, 102)',
      width: custom ? '100%' : '48%',
      display: 'flex',
      float: custom ? '' : 'left',
      textAlign: 'left',
      lineHeight: custom ? 0.9 : 1.6,
    }
  }))
  
export const StyledminmaxRange = styled(Typography)(({ custom }) => ({
    "&.MuiTypography-body1": {
      fontSize: '14px',
      color: 'rgb(102, 102, 102)'
    }
  }))
  
export const StyledminmaxValue = styled(Typography)(({ custom }) => ({
    "&.MuiTypography-body1": {
      fontSize: '12px',
      color: 'rgb(102, 102, 102)',
      width: '20%'
    }
  }))
  
export const StyledfilterType = styled(Typography)(({ custom }) => ({
    "&.MuiTypography-h7": {
      margin: '0px 8px',
      fontSize: '12px',
      color: '#666666'
    }
  }))

export const StyledSelect = styled(Select)(({ custom }) => ({
    "&.MuiInput-root": {
      color: 'black',
      width: '115px',
      lineHeight: '1.89em',
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: '2px solid black'
      }
    }
}))

export const StyledTextField = styled(TextField)(({ custom }) => ({
  "& .MuiOutlinedInput-input": {
    color: 'black',
    fontSize: '16px',
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: 'black',
      borderWidth: '1px'
    },
    "&.Mui-focused fieldset": {
      borderColor: '#3f51b5',
      borderWidth: '2px'
    }
  }
}))