/**************************************************************************
 *
 *     Copyright Bain & Company. 2020, 2021
 *
 **************************************************************************/
import React, { Component } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select
} from "@mui/material";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import API from "@aws-amplify/api-rest";
import classnames from "classnames";

import commonUtil from "../../Utils/commonUtil";
import ScreenerTable from "./ScreenerTable";
import FilterComponent from "../Shared/FilterComponent";
import ExportComponent from "../ExportComponent";
import Search from "./Search";
import ErrorDialog from "../Shared/ErrorDialog";
import { updateInitialFilterList } from "../Shared/common";
import { actions as customScreenActions } from '../../slices/custom-screen';
import { actions as searchActions } from '../../slices/search';
import { clearSearchSubject } from '../../Utils/subjects';
import { searchSubject } from '../../Utils/subjects';
import "../../App.css";

const StyledButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-root": {
    backgroundColor: 'rgb(221, 221, 221)',
    textTransform: 'none',
    fontWeight: '600',
    color: 'rgb(72, 72, 72)',
    boxShadow: 'none',
    borderRadius: '4px',
    "&:hover": {
      backgroundColor: 'rgb(221, 221, 221)'
    }
  },
}))

const StyledSearchButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-root": {
    backgroundColor: 'rgb(204, 0, 0)',
    textTransform: 'none',
    fontWeight: '600',
    color: 'white',
    padding: '6px 15px',
    borderRadius: '4px',
    "&:hover": {
      backgroundColor: 'rgb(204, 0, 0)'
    },
    "&:disabled": {
      backgroundColor: 'rgb(221, 221, 221)',
      color: 'rgb(72, 72, 72)',
      opacity: '0.5',
      cursor: 'not-allowed'
    }
  },
}))

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  "&.MuiListSubheader-root": {
    backgroundColor: 'white'
  },
}))

const StyledSelect = styled(Select)(({ custom }) => ({
  "&.MuiOutlinedInput-root": {
    color: 'black',
    backgroundColor: '#dddddd',
    marginRight: '4px',
    "&.Mui-focused fieldset": {
      borderColor: 'white',
      borderWidth: '2px'
    }
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: 'white'
  },
  "& .MuiSelect-select": {
    padding: 8,
  },
}))

const StyledGrid = styled(Grid)(({ groupLength }) => ({
  "&.MuiGrid-root": {
    width: '100% !important',
    padding: '0px'
  },
  "&.MuiGrid-grid-xs-8": {
    flexGrow: '0',
    maxWidth: '82%',
    flexBasis: '82%'
  },
  "&.MuiGrid-grid-xs-4": {
    flexGrow: '0',
    maxWidth: '18%',
    flexBasis: '18%'
  }
}));

const FILTER_TEXT = "Select any additional filters";
// FIXME: Temprorarily solution to have a global state for filters
export let CURRENT_FILTERS = [];

class CompanyResearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFilters: [
        "self_location_region___",
        "self_location_country___",
        "self_sector_sector___",
      ],
      filtersSelectedFromDropdown: [
        "self_location_region___",
        "self_location_country___",
        "self_sector_sector___",
      ],
      columnMapperData: [],
      modifiedFilters: [],
      optionalFiltersToAdd: [],
      filtersWithoutTransformation: [],
      companyName: "",
      selectedFilters: [],
      initialFilterData: [], // Used to reset a singular filters' value to it's initial state
      includeNullFilters: [],
      throwError: false,
      isLoading: true,
      isASFPopOpen: false,
      isApplyActive: false,
      filterState: false,
      isHighlighted: false,
      isExport: false,
      companySearchValue: "",
      typeAheadList: [],
      filterSorting: [
        "self_location_region___",
        "self_location_country___",
        "self_sector_sector___",
      ],
      helpDialogOpen: false,
      helpTabValue: 0,
      resetState: false,
      companyProfileBainIds: [],
      isFilterUpdated: false,
      prevSelectedFilters: [
        "self_location_region___",
        "self_location_country___",
        "self_sector_sector___"
      ]
    };
    this.updateFilterFromChild = this.updateFilterFromChild.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.updateParentCompanyProfileBainIds = this.updateParentCompanyProfileBainIds.bind(this);
    this.initialFilters = [
      "self_location_region___",
      "self_location_country___",
      "self_sector_sector___",
    ];
    this.search$ = searchSubject.subscribe(this.searchObserver);
  }

  async componentDidMount() {
    await this.populateColumnMapperData();
    await this.resetFilters();
  };

  componentWillUnmount() {
    this.search$.unsubscribe();
  }

  searchObserver = async ({ text, isUniq = false }) => {
    if (text) {
      await this.props.fetchBainIds({
        searchText: _.trim(text),
        unique_company: isUniq,
      });
    }

    await this.applyFilters(true);
  }

  fetchFilterData = async () => {
    const { currency } = this.props;
    // only start with region country
    let data;
    let reqBody = this.buildFilterRequest();
    try {
      data = await commonUtil.fetchFiltersData(reqBody, `filters?currency=${currency}`);
    } catch (err) {
      await this.setState({ throwError: true });
    }
    let filterList = [];
    for (const [key, value] of Object.entries(data)) {
      let returnObj = {};
      returnObj[key] = value;

      filterList.push(returnObj);
    }
    // Set state of full filter list before updating the selected filters
    // Only set this value on first load
    // if(!this.state.filtersWithoutTransformation.length > 0) {
    this.setState({ filtersWithoutTransformation: filterList }, () => {
      return;
    });
    // }

    return filterList;
  };

  buildFilterRequest() {
    let { selectedFilters, activeFilters } = this.state;
    let returnObj = {};
    // first pass what filters to get
    returnObj["include_filters"] = activeFilters;
    // next pass the values from current filters
    if (selectedFilters) {
      selectedFilters.forEach((filter) => {
        // check for numerical
        let key = Object.keys(filter)[0];
        let value = Object.values(filter)[0];
        if (activeFilters.includes(key)) {
          if (Array.isArray(value)) {
            if (value.length != 0 && !value.includes("All")) {
              returnObj[key] = value;
            }
          } else {
            returnObj[key] = value;
          }
        }
      });
    }

    return returnObj;
  }

  async updateFilterFromChild(title, value, includeNull) {
    // update nulls first
    let currentNullFilters = this.state.includeNullFilters;
    if (includeNull) {
      if (!currentNullFilters.includes(title[0])) {
        currentNullFilters.push(title[0]);
      }
    } else {
      if (currentNullFilters.includes(title[0])) {
        currentNullFilters = currentNullFilters.filter(
          (item) => item !== title[0]
        );
      }
    }

    // handle loading here
    this.setState({ isLoading: true }, () => {
      return;
    });
    let currentFilters = this.state.selectedFilters;
    let updatedFilter;
    // Update the according filter from child by keyname
    currentFilters.forEach((filter) => {
      if (title in filter) {
        filter[title] = value;
        updatedFilter = Object.assign({}, filter);
      }
    });
    // Remove old filter
    currentFilters = currentFilters.filter((item) => !(title in item));
    // update with new filter
    currentFilters.push(updatedFilter);
    // Keep same sorting here since just updating filter value
    let sortingFilter = this.state.filterSorting;
    currentFilters.sort(function (a, b) {
      a = Object.keys(a)[0];
      b = Object.keys(b)[0];
      return sortingFilter.indexOf(a) - sortingFilter.indexOf(b);
    });

    // update Parent state
    this.setState(
      {
        selectedFilters: currentFilters,
        isLoading: false,
        includeNullFilters: currentNullFilters,
      },
      () => {
        return;
      }
    );

    CURRENT_FILTERS = currentFilters;
  }

  updateFilterFromSelection = async (filterNames) => {
    // Set to true only if user clicks on any of the checbox
    this.setState({ isFilterUpdated: true });
    this.setState({ filtersSelectedFromDropdown: filterNames });
  };

  updateFilters = async () => {
    // Called when you make a change to the active filters select field
    // Passed in values to active filters so that the fetch filter data func will fetch data for those filters
    let filterNames = this.state.filtersSelectedFromDropdown;
    let currentFilterValues = this.state.selectedFilters;
    let allEnabledFiltersBefore = this.state.activeFilters;
    let prevSelected = this.state.prevSelectedFilters;
    // first check if any filters need to be deleted
    let filtersToRemove = allEnabledFiltersBefore.filter(
      (x) => !filterNames.includes(x)
    );

    if (filtersToRemove.length > 0) {
      // first remove from new filters
      filterNames = filterNames.filter(function (value) {
        return !filtersToRemove.includes(value);
      });

      // next remove from current filter values
      currentFilterValues = currentFilterValues.filter(function (value) {
        return !filtersToRemove.includes(Object.keys(value)[0]);
      });

      this.props.setHasChange(false);
    }

    // Code to check whether the set of filters before opening the dropdown is same as after closing the dropdown
    let shouldFetchFilterData = (filterNames.length !== prevSelected.length);
    let isEqual = false;
    if (filterNames.length === prevSelected.length) {
      let currentFilterSet = new Set(filterNames);
      let prevFilterSet = new Set(prevSelected);
      isEqual = filterNames.every(item => prevFilterSet.has(item)) && prevSelected.every(item => currentFilterSet.has(item));
    }

    if (this.state.isFilterUpdated && shouldFetchFilterData && !isEqual) {
      await this.setState(
        {
          isLoading: true,
          filterSorting: filterNames,
          activeFilters: filterNames,
        },
        () => {
          return;
        }
      );

      let filterList = await this.fetchFilterData();
      let currentNullList = [...this.state.includeNullFilters];
      // iterate through returned filters
      filterList.forEach((item) => {
        let key = Object.keys(item)[0];
        if (this.state.activeFilters.includes(key)) {
          // first add to null values if the filter is new and it is a numerical filter
          if (
            !Array.isArray(item[key]) && !allEnabledFiltersBefore.includes(key) &&
            !currentNullList.includes(key)
          ) {
            currentNullList.push(key);
          }
          // prevent new data from overwriting active filter values
          if (!allEnabledFiltersBefore.includes(key)) {
            currentFilterValues.push(item);
          }
        }
      });

      //sort results
      let sortingFilter = this.state.filterSorting;
      currentFilterValues.sort(function (a, b) {
        a = Object.keys(a)[0];
        b = Object.keys(b)[0];
        return sortingFilter.indexOf(a) - sortingFilter.indexOf(b);
      });

      // Finally update current filters
      this.setState(
        { selectedFilters: currentFilterValues, isLoading: false, includeNullFilters: currentNullList },
        () => {
          return;
        }
      );
      CURRENT_FILTERS = currentFilterValues;

      // Add any new filter values to the intial list
      let initialFilterData = await updateInitialFilterList(
        filterNames,
        currentFilterValues,
        this.state.initialFilterData
      );
      this.setState({ initialFilterData: initialFilterData }, () => {
        return;
      });
    }

    this.setState({ isFilterUpdated: false, prevSelectedFilters: filterNames });
  };

  resetFilters = async () => {
    clearSearchSubject.next();

    // ADD INTIAL FILTERS HERE
    // Reset state for filter sorting
    let initialFilters = this.initialFilters;

    this.setState({ isLoading: true, selectedFilters: [] }, () => {
      return;
    });
    CURRENT_FILTERS = [];

    this.props.reset();

    let filterList = await this.fetchFilterData();
    // Remove all except region & country
    let initialFilterList = [];
    // let optionalFilterList = []
    filterList.forEach((item) => {
      // Only 1 key in each object so grab the first
      let key = Object.keys(item)[0];
      // optionalFilterList.push(key)
      if (initialFilters.includes(key)) {
        initialFilterList.push(item);
      }
    });

    //sort results
    initialFilterList.sort(function (a, b) {
      a = Object.keys(a)[0];
      b = Object.keys(b)[0];
      return initialFilters.indexOf(a) - initialFilters.indexOf(b);
    });

    let initialFilterData = JSON.parse(JSON.stringify(initialFilterList));

    this.setState(
      {
        selectedFilters: initialFilterList,
        initialFilterData: initialFilterData,
        isLoading: false,
        activeFilters: initialFilters,
        filtersSelectedFromDropdown: initialFilters,
        typeAheadList: [],
        includeNullFilters: [],
        prevSelectedFilters: [
          "self_location_region___",
          "self_location_country___",
          "self_sector_sector___"
        ],
        resetState: true,
      },
      () => {
        this.applyFilters(true);
      }
    );

    this.props.setSearchQueries([]);
    this.props.setBainIds([]);

    CURRENT_FILTERS = initialFilterList;
  };

  async applyFilters(isReset) {
    if (this.state.isApplyActive || isReset === true) {
      try {
        await this.child.getTableData(true);
        this.props.setHasChange(false);
      } catch (error) {
        console.log("error occured while applying filters", error);
        this.setState({ throwError: true });
      }
    }
  }

  mapOptionalFilters = (optionalFilters) => {
    // Builds a list of json objects
    // Sorted by display rank
    let displayHeaders = []
    optionalFilters.forEach(opFilter => {
      let filterHeader = opFilter['Display Header']
      let returnObj = {
        [filterHeader]: []
      }
      if (filterHeader != null && !displayHeaders.some(dh => Object.keys(dh)[0] === filterHeader)) {
        displayHeaders.push(returnObj)
      }
    })


    optionalFilters.forEach(opFilter => {
      let displayName = opFilter["Display Name"]
      let displayRank = opFilter["Display Rank"]
      let displayHeader = opFilter["Display Header"]
      let backendName = opFilter["Backend Name"]

      let filterObj = {
        "Display Name": displayName,
        "Display Rank": displayRank,
        "Backend Name": backendName
      }

      displayHeaders.forEach(dh => {
        let key = Object.keys(dh);
        if (displayHeader == key && displayHeader != null) {
          dh[key].push(filterObj)
        }
      })
    });

    return displayHeaders;
  }

  populateColumnMapperData = async () => {
    const { currency } = this.props;
    let response = {};
    try {
      response = await API.get("CDPAPI", `/column_mapper?currency=${currency}`).then((response) => {
        const data = response.data;

        // build optional filters here with FILTER_COLUMN attribute
        let allFilterNames = [];
        response["data"].forEach((item) => {
          if (item["FILTER_COLUMN"] === "Y") {
            allFilterNames.push(item);
          }
        });
        let mappedOptionalFilters = this.mapOptionalFilters(allFilterNames);

        this.props.setColumnMapper(data);

        this.setState(
          {
            columnMapperData: data,
            optionalFiltersToAdd: mappedOptionalFilters,
          },
          () => {
            return;
          }
        );
      });
    } catch (err) {
      console.log(err)
      await this.setState({ throwError: true });
    }
  };

  setHasChange = (hasChange) => {
    this.props.setHasChange(hasChange);
  };

  setFilterState = async (bool) => {
    this.setState({ filterState: bool });
  };

  setStateFromChild = (data) => {
    if (this.state.searchState !== data) {
      this.setState({
        companyName: data.companyName,
        typeAheadList: data.typeAheadList,
        helpTabValue: data.helpTabValue,
        isLoading: data.isLoading,
        resetState: data.resetState,
        searchState: data,
      });
    }
  };

  updateParentCompanyProfileBainIds = (ids) => {
    this.setState({
      companyProfileBainIds: ids.map(String)
    }, () => {
      return;
    });
  };

  buildOptionalFilters = (data, filtersSelectedFromDropdown) => {
    let menuItems = (Object.values(data)[0].map((subData, index) => (
      <MenuItem key={subData["Backend Name"]} value={subData["Backend Name"]}
        style={{ paddingTop: "0px", paddingBottom: "0px" }} id={`menu-item-custom-${index}`}>
        <ListItemText primary={subData["Display Name"]} id={`list-${subData["Display Name"].replace(/\s/g, "")}`} />
        <Checkbox checked={filtersSelectedFromDropdown.indexOf(subData["Backend Name"]) > -1} color="primary"
          id={`checkbox-${subData["Display Name"].replace(/\s/g, "")}`} />
      </MenuItem>
    )));

    return (
      [
        <StyledListSubheader value={data["Backend Name"]} className="filter-sub-headers" id="list-sub-header">{Object.keys(data)[0]}</StyledListSubheader>,
        menuItems
      ]
    )
  }

  handleFilterRender = ({
    filtersData,
    filtersWithoutTransformation,
    initialFilterData,
    columnMapperData,
    includeNullFilters
  }) => {
    const filterValues = Object.values(filtersData);
    const filterKeys = Object.keys(filtersData);

    return (
      <FilterComponent
        filterData={filterValues[0]}
        title={filterKeys}
        key={filterKeys[0]}
        initialFilters={filtersWithoutTransformation}
        initialFilterData={initialFilterData}
        setHasChange={this.props.setHasChange}
        setFilterState={this.setFilterState}
        updateParent={this.updateFilterFromChild}
        columnMapperData={columnMapperData}
        includeNull={includeNullFilters.includes(filterKeys[0])}
      />
    )
  }

  handleSearchClick = () => {
    const { searchQueries, searchText, isLuceneQuery } = this.props;
    const preparedText = _.trim(searchText);

    if (!preparedText) return;

    const result = preparedText ? [...searchQueries, ...preparedText.split('AND')] : searchQueries;

    searchSubject.next({
      text: isLuceneQuery ? result.join(' AND ') : preparedText,
    });

    if (isLuceneQuery) {
      this.props.setSearchText('');
    }

    if (isLuceneQuery && preparedText) {
      this.props.setSearchQueries(result);
    }
  }

  render() {
    const {
      selectedFilters,
      initialFilterData,
      isLoading,
      filtersWithoutTransformation,
      optionalFiltersToAdd,
      includeNullFilters,
      columnMapperData,
      filtersSelectedFromDropdown,
      throwError,
      resetState,
    } = this.state;
    const { isMidtierLoading, bainIds } = this.props;
    const isSearchDisabled = !this.props.hasChange || isMidtierLoading;

    const filterData = {
      filtersWithoutTransformation,
      initialFilterData,
      columnMapperData,
      includeNullFilters
    };

    return (
      <div>
        <Dialog
          open={this.state.isExport}
          onClose={() => this.setState({ isExport: false })}
          id="dialog-export-research"
        >
          <DialogTitle sx={{ backgroundColor: 'white', color: 'black', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '115px' }}>
            <Typography><b>Export</b></Typography>
            <Typography>
              Would you like to export and download your results? Please note this
              launches a new pop-up/window. Please ensure pop-ups are enabled for
              this site.
            </Typography>
          </DialogTitle>
          <DialogActions sx={{ width: '570px', backgroundColor: 'white', color: 'black', padding: '15px' }}>
            <Button
              variant="outline"
              color="primary"
              onClick={() => this.setState({ isExport: false })}
              id="cancel-export-research"
            >
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={() => this.exportTableauData()} id="export-research">
              Export
            </Button>
          </DialogActions>
        </Dialog>
        <ErrorDialog throwError={throwError} />
        {/* FULL PAGE WRAPPER BOX */}
        <Box mx={3} my={2}>
          <LoadingOverlay
            active={isLoading}
            spinner
            text="Loading..."
            styles={{
              overlay: (base) => ({
                ...base,
                zIndex: "9999",
              }),
            }}
          >
            <Box sx={{ display: "flex" }} mx={2}>
              <Box color="#666666" flex="90%" ml={2}>
                <Typography
                  variant="h6"
                  id="Custom-screen"
                  sx={{ fontSize: '20px', letterSpacing: '0.12px' }}
                >
                  <b>Custom screen</b>
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ letterSpacing: '0.01071em' }}
                >
                  Use this screen to find a specific company of interest or to
                  create custom queries. Start by typing a company name or
                  keyword and click “add more” to add more fields to your query.
                </Typography>
              </Box>
              <Box color="#666666" flex="none">
                <ExportComponent
                  selectedFilters={selectedFilters}
                  includeNullFilters={includeNullFilters}
                  bainIds={bainIds}
                  isCompanyProfile={false}
                />
              </Box>
            </Box>
            <Box id="company-filters" sx={{ display: "flex", label: "Title here" }} mt={1} ml={4}>
              {/* Change the repeat variable if you want more/less filters per row */}
              <StyledGrid container direction="row" spacing={1} className="grid-container">
                {/* Company Search Text Box Starts Here */}
                <StyledGrid item xs={8} zeroMinWidth>
                  <div className="flex flex-wrap flex-col mt-2">
                    <div className="flex items-start">
                      <Search
                        handleChange={this.setStateFromChild}
                        resetState={resetState}
                        setHasChange={this.props.setHasChange}
                      />
                      <div className="flex gap-1">
                        <StyledSelect
                          multiple
                          className="add-more-filters"
                          value={filtersSelectedFromDropdown}
                          displayEmpty={true}
                          renderValue={() => 'Filters'}
                          onChange={(e) => this.updateFilterFromSelection(e.target.value)}
                          onClose={() => this.updateFilters()}
                          inputProps={{
                            name: "filters",
                            id: "add-more-filters-input",
                            style: { fontSize: 14 },
                          }}
                        >
                          <MenuItem value="" disabled id="menu-item-custom">
                            <ListItemText primary={FILTER_TEXT} id="list-item-custom" />
                          </MenuItem>
                          {/* Set this as the initial data so that the data doesn't dissapear when unchecking it */}
                          {optionalFiltersToAdd.map((data) => (
                            this.buildOptionalFilters(data, filtersSelectedFromDropdown)
                          ))}
                        </StyledSelect>

                        <StyledButton onClick={(e) => this.resetFilters()} id="custom-reset-button">
                          Clear
                        </StyledButton>

                        <Button
                          id="custom-search-button"
                          disabled={isSearchDisabled}
                          onClick={this.handleSearchClick}
                          className={classnames('px-3 ml-4', {
                            'bg-[#ddd]': isSearchDisabled,
                            'bg-[#cc0100] text-white': !isSearchDisabled,
                          })}
                        >
                          Search
                        </Button>
                      </div>
                    </div>
                    <div className="flex flex-wrap">
                      {selectedFilters?.map((filtersData, idx) => (
                        <div
                          key={idx}
                          className="flex justify-center p-2 mt-2"
                        >
                          {this.handleFilterRender({ ...filterData, filtersData })}
                        </div>
                      ))}
                    </div>
                  </div>
                </StyledGrid>
                {/* Filters End here */}
              </StyledGrid>

            </Box>
            {/* Data Table Starts Here */}
            <Box borderTop="1px solid #dddddd" my={2} mx={[1, 2, 3, 4]}>
              <ScreenerTable
                filters={selectedFilters}
                bainIds={bainIds ? bainIds : []}
                columnMapperData={columnMapperData}
                includeNullFilters={includeNullFilters}
                childRef={(ref) => (this.child = ref)}
                updateParent={this.updateParentCompanyProfileBainIds}
              />
            </Box>
          </LoadingOverlay>
        </Box>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    isMidtierLoading: state.customScreen.isLoading,
    currency: state.customScreen.currency,
    hasChange: state.search.hasChange,
    bainIds: state.search.bainIds.data,
    searchQueries: state.search.searchQueries,
    searchText: state.search.searchText,
    isLuceneQuery: state.search.isLuceneQuery,
  }),
  {
    reset: customScreenActions.reset,
    setColumnMapper: customScreenActions.setColumnMapper,
    fetchBainIds: searchActions.fetchBainIds,
    setHasChange: searchActions.setHasChange,
    setSearchText: searchActions.setSearchText,
    setSearchQueries: searchActions.setSearchQueries,
    setBainIds: searchActions.setBainIds,
  },
)(CompanyResearch);
