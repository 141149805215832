/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React, { Component } from "react";
import Button from '@mui/material/Button';
import { CustomRangeContent } from "../SelectedFiltersComponent";
import styled from "styled-components";

const StyledButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-root": {
    backgroundColor: 'rgb(204, 0, 0)',
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'white',
    padding: '0px 10px',
    borderRadius: '16px',
    marginRight: '5px',
    marginBottom: '10px',
    "&:hover": {
      backgroundColor: 'rgb(204, 0, 0)'
    }
  },
}))
class SelectedFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let filter = this.props.filter;
    let override = this.props.override;
    if (filter) {
      let filterName = Object.keys(filter)[0];
      let filterVals = Object.values(filter)[0];
      let filterVariant = override ? "primary" : "secondary"

      if (Array.isArray(filterVals)) {
        let formattedSelectString = filterVals.join(", ");
        // Select Type Filter
        if (formattedSelectString.length > 75) {
          return (
            <StyledButton id="styled-button-1">
              {filterName} - {filterVals.length} Selected
            </StyledButton>
          );
        } else {
          return (
            <StyledButton id="styled-button-2">{filterName} - {formattedSelectString}</StyledButton>
          );
        }
      } else {
        // Numeric Type Filter
        return (
          <StyledButton id="styled-button-3">
            {filterName} - {" "}
            &nbsp;
            {
              <CustomRangeContent
                rangeData={filterVals}
                numericType={""}
                rangeStyleType={"Standard"}
              />
            }
          </StyledButton>
        );
      }
    }
  }
}

export default SelectedFilter;
