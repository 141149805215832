import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  secrets: {},
};

export const {
  reducer, actions,
} = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setSecrets: (state, { payload }) => {
      state.secrets = payload;
    },
  },
});
