/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React, { Component } from "react";
import { Card, CardActions, CardContent, Box, Grid } from '@mui/material';
import "../App.css";
import { sectorScreenersData } from "../Data/sectorScreeners";
import API from "@aws-amplify/api-rest";
import { StyledButton, StyledTitle, StyledScreenerTitle, StyledScreenerBody, StyledCustomScreener } from './savedScreenersStyles'
class SavedScreeners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      throwError: false,
      customScreeners: null,
    };
  }

  fetchSavedScreeners = async () => {
    let response = {};
    try {
      response = await API.get("CDPAPI", "/screeners/custom").then(
        (response) => {
          console.log("RESPONSE: ", response);

          this.setState({ customScreeners: response }, () => {
            return;
          });
        }
      );
    } catch (err) {
      await this.setState({ throwError: true });
    }
  };

  routeToCustomScreener = (selectedScreener) => {
    const { history } = this.props;
    if (selectedScreener) history.push(`/Screener/${selectedScreener}`);
  };

  componentDidMount = async () => {
    this.setState({ dataToRender: sectorScreenersData });
    await this.fetchSavedScreeners();
  };

  render() {
    let { customScreeners } = this.state;
    if (!customScreeners) {
      return <h1>Loading...</h1>;
    } else {
      return (
        <Box m={3} position="relative" height="100%" data-testid='savedScreenersComponent' id='saved-screeners-component'>
          <Box sx={{display: "flex"}} mb={2} id='box-1'>
            <Box flex="4" id='box-2'>
              <StyledTitle variant="h3" my={1}
                id='saved-screeners'
              >
                Saved Screeners
              </StyledTitle>
            </Box>
          </Box>
          <Grid container justify="left" spacing={6} id='grid-container'>
            {customScreeners &&
              customScreeners.map((customScreener, screenerIndex) => (
                <Grid id='grid-item' item>
                  <Card
                    variant="outlined"
                    id='card'
                    sx={{ width: 375, 
                          height: '230px', 
                          padding: '24px', 
                          backgroundColor: 'white', 
                          color: 'rgb(102, 102, 102)', 
                          borderRadius: '16px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          boxShadow: 'rgb(47 48 50 / 14%) 0px 2px 8px' }}
                  >
                    <CardContent sx={{ padding: '0px' }} id='saved-card-content'>
                    <StyledScreenerTitle variant="h5" mb={2}
                      id={`saved-${customScreener.ScreenerData.pageName}`}
                    >
                      {customScreener.ScreenerData.pageName}
                    </StyledScreenerTitle>
                    <StyledScreenerBody
                      variant="body1"
                      mb={10}
                      id='description'
                    >
                      {customScreener.ScreenerDescription}
                    </StyledScreenerBody>
                    </CardContent>
                    <CardActions id='saved-card-actions'>
                    <Box sx={{display: "flex", justifyContent:"space-between", width: '100%'}} id='box-3'>
                    <StyledCustomScreener variant="h6" mb={2}
                        id='custom-screeners'
                      >
                        Custom Screeners
                      </StyledCustomScreener>
                      <StyledButton
                        id={`screener-${screenerIndex}`}
                        onClick={(e) => {
                            this.routeToCustomScreener(
                                customScreener.ScreenerName
                            );
                          }}
                      >
                        Explore
                      </StyledButton>
                    </Box>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Box>
      );
    }
  }
}

export default SavedScreeners;
