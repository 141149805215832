/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React, { Component } from "react";
import { ThemeProvider } from '@mui/material/styles';

import { GlobalCss } from './globalStyle';
import { Main } from './Components/Main';
import Header from "./Components/Header";
import Routes from "./Routes";
import { theme } from "./theme"
import { CaseCodeDialog } from './Components/Dialogs/CaseCodeDialog';
import { TooManyCompaniesDialog } from './Components/Dialogs/CompanyOverview/TooManyCompaniesDialog';
import { CompanyOverviewDialog } from './Components/Dialogs/CompanyOverview/CompanyOverviewDialog';
import { AdvancedSearchDialog } from './Components/Dialogs/AdvancedSearchDialog';
import { DeleteQueryDialog } from './Components/Dialogs/DeleteQueryDialog';
import CompanyProfileView from './Components/CompanyResearch/CompanyProfile/CompanyProfileView';

import awsService from "./awsService";

import "./App.css";

awsService.initializeAws();

class App extends Component {
  render() {
    return (
      <Main>
        <ThemeProvider theme={theme}>
          <Header></Header>
          <Routes />

          {/** Render modals here */}
          <CompanyOverviewDialog />
          <TooManyCompaniesDialog />
          <CaseCodeDialog />
          <AdvancedSearchDialog />
          <DeleteQueryDialog />
          <CompanyProfileView />
          {/** Render modals here */}

          <GlobalCss />
        </ThemeProvider>
      </Main>
    );
  }
}

export default App;
