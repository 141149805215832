/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React, { Component, Fragment } from "react";
import Typography from '@mui/material/Typography';
import styles from "../../../App.css"
import { Grid, Paper, TableContainer } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { numberFormatterCustom, dataNotAvailable } from "../../Shared/common";
import styled from "styled-components";

const StyledPaper = styled(Paper)`
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

const StyledTypography = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-body1": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fafafa",
    color: "#CC0000",
    marginTop: "10px",
    fontSize: "14px"
  }
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
  "&.MuiGrid-grid-xs-6": {
    flexGrow: '0',
    maxWidth: '50%',
    flexBasis: '50%',
  },
  "&.MuiGrid-grid-xs-3": {
    flexGrow: '0',
    maxWidth: '25%',
    flexBasis: '25%',
    paddingBottom: '4px'
  }
}))

class M_n_ATab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tablesData: [],
      showCompaniesInPortfolio: false
    }
  }

  formatNumber(val) {
    if (!val) {
      return dataNotAvailable()
    }
    else if (isNaN(val)) {
      return val
    } else {
      return numberFormatterCustom(val)
    }
  }

  formatHeader(val) {
    return val.replace(/_/g, ' ')
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }

  buildMASummary(rowData) {
    if (rowData) {
      return (
        <StyledGrid className="w-1/2">
          <StyledGrid className="w-1/2" item xs={6}>
            <StyledTypography id="portfolio-overview">
              PORTFOLIO OVERVIEW
            </StyledTypography>
          </StyledGrid>
          <StyledPaper style={{ minHeight: "87%" }}>
            <Typography className={styles.tabField} id="current-portfolio">
              <b>Current Portfolio Count:</b>{" "}
              {rowData['current_portfolio_count'] ? rowData['current_portfolio_count'].toFixed(0) : dataNotAvailable()}
            </Typography>
            <Typography className={styles.tabField} id="exited-portfolio">
              <b>Exited Portfolio Count:</b>{" "}
              {rowData['exited_portfolio_count'] ? rowData['exited_portfolio_count'].toFixed(2) : dataNotAvailable()}
            </Typography>
            <Typography className={styles.tabField} id="average-age">
              <b>Current Portfolio Average Age:</b>{" "}
              {rowData['current_portfolio_avg_age'] ? rowData['current_portfolio_avg_age'].toFixed(2) : dataNotAvailable()}
            </Typography>
            <Typography className={styles.tabField} id="holding-period">
              <b>Holding Period Average:</b>{" "}
              {rowData['holding_period_avg'] ? rowData['holding_period_avg'].toFixed(2) : dataNotAvailable()}
            </Typography>
            <Typography className={styles.tabField} id="standard-period">
              <b>Holding Period Standard:</b>{" "}
              {rowData['holding_period_std'] ? rowData['holding_period_std'].toFixed(2) : dataNotAvailable()}
            </Typography>
          </StyledPaper>
        </StyledGrid>
      )
    }
  }

  buildDataTable(data) {
    let columns = []
    let rows = []
    let title = ''

    Object.entries(data).forEach(dataPoint => {
      title = dataPoint[0].toUpperCase()
      // create columns
      if (title == 'TARGET_TRANSACTIONS') {
        let columnList = ['entry_date', 'exit_date', 'buyer_name', 'transaction_value', 'ownership_pct', 'currently_in_portfolio'] // missing sector
        title = "TRANSACTIONS AS TARGET"
        Object.keys(dataPoint[1][0]).forEach(columnName => {
          if (columnList.includes(columnName)) {
            let column = {
              field: columnName,
              headerName: this.formatHeader(columnName),
              width: 200,
              renderCell: (params) => {
                return (
                  <span>{params["value"] ? this.formatNumber(params["value"]) : dataNotAvailable()}</span>
                )
              }
            }
            if (!columns.includes(column)) {
              columns.push(column)
            }
          }
        })
      } else if (title == 'BUYER_TRANSACTIONS') {
        title = "TRANSACTIONS AS ACQUIRER"
        Object.keys(dataPoint[1][0]).forEach(columnName => {
          let columnList = ['entry_date', 'exit_date', 'company_name', 'transaction_value', 'ownership_pct', 'currently_in_portfolio'] // missing sector
          if (columnList.includes(columnName)) {
            let column = {
              field: columnName,
              headerName: this.formatHeader(columnName),
              width: 200,
              renderCell: (params) => {
                return (
                  <span>{params["value"] ? this.formatNumber(params["value"]) : dataNotAvailable()}</span>
                )
              }
            }
            if (!columns.includes(column)) {
              columns.push(column)
            }
          }
        })
      } else if (title == 'BUYER_SUMMARY') {
        title = "ACQUISITIONS AND DIVESTITURES"
        Object.keys(dataPoint[1][0]).forEach(columnName => {
          let columnList = ['acquisitions_last_1yr', 'acquisitions_last_3yr', 'acquisitions_last_5yr', 'sales_last_1yr', 'sales_last_3yr', 'sales_last_5yr']
          if (columnList.includes(columnName)) {
            let column = {
              field: columnName,
              headerName: this.formatHeader(columnName),
              width: 200,
              renderCell: (params) => {
                return (
                  <span>{params["value"] ? params["value"] : dataNotAvailable()}</span>
                )
              }
            }
            if (!columns.includes(column)) {
              columns.push(column)
            }
          }
        })
      }
      // populate rows
      let i = 1;
      dataPoint[1].forEach(rowData => {
        if (rowData['bain_id_buyer'] != '') {
          if (!rows.includes(rowData)) {
            rows.push(data = {
              id: i,
              ...rowData,
            })
          }
        }
        i++;
      })
    })

    if (title == 'ACQUISITIONS AND DIVESTITURES') {
      let acqCols = ['acquisitions_last_1yr', 'acquisitions_last_3yr', 'acquisitions_last_5yr']
      let divCols = ['sales_last_1yr', 'sales_last_3yr', 'sales_last_5yr']
      // Return both tables here with filtered columns
      let divesturesCols = columns.filter(function (x) {
        return acqCols.indexOf(x['field']) < 0;
      });
      let acquistionCols = columns.filter(function (x) {
        return divCols.indexOf(x['field']) < 0;
      });
      return (
        <div>
          <Grid container spacing={1} style={{ marginBottom: "20px" }}>
            {this.buildMASummary(rows[0])}
            <StyledGrid className="w-1/2 p-0 pl-2" item>
              <StyledGrid className="w-1/2" item xs={6}>
                <StyledTypography id="acquisitions">
                  ACQUISITIONS
                </StyledTypography>
              </StyledGrid>
              <TableContainer component={StyledPaper}>
                <DataGridPro
                  rows={rows}
                  columns={acquistionCols}
                  autoHeight={true}
                  rowHeight={35}
                  width={"auto"}
                  rowsPerPageOptions={[]}
                  hideFooterPagination={true}
                  hideFooter={true}
                  disableColumnPinning={true}
                />
              </TableContainer>
              <StyledGrid className="w-1/2" item xs={6}>
                <StyledTypography id="divestitures">
                  DIVESTITURES
                </StyledTypography>
              </StyledGrid>
              <TableContainer component={StyledPaper}>
                <DataGridPro
                  rows={rows}
                  columns={divesturesCols}
                  autoHeight={true}
                  rowHeight={35}
                  width={"auto"}
                  rowsPerPageOptions={[]}
                  hideFooterPagination={true}
                  hideFooter={true}
                  disableColumnPinning={true} />
              </TableContainer>
            </StyledGrid>
          </Grid>
        </div>
      )
    } else {
      return (
        <Grid container spacing={1} style={{ marginBottom: "20px" }}>
          <StyledGrid item xs={3}>
            <StyledTypography id={title}>
              {title}
            </StyledTypography>
          </StyledGrid>
          <TableContainer component={StyledPaper}>
            <DataGridPro
              rows={rows}
              columns={columns}
              autoHeight={true}
              rowHeight={35}
              width={"auto"}
              disableColumnPinning={true} />
          </TableContainer>
        </Grid>
      )
    }
  }

  render() {
    const ma_data = this.props.m_n_a;

    if (ma_data != undefined && Object.entries(ma_data).length > 0) {
      const maArray = Object.entries(ma_data);

      return (
        <div>
          {maArray.map((data, idx) => (
            <Fragment key={idx}>
              {this.buildDataTable(data[1])}
            </Fragment>
          ))}
        </div>
      );
    } else {
      return (
        <div>
          Loading data...
        </div>
      )
    }
  }
};

export default M_n_ATab;