
import styled from "styled-components";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export const StyledButton = styled(Button)`
  background-color: rgb(204, 0, 0);
  font-size: 14px;
  font-weight: bold;
  max-height: 35px; 
  color: white;
  text-transform: none;
  :hover {
    background-color: rgb(204, 0, 0);
  }
`;

export const StyledTitle = styled(Typography)(({ isActive }) => ({
  "&.MuiTypography-h3": {
    color: '#666666',
    whiteSpace: 'normal',
  }
}))

export const StyledScreenerTitle = styled(Typography)(({ isActive }) => ({
  "&.MuiTypography-h5": {
    whiteSpace: 'normal',
    fontWeight: '600',
    letterSpacing: '0.15px',
  }
}))

export const StyledScreenerBody = styled(Typography)(({ isActive }) => ({
  "&.MuiTypography-body1": {
    whiteSpace: 'normal',
    color: '66666',
    fontWeight: '400',
    letterSpacing: '0.15px',
    marginBottom: '0px'
  }
}))

export const StyledCustomScreener = styled(Typography)(({ isActive }) => ({
  "&.MuiTypography-h6": {
    color: '#0484E7',
    whiteSpace: 'normal',
    display: 'block',
    marginTop: '6px',
    letterSpacing: '0.15px'
  }
}))