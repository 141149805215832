/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React, { Component } from "react";
import { connect } from "react-redux";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import { Box, FormControlLabel, Checkbox } from '@mui/material';
import { Close, KeyboardArrowDown } from '@mui/icons-material';
import _, { isEqual, min, wrap } from "lodash";
import { CustomRangeContent } from "../SelectedFiltersComponent";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Tooltip from '@mui/material/Tooltip';
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import '../../App.css';
import {
  StyledButton,
  StyledConfirmButton,
  StyledNameCustom,
  StyledName,
  StyledDisplayRange,
  StyledminmaxRange,
  StyledminmaxValue,
  StyledfilterType,
  StyledSelect,
  StyledTextField
} from './FilterComponentStyles';

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const getLabel = (filterType) => {
  if (filterType === "EUR") {
    return "€";
  } else if (filterType === "USD") {
    return "$";
  } else if (filterType === "%") {
    return "%";
  } else {
    return "";
  }
};

class FilterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: this.props.filterData,
      initialSelectedFilters: this.props.initialFilters,
      title: this.props.title,
      filterType: null,
      showRangeModal: false,
      includeNull: this.props.includeNull, // Use props here if need to be set from parent
      isOpen: false,
      collapseTitle: false,
      isHighlighted: false,
      inputMinChange: false,
      inputMaxChange: false,
      checkboxSelect: false
    };
    this.closeRangeModal = this.closeRangeModal.bind(this);
    this.closeSelectFilter = this.closeSelectFilter.bind(this);
    this.updateParentValues = this.updateParentValues.bind(this);
  }

  componentDidMount() {
    let { filterData, title, initialSelectedFilters } = this.state;
    // Create copy of list if the data is an array, otherwise create numerical JSON copy
    if (filterData && title && initialSelectedFilters) {
      if (Array.isArray(filterData)) {
        // iterate through all initial fitlers and sync list
        initialSelectedFilters.forEach((obj) => {
          let keyName = Object.keys(obj)[0];
          if (keyName === title[0]) {
            this.setState({ initialSelectedFilters: obj[keyName] });
          }
        });
      } else {
        this.setState({
          initialSelectedFilters: Object.assign({}, this.state.filterData),
        });
      }
    }
  }

  toggleRangeModal = () => {
    this.setState({ showRangeModal: !this.state.showRangeModal }, () => {
      document.addEventListener("click", this.closeRangeModal);
    });
  };

  closeRangeModal = (e) => {
    // First check if clicking inside modal or not
    let pointerTarget = e.target;
    let title = this.state.title;
    if (this.node === null || pointerTarget === null) {
      this.toggleRangeModal();
    } else if (this.node.contains(pointerTarget)) {
      return;
    } else {
      this.setState({ showRangeModal: false }, () => {
        if (this.state.inputMaxChange || this.state.inputMinChange || this.state.checkboxSelect) {
          this.props.setHasChange(true);
          this.setState({ inputMinChange: false });
          this.setState({ inputMaxChange: false });
          this.setState({ checkboxSelect: false });
        }
        this.props.setFilterState(true);
        document.removeEventListener("click", this.closeRangeModal);
      });
      // Update parent state
      this.updateParentValues(
        title,
        this.state.filterData,
        this.state.includeNull
      );
    }
  };

  confirmFilterValue = (e) => {
    let title = this.state.title;
    this.setState({ showRangeModal: false }, () => {
      document.removeEventListener("click", this.closeRangeModal);
    });
    if (this.state.inputMaxChange || this.state.inputMinChange || this.state.checkboxSelect) {
      this.props.setHasChange(true);
      this.setState({ inputMinChange: false });
      this.setState({ inputMaxChange: false });
      this.setState({ checkboxSelect: false });
    }
    this.props.setFilterState(true);
    // Update parent state
    this.updateParentValues(
      title,
      this.state.filterData,
      this.state.includeNull
    );
  };

  updateNumericalMin = (e) => {
    let minVal = parseFloat(e.target.value);
    this.setState({ inputMinChange: true });
    if (typeof minVal === "string" || isNaN(minVal)) {
      minVal = 0;
    }

    let returnFilterObj = {
      min: parseFloat(minVal),
      max: parseFloat(this.state.filterData["max"]),
    };
    this.setState({ filterData: returnFilterObj }, () => {
      return;
    });
  };

  updateNumericalMax = (e) => {
    let maxVal = parseFloat(e.target.value);
    this.setState({ inputMaxChange: true });
    if (typeof maxVal === "string" || isNaN(maxVal)) {
      maxVal = 0;
    }
    let returnFilterObj = {
      min: parseFloat(this.state.filterData["min"]),
      max: parseFloat(maxVal),
    };
    this.setState({ filterData: returnFilterObj }, () => {
      return;
    });
  };

  closeSelectFilter = (e) => {
    // First check if clicking inside modal or not
    let pointerTarget = e.target;
    let title = this.state.title;

    if (pointerTarget.classList.contains("MuiListItem-root")) {
      // Don't trigger call
      return;
    } else {
      document.removeEventListener("click", this.closeSelectFilter);
      // Update parent state
      this.updateParentValues(
        title,
        this.state.filterData,
        this.state.includeNull
      );
    }
  };

  updateSelectFilter(e, filterData) {
    // Add logic to select all if all other values are selected except all?
    let new_e = e;
    let innerText = e[0];
    // e.nativeEvent.toElement.innerText
    if (innerText !== "All" && filterData.includes("All")) {
      new_e = e.filter((e) => e !== "All");
    }
    this.setState({ filterData: new_e }, () => {
      document.addEventListener("click", this.closeSelectFilter);
    });
  }

  updateParentValues(title, value, includeNull) {
    this.props.updateParent(title, value, includeNull);
  }

  handleDropdownClick(e, filterData, initialSelectedFilters) {
    // let innerText = e.nativeEvent.target.innerText,
      // Get unselected
      let difference = this.state.initialSelectedFilters.filter(
        (x) => !e.target.value.includes(x)
      );
      // Get Selected
      let difference2 = this.state.initialSelectedFilters.filter(
        (x) => e.target.value.includes(x)
      );
    
    let updatedFilterData = [];
    if (!difference.includes('All') && filterData.includes('All')) {
        updatedFilterData = e.target.value.filter((x) => x!== 'All');
    }
    if (
      (difference.includes('All') && filterData.includes("All"))
    ) {
      this.setState({ filterData: [] }, () => {
        document.addEventListener("click", this.closeSelectFilter);
      }); // deselect all
      this.props.setHasChange(false);
      this.props.setFilterState(false);
    }  else if (
      (difference2.includes('All') && !filterData.includes('All')) ||
      (difference.includes('All') && (difference2.length === initialSelectedFilters.length - 1))
    ) {
      // change this as it creates a reference
      this.setState({ filterData: initialSelectedFilters }, () => {
        document.addEventListener("click", this.closeSelectFilter);
      }); // all selected
      this.props.setHasChange(true);
      this.props.setFilterState(false);
    } else {
      let selectedValues = updatedFilterData.length > 0 ? updatedFilterData : e.target.value;
      this.updateSelectFilter(selectedValues, filterData);
      this.props.setHasChange(true);
      this.props.setFilterState(true);
    }
  }

  toggleNullCheck() {
    this.setState({ checkboxSelect: true });
    this.setState({ includeNull: !this.state.includeNull }, () => {
      return;
    });
  }

  togglePanel(e) {
    this.setState({ collapseTitle: !this.state });
  }

  resetNumericFilter() {
    this.setState({
      filterData: this.state.initialSelectedFilters,
      includeNull: true
    });
  }

  // duplicate function, extract
  mapColumnName(colName) {
    const currency = this.props.currency;
    let columnDisplayName;
    let filterType;

    this.props.columnMapperData.forEach((data) => {
      if (colName === data["Backend Name"]) {
        columnDisplayName = data["Display Name"];
        filterType = data["NUMERIC_TYPE"];
      }
    });

    const isCurrency = ['USD', 'EUR'].includes(filterType);

    return [
      columnDisplayName,
      getLabel(isCurrency ? currency : filterType),
    ];
  }

  render() {
    const {
      filterData,
      title,
      initialSelectedFilters,
      showRangeModal,
      includeNull,
    } = this.state;
    const [filterDisplayName, filterType] = this.mapColumnName(title[0]);
    let filterStyle = {};
    const styleType = this.props.styleType;
    const disabledFilter = this.props.disabledFilter;

    if (styleType === "Standard") {
      filterStyle = {
        marginTop: 8,
        marginBottom: 8,
      };
    }

    //truncate the filter name
    let truncate = (filtername, numOfWords) => {
      return filtername.split(" ").splice(0, numOfWords).join(" ");
    };

    const highlightedLineActive = (e) => {
      e.target.style.borderColor = "#595959";
    };

    const highlightedLineInactive = (e) => {
      e.target.style.borderColor = "#d9d9d9";
    };

    if (filterData && filterDisplayName && initialSelectedFilters) {
      // Filter Type: Select
      if (Array.isArray(filterData)) {
        // Standard Screen Style
        if (styleType === "Standard") {
          return (
            <Grid spacing={3}>
              <Box sx={{display: "flex", flexDirection:"row", alignItems:"center", maxWidth:400 }} style={filterStyle}>
                <StyledName variant="h3" custom={false}>
                  {filterDisplayName}
                </StyledName>
                <FormControl variant="standard">
                <StyledSelect
                  value={filterData}
                  className="select-filter"
                  multiple
                  disabled={disabledFilter}
                  displayEmpty={true}
                  renderValue={(filterData) => {
                    let selected = `${filterData.length} selected`;
                    if (initialSelectedFilters.length === filterData.length) {
                      selected = "All selected";
                    } else if (filterData.length === 1) {
                      selected = filterData[0];
                    } else if (!filterData.length) {
                      selected = "Select a value";
                    }
                    return selected;
                  }}
                  onChange={(e) =>
                    this.handleDropdownClick(
                      e,
                      filterData,
                      initialSelectedFilters
                    )
                  }
                  inputProps={{
                    name: `standard-${filterDisplayName.replace(/\s/g,'')}`,
                    id: `standard-${filterDisplayName.replace(/\s/g,'')}`,
                  }}
                  MenuProps={{ autoFocus: false }}
                  onOpen={() => {
                    console.log("Entering dropdown ------");
                  }}
                >
                  {initialSelectedFilters.map((data, index) => (
                    <MenuItem
                      key={data}
                      value={data}
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                      id={`menu-item-${index}`}
                    >
                      <ListItemText primary={data} id={`list-item-${index}`}/>
                      <Checkbox
                        checked={filterData.indexOf(data) > -1}
                        color="primary"
                        id={`checkbox-filter-${index}`}
                        sx={{ padding: '9px !important' }}
                      />
                    </MenuItem>
                  ))}
                </StyledSelect>
                </FormControl>
              </Box>
            </Grid>
          );
        } else {
          // Custom Screen Style
          return (
            <Grid spacing={3}>
              <StyledName variant="h3" custom={true}>
                {filterDisplayName.length >= 25
                  ? `${truncate(filterDisplayName, 4)}...`
                  : filterDisplayName}
              </StyledName>
              <Box sx={{display: "flex", flexDirection:"row", alignItems:"center", justifyContent: "space-evenly", maxWidth:400}}>
                <FormControl variant="standard">
                <StyledSelect
                  value={filterData}
                  multiple
                  disabled={disabledFilter}
                  displayEmpty={true}
                  renderValue={(filterData) => {
                    let selected = `${filterData.length} selected`;
                    if (initialSelectedFilters.length === filterData.length) {
                      selected = "All selected";
                    } else if (filterData.length === 1) {
                      selected = filterData[0];
                    } else if (!filterData.length) {
                      selected = "Select a value";
                    }
                    return selected;
                  }}
                  onChange={(e) =>
                    this.handleDropdownClick(
                      e,
                      filterData,
                      initialSelectedFilters
                    )
                  }
                  inputProps={{
                    name: filterDisplayName.replace(/\s/g,''),
                    id: `select-${filterDisplayName.replace(/\s/g,'')}`,
                  }}
                  MenuProps={{ autoFocus: false, variant: "menu" }}
                  onOpen={() => {
                    console.log("Entering dropdown ------");
                  }}
                >
                  {initialSelectedFilters.map((data, index) => (
                    <MenuItem
                      key={data}
                      value={data}
                      id={`menu-item-${index}`}
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      <ListItemText primary={data} id={`list-item-${index}`} />
                      <Checkbox
                        checked={filterData.indexOf(data) > -1}
                        color="primary"
                        id={`checkbox-filter-${index}`}
                        sx={{ padding: '9px !important' }}
                      />
                    </MenuItem>
                  ))}
                </StyledSelect>
                </FormControl>
              </Box>
            </Grid>
          );
        }
        // Filter Type: Numerical
      } else {
        // Standard Screen Style
        let backgroundColor = disabledFilter ? "rgb(242, 242, 242)" : "";
        if (styleType === "Standard") {
          let filterStyle = {
            marginTop: "12px",
            marginBottom: "12px",
          };
          return (
            <div>
              <Grid spacing={3} style={{ cursor: "pointer" }}>
                <Grid
                  align="center"
                  spacing={3}
                  onMouseMove={highlightedLineActive}
                  onMouseOut={highlightedLineInactive}
                >
                 <Box 
                  sx={{display: "flex", 
                  flexDirection:"row", 
                  alignItems:"center", 
                  cursor:"pointer", 
                  }} 
                  backgroundColor={backgroundColor}
                  style={filterStyle}
                  mt={"6px"}
                  id={`filter-${filterDisplayName}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!disabledFilter) {
                      this.toggleRangeModal();
                    }
                  }}>
                    <StyledDisplayRange variant="body1" custom={false}>
                      {" "}
                      {filterDisplayName.length >= 25
                        ? `${truncate(filterDisplayName, 4)}...`
                        : filterDisplayName}{" "}
                    </StyledDisplayRange>
                    <CustomRangeContent
                      rangeData={filterData}
                      numericType={filterType}
                      disabled={disabledFilter}
                      displayName={filterDisplayName}
                    />
                    <div
                      marginRight="-30px"
                      backgroundColor="darkgrey"
                      marginTop="0px"
                    >
                      <KeyboardArrowDown fontSize="small" id="keyboard-down" />
                    </div>
                  </Box>
                  <div
                    className="bottom-div"
                  ></div>
                </Grid>
              </Grid>

              {showRangeModal == true && (
                <Box
                  bg="backgroundSurface"
                  className="rangeCard"
                  name={filterDisplayName}
                  position="absolute"
                  zIndex="1"
                  ref={(node) => {
                    this.node = node;
                  }}
                >
                  <Card
                  variant="outlined"
                  sx={{ maxWidth: 380, padding: '24px', backgroundColor: 'white', color: 'rgb(102, 102, 102)' }}
                  >
                    <div style={{ textAlign: "right" }}>
                      <div
                        style={{ display: "inline-block", cursor: "pointer" }}
                        p={1}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.confirmFilterValue(e);
                        }}
                        id="close-card"
                      >
                        <Close className="material-icons" fontSize="small" id="close-material-icon" />
                      </div>
                    </div>
                    <CardContent sx={{ padding: '0px' }}>
                    <StyledminmaxRange
                      justify="center"
                      color="subduedText"
                      mb={2}
                      variant="body1"
                    >
                      Please provide the min and max range
                    </StyledminmaxRange>

                    <StyledNameCustom
                      variant="h5"
                      textAlign="center"
                      fontWeight="600px"
                      width={[1, 2 / 2]}
                      my={1}
                    >
                      {filterDisplayName}
                    </StyledNameCustom>

                    <Box sx={{display: "flex", justifyContent: "space-around"}}>
                      <Box sx={{display: "flex", alignItems:"center", flexDirection:"column", maxWidth: '45%'}} my={1}>
                        <StyledminmaxValue
                          variant="body1"
                          color="grey"
                        >
                          Min
                        </StyledminmaxValue>
                        <Box sx={{display: "flex", justifyContent: "space-between", alignItems:"center", width: '185px'}}>
                          {filterType === "%" ? (
                            <>
                              <StyledTextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                id="Min"
                                mr={2}
                                name={"filtersData.title"}
                                placeholder="Min"
                                thousandSeparator={true}
                                value={filterData.min}
                                onChange={this.updateNumericalMin}
                                onFocus={(e) => {
                                  e.stopPropagation();
                                }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                              <StyledfilterType
                                variant="h7"
                                mr={1}
                                ml={1}
                              >
                                {filterType}
                              </StyledfilterType>
                            </>
                          ) : (
                            <>
                              <StyledfilterType
                                variant="h7"
                                mr={1}
                                ml={1}
                              >
                                {filterType}
                              </StyledfilterType>
                              <StyledTextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                id="Min"
                                mr={2}
                                name={"filtersData.title"}
                                placeholder="Min"
                                thousandSeparator={true}
                                value={filterData.min}
                                onChange={this.updateNumericalMin}
                                onFocus={(e) => {
                                  e.stopPropagation();
                                }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                            </>
                          )}
                        </Box>
                      </Box>
                      <Box sx={{display: "flex", flexDirection:"column", alignItems:"center", maxWidth: '45%'}} my={1}>
                        <StyledminmaxValue
                          variant="body1"
                          color="grey"
                        >
                          Max
                        </StyledminmaxValue>
                        <Box sx={{display: "flex", alignItems:"center", justifyContent:"space-between", width: '185px'}}>
                          {filterType === "%" ? (
                            <>
                              <StyledTextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                id="Max"
                                mr={2}
                                name={"filtersData.title"}
                                placeholder="Max2"
                                thousandSeparator={true}
                                value={filterData.max}
                                onChange={this.updateNumericalMax}
                                onFocus={(e) => {
                                  e.stopPropagation();
                                }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                              <StyledfilterType
                                variant="h7"
                                mr={1}
                                ml={1}
                              >
                                {filterType}
                              </StyledfilterType>
                            </>
                          ) : (
                            <>
                              <StyledfilterType
                                variant="h7"
                                mr={1}
                                ml={1}
                              >
                                {filterType}
                              </StyledfilterType>
                              <StyledTextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                id="Max"
                                mr={2}
                                name={"filtersData.title"}
                                placeholder="Max2"
                                thousandSeparator={true}
                                value={filterData.max}
                                onChange={this.updateNumericalMax}
                                onFocus={(e) => {
                                  e.stopPropagation();
                                }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                            </>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{display: "flex", justifyContent:"center"}}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          my={1}
                          name={filterDisplayName}
                          id="include-null"
                          value={includeNull}
                          label="Include Null"
                          checked={includeNull}
                          onChange={(e) => {
                            this.toggleNullCheck();
                          }}
                          className="filter-checkbox"
                        />
                      }
                      label="Include Null"/>
                    </Box>
                    </CardContent>
                    {
                      <CardActions sx={{ width: '95%', justifyContent: 'center' }}>
                      <Box sx={{display: "flex", justifyContent:"space-between", width: '80%'}}>
                        {/* here check if selected value == initial value for range */}
                        <StyledButton
                          id="Reset"
                          disabled={isEqual(0, 100)}
                          mt={2}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.resetNumericFilter();
                          }}
                        >
                          Reset
                        </StyledButton>
                        <StyledConfirmButton
                          id="Confirm"
                          color={isEqual(0, 100) ? "" : "primary"}
                          disabled={isEqual(0, 100)}
                          mt={2}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.confirmFilterValue(e);
                          }}
                        >
                          Confirm
                        </StyledConfirmButton>
                      </Box>
                      </CardActions>
                    }
                  </Card>
                </Box>
              )}
            </div>
          );
        } else {
          return (
            <div>
              <Grid spacing={3} style={{ cursor: "pointer" }}>
                <Tooltip title={filterDisplayName}>
                  <Grid
                    align="center"
                    spacing={3}
                    onMouseMove={highlightedLineActive}
                    onMouseOut={highlightedLineInactive}
                  >
                    <StyledDisplayRange variant="body1" custom={true}>
                      {" "}
                      {filterDisplayName.length >= 25
                        ? `${truncate(filterDisplayName, 4)}...`
                        : filterDisplayName}{" "}
                    </StyledDisplayRange>
                    {/* prev text wrap width={[1, 1 / 2, 2/3]} */}
                    <Box 
                      sx={{display: "flex", 
                      flexDirection:"row", 
                      alignItems:"center", 
                      justifyContent: "space-evenly", 
                      maxWidth:400, 
                      cursor:"pointer"}} 
                      backgroundColor={backgroundColor}
                      style={filterStyle}
                      mt={"6px"}
                      id="custom-range"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.toggleRangeModal();
                      }}>
                      <CustomRangeContent
                        rangeData={filterData}
                        numericType={filterType}
                        displayName={filterDisplayName}
                      />
                      <div
                        marginRight="-30px"
                        backgroundColor="darkgrey"
                        marginTop="0px"
                      >
                        <KeyboardArrowDown fontSize="small" />
                      </div>
                    </Box>
                    <div
                      style={{
                        borderBottom: "1px solid #d9d9d9",
                        marginTop: "9px",
                      }}
                    ></div>
                  </Grid>
                </Tooltip>
              </Grid>

              {showRangeModal == true && (
                <Box
                  bg="backgroundSurface"
                  className="rangeCard"
                  name={filterDisplayName}
                  position="absolute"
                  zIndex="1"
                  ref={(node) => {
                    this.node = node;
                  }}
                >
                  <Card
                    variant="outlined"
                    sx={{ maxWidth: 380, padding: '24px', backgroundColor: 'white', color: 'rgb(102, 102, 102)' }}
                  >
                    <div style={{ textAlign: "right" }}>
                      <div
                        style={{ display: "inline-block", cursor: "pointer" }}
                        p={1}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.confirmFilterValue(e);
                        }}
                        id="close-icon"
                      >
                        <Close className="material-icons" fontSize="small" />
                      </div>
                    </div>
                    <CardContent sx={{ padding: '0px' }}>
                    <StyledminmaxRange
                      justify="center"
                      color="subduedText"
                      mb={2}
                      variant="body1"
                    >
                      Please provide the min and max range
                    </StyledminmaxRange>

                    <StyledNameCustom
                      variant="h5"
                    >
                      {filterDisplayName}
                    </StyledNameCustom>

                    <Box sx={{display: "flex", justifyContent:"space-around"}}>
                      <Box sx={{display: "flex", flexDirection:"column", alignItems:"center", maxWidth: '45%', marginTop: '0px'}} my={1}>
                        <StyledminmaxValue
                          variant="body1"
                          color="grey"
                        >
                          Min
                        </StyledminmaxValue>
                        <Box sx={{display: "flex", alignItems:"center", justifyContent:"space-between", width: '185px'}}>
                          {filterType === "%" ? (
                            <>
                              <StyledTextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                id="Min"
                                mr={2}
                                name={"filtersData.title"}
                                placeholder="Min"
                                thousandSeparator={true}
                                value={filterData.min}
                                onChange={this.updateNumericalMin}
                                onFocus={(e) => {
                                  e.stopPropagation();
                                }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                              <StyledfilterType
                                variant="h7"
                                mr={1}
                                ml={1}
                                fontSize="small"
                              >
                                {filterType}
                              </StyledfilterType>
                            </>
                          ) : (
                            <>
                              <StyledfilterType
                                variant="h7"
                                mr={1}
                                ml={1}
                                fontSize="small"
                              >
                                {filterType}
                              </StyledfilterType>
                              <StyledTextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                id="Min"
                                mr={2}
                                name={"filtersData.title"}
                                placeholder="Min"
                                thousandSeparator={true}
                                value={filterData.min}
                                onChange={this.updateNumericalMin}
                                onFocus={(e) => {
                                  e.stopPropagation();
                                }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                            </>
                          )}
                        </Box>
                      </Box>
                      <Box sx={{display: "flex", alignItems:"center", flexDirection:"column", maxWidth: '46%'}}>
                        <StyledminmaxValue
                          variant="body1"
                          color="grey"
                        >
                          Max
                        </StyledminmaxValue>
                        <Box sx={{display: "flex", alignItems:"center", justifyContent:"space-between", width: '185px'}}>
                          {filterType === "%" ? (
                            <>
                              <StyledTextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                id="Max"
                                mr={2}
                                name={"filtersData.title"}
                                placeholder="Max2"
                                thousandSeparator={true}
                                value={filterData.max}
                                onChange={this.updateNumericalMax}
                                onFocus={(e) => {
                                  e.stopPropagation();
                                }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                              <StyledfilterType
                                variant="h7"
                                fontSize="small"
                                mr={1}
                                ml={1}
                              >
                                {filterType}
                              </StyledfilterType>
                            </>
                          ) : (
                            <>
                              <StyledfilterType
                                variant="h7"
                                fontSize="small"
                                mr={1}
                                ml={1}
                              >
                                {filterType}
                              </StyledfilterType>
                              <StyledTextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                id="Max"
                                mr={2}
                                name={"filtersData.title"}
                                placeholder="Max2"
                                thousandSeparator={true}
                                value={filterData.max}
                                onChange={this.updateNumericalMax}
                                onFocus={(e) => {
                                  e.stopPropagation();
                                }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                            </>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{display: "flex", justifyContent:"center"}}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          my={1}
                          name={filterDisplayName}
                          value={includeNull}
                          id="include-null-checkbox"
                          label="Include Null"
                          checked={includeNull}
                          onChange={(e) => {
                            this.toggleNullCheck();
                          }}
                          className="filter-checkbox"
                        />
                      }
                    label="Include Null"/>
                    </Box>
                    </CardContent>
                    {
                      <CardActions sx={{ width: '95%', justifyContent: 'center' }}>
                      <Box sx={{display: "flex", justifyContent:"space-between", width: '80%'}}>
                        {/* here check if selected value == initial value for range */}
                        <StyledButton
                          id="Reset-1"
                          disabled={isEqual(0, 100)}
                          mt={2}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.resetNumericFilter();
                          }}
                        >
                          Reset
                        </StyledButton>
                        <StyledConfirmButton
                          id="Confirm-1"
                          color={isEqual(0, 100) ? "" : "primary"}
                          disabled={isEqual(0, 100)}
                          mt={2}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.confirmFilterValue(e);
                          }}
                        >
                          Confirm
                        </StyledConfirmButton>
                      </Box>
                      </CardActions>
                    }
                  </Card>
                </Box>
              )}
            </div>
          );
        }
      }
    } else {
      return <div />;
    }
  }
}

export default connect(
  (state) => ({
    currency: state.customScreen.currency,
  }),
)(FilterComponent);
