import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import classnames from 'classnames';

export const TableAlert = ({ isMoreThenFixedOutput }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    if (isMoreThenFixedOutput) {
      setIsOpen(true);
    }
  }, [isMoreThenFixedOutput]);

  return (
    <Alert
      severity="warning"
      style={{ backgroundColor: 'rgb(254, 247, 234)', right: '330px' }}
      className={classnames('absolute bottom-0 mb-1.5 px-3 py-0.5', {
        flex: isOpen,
        hidden: !isOpen,
      })}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={handleClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      We can only show you 10,000 results. Please narrow your search.
    </Alert>
  );
};

TableAlert.propTypes = {
  isMoreThenFixedOutput: PropTypes.bool.isRequired,
};
