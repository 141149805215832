/**************************************************************************
 *
 *     Copyright Bain & Company. 2020, 2021
 *
 **************************************************************************/
import React, { Component } from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar
} from "@mui/material";
import API from "@aws-amplify/api-rest";
import { Close, Download } from "@mui/icons-material";
import styled from "styled-components";
import classnames from "classnames";
import _ from "lodash";
import Tooltip from "@mui/material/Tooltip";
import awsService from "../awsService";
import { actions } from "../slices/modal";
import { MODALS } from "../constants";

import "./ExportComponent.css";

const StyledButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-root": {
    backgroundColor: "rgb(221, 221, 221)",
    textTransform: "none",
    fontWeight: "600",
    color: "rgb(72, 72, 72)",
    boxShadow: "none",
    padding: '8px 12px',
    borderRadius: '4px',
    "&:hover": {
      backgroundColor: 'rgb(221, 221, 221)'
    }
  },
}))

const StyledExportButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-root": {
    backgroundColor: "rgb(204, 0, 0)",
    fontSize: "14px",
    fontWeight: "500",
    color: "white",
    padding: "6px 15px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "rgb(204, 0, 0)"
    },
    "&.Mui-disabled": {
      backgroundColor: "rgb(90, 90, 90)"
    }
  },
}))

const StyledRadio = styled(Radio)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    padding: '9px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  '&.MuiButtonBase-root.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.26)'
  },
  '&.Mui-checked': {
    color: '#c00',
  }
}))

const MAX_OVERVIEW_COMPANIES = 5;

class ExportComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedFilters: this.props.selectedFilters,
      includeNullFilters: this.props.includeNullFilters,
      dialogOpen: false,
      snackbarOpenError: false,
      snackbarOpenSuccess: false,
      noCaseCode: !this.props.caseCodeFormData.caseCode,
      caseCode: this.props.caseCodeFormData.caseCode,
      caseType: this.props.caseCodeFormData.caseType,
      projectName: this.props.caseCodeFormData.projectName,
      projectType: this.props.caseCodeFormData.projectType
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      exportOption: _.isEmpty(props.selectedCompanies) ? 'all' : state.exportOption,
    };
  }

  componentDidMount() {
    awsService.getUser().then((data) => {
      if (data?.username) {
        this.setState({ userName: data.username });
      }
    });
  }

  exportData() {
    const { exportOption } = this.state;
    const {
      selectedCompanies,
      isCompanyProfile,
      searchText,
      currency,
      bainIds,
      email,
    } = this.props;

    let response;
    let body = {
      email,
      sql_query: true,
      free_search_text: searchText,
    };

    if (isCompanyProfile) {
      body.company_profile = true;
      body.bainIds = bainIds;
    } else if (exportOption === 'selected') {
      body.company_profile = true;
      body.bainIds = selectedCompanies.map(({ id }) => String(id));
    }

    // Add Filters
    let includeNullList = this.props.includeNullFilters;
    if (this.props.selectedFilters.length > 0) {
      this.props.selectedFilters.forEach(function (item) {
        let itemValues = Object.values(item)[0];
        let itemKey = Object.keys(item)[0];
        // numerical filter
        if ("min" in itemValues && !Array.isArray(itemValues)) {
          body[itemKey] = itemValues;
        }
        // select filter
        if (Array.isArray(itemValues)) {
          if (!itemValues.includes("All")) {
            body[itemKey] = itemValues;
          }
        }
      });
    }

    // Add null list to body if present
    if (includeNullList.length > 0) {
      body["include_null_list"] = includeNullList;
    }

    if (this.props.bainIds.length > 0) {
      body["self_ids_id__cdp_"] = this.props.bainIds;
    }

    // add case code
    if (this.state.noCaseCode) {
      body["project_name"] = this.state.projectName;
      body["case_type"] = this.state.caseType;
      body["project_type"] = this.state.projectType;
    } else {
      body["case_code"] = this.state.caseCode;
    }

    // remove this until functional
    delete body["has_pe_owner"];

    try {
      response = API.post("CDPAPI", `/midtier/export?currency=${currency}`, {
        body: body
      });
      this.setState({ snackbarOpenSuccess: true });
    } catch (err) {
      this.setState({ snackbarOpenError: true });
      alert("Error exporting data");
    }
    this.setState({ dialogOpen: false });
  }

  dialogHandleClickOpen = () => {
    this.setState({ dialogOpen: true });
  };

  dialogHandleClose = () => {
    this.setState({ dialogOpen: false });
  };

  snackbarHandleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbarOpenError: false });
  };

  snackbarHandleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbarOpenSuccess: false });
  };

  handleExportOption = (_, value) => {
    this.setState({ exportOption: value });
  };

  handleNoCaseCode = () => {
    this.setState({ noCaseCode: !this.state.noCaseCode });

    this.state.noCaseCode
      ? this.setState({
        projectName: "",
        caseType: "",
        projectType: "external"
      })
      : this.setState({ caseCode: "" });
  };

  isNotNilOrEmpty = (val) => val !== undefined && val !== null && val !== "";

  handleCaseCode = (event) => this.setState({ caseCode: event.target.value });

  handleProjectName = (event) => this.setState({ projectName: event.target.value });

  handleCaseType = (event) => this.setState({ caseType: event.target.value });

  handleProjectType = (event) => this.setState({ projectType: event.target.value });

  isCaseCodeValid = () => this.state.noCaseCode ?
    this.isNotNilOrEmpty(this.state.projectName) && this.isNotNilOrEmpty(this.state.caseType)
    : this.isNotNilOrEmpty(this.state.caseCode);

  handleCompanyOverview = () => {
    const isTooMuch = Object.keys(this.props.selectedCompanies).length > MAX_OVERVIEW_COMPANIES;

    this.props.openModal({
      id: isTooMuch ? MODALS.TOO_MANY_COMPANIES : MODALS.COMPANY_OVERVIEW,
    });
  };

  render() {
    const { exportOption } = this.state;
    const { selectedCompanies } = this.props;
    const areIdsEmpty = _.isEmpty(selectedCompanies);

    return (
      <div data-testid="exportComponent">
        <Snackbar
          open={this.state.snackbarOpenSuccess}
          autoHideDuration={600}
          onClose={this.snackbarHandleCloseError}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          id="snackbar-1"
        >
          <Alert
            onClose={this.snackbarHandleCloseSuccess}
            severity="success"
            variant="filled"
            sx={{ backgroundColor: 'rgb(33, 166, 99) !important', color: 'white !important' }}
          >
            <Typography variant="body1" gutterBottom>
              Success! You will receive an email with a link to your export.
            </Typography>
            <Typography variant="body1" gutterBottom style={{ wordWrap: "break-word" }}>
              Exports may take upwards of 10 minutes based on the size of the data.
              Please wait before re-attempting to export.
            </Typography>
          </Alert>
        </Snackbar>
        <Snackbar
          open={this.state.snackbarOpenError}
          autoHideDuration={600}
          onClose={this.snackbarHandleCloseError}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          id="snackbar-2"
        >
          <Alert
            onClose={this.snackbarHandleCloseError}
            severity="error"
            variant="filled"
            sx={{ backgroundColor: 'rgb(211, 47, 47) !important', color: 'white !important' }}
          >
            Error. Please try again or contact support.
          </Alert>
        </Snackbar>
        {!this.props.isCompanyProfile && (
          <Tooltip title="Select one or more companies to export the company comparison slides" placement="left">
            <span>
              <Button
                className={classnames('text-cgray-150 bg-cgray-100 py-2 mr-4', {
                  'opacity-50': areIdsEmpty
                })}
                disabled={areIdsEmpty}
                onClick={this.handleCompanyOverview}
              >
                Create overview
              </Button>
            </span>
          </Tooltip>
        )}
        <StyledButton onClick={this.dialogHandleClickOpen} id="download-button">
          {" "}
          <Download className="material-icons" fontSize="small" sx={{ fontSize: '16px !important' }} />
          Export
        </StyledButton>
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.dialogHandleClose}
          id="dialog-box"
        >
          <div style={{ backgroundColor: 'white', position: 'absolute', right: '5px', padding: '10px' }}><Close id="close-icon" onClick={this.dialogHandleClose} sx={{ cursor: 'pointer', color: 'black' }} /></div>
          <DialogTitle sx={{ backgroundColor: 'white', color: 'black', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '70px' }}>
            <Typography><b>Export Results</b></Typography>
            {this.props.isCompanyProfile ?
              <Typography variant="body1" gutterBottom>
                Please select "Export" to export all data for the selected company.
              </Typography>
              :
              <Typography variant="body1" gutterBottom>
                Please select an export option:
              </Typography>
            }
          </DialogTitle>
          <DialogActions sx={{ width: '500px', backgroundColor: 'white', color: 'black', padding: '15px' }} id="dialog-actions">
            {!this.props.isCompanyProfile ?
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                  <RadioGroup
                    aria-label="export"
                    name="export"
                    value={exportOption}
                    onChange={this.handleExportOption}
                    style={{
                      position: "relative"
                    }}
                    id="radio-group"
                  >
                    <FormControlLabel
                      value="all"
                      control={<StyledRadio id="export-all-radio" />}
                      label="Export summary data for all search results (excludes time-series data on financials, news, M&A, others...)"
                      id="export-all"
                    />
                    <FormControlLabel
                      value="selected"
                      control={<StyledRadio id="export-selected-radio" />}
                      disabled={areIdsEmpty}
                      label="Export all data for only selected companies"
                      id="export-selected"
                    />
                  </RadioGroup>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="body1" gutterBottom>
                    {this.isCaseCodeValid() ? "" : "* Starred fields are mandatory"}
                  </Typography>

                  <StyledExportButton
                    onClick={() => this.exportData()}
                    id="export-button-1"
                  >
                    Export
                  </StyledExportButton>
                </Box>
              </Box>
              :
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <StyledExportButton
                  onClick={() => this.exportData()}
                  id="export-button-2"
                >
                  Export
                </StyledExportButton>
              </Box>
            }
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    email: state.user.email,
    caseCodeFormData: state.caseCode.formData,
    selectedCompanies: state.customScreen.selectedCompanies,
    searchText: state.search.searchText,
    currency: state.customScreen.currency,
  }),
  { openModal: actions.openModal },
)(ExportComponent);
