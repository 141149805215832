import React from "react";

export const IconSearch = (props) => (
  <svg {...props} width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.167 9.167c0-2.758 2.242-5 5-5 2.757 0 5 2.242 5 5 0 2.757-2.243 5-5 5-2.758 0-5-2.243-5-5Zm13.089 6.91-2.83-2.83a6.626 6.626 0 0 0 1.407-4.08A6.674 6.674 0 0 0 9.167 2.5 6.674 6.674 0 0 0 2.5 9.167a6.674 6.674 0 0 0 6.667 6.666 6.627 6.627 0 0 0 4.08-1.406l2.83 2.829a.831.831 0 0 0 1.179 0 .832.832 0 0 0 0-1.178Z"
      fill="#666"
    />
  </svg>
);
