/**************************************************************************
 *
 *     Copyright Bain & Company. 2020, 2021
 *
 **************************************************************************/
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tabs from "@mui/material/Tabs";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import HelpOutline from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import classnames from "classnames";
import _ from 'lodash';

import styles from "./Search.module.css";
import ErrorDialog from "../Shared/ErrorDialog";
import { queryCompanySearch } from "../../Api/search-api.service";
import { IconFilterSettings } from "../../Components/Icons/IconFilterSettings";
import { IconSearch } from "../../Components/Icons/IconSearch";
import { SavedQueries } from "./SavedQueries";
import { actions } from "../../slices/search";
import { useShallowSelector } from "../../hooks/use-shallow-selector";
import { useModal } from "../../hooks/use-modal";
import { hideAdvancedSearch, MODALS } from "../../constants";
import { clearSearchSubject, searchSubject } from "../../Utils/subjects";

import "../../App.css";

const StyledSwitch = styled(Switch)(() => ({
  "& .MuiSwitch-switchBase": {
    padding: "9px",
    position: "absolute",
    color: "#fff",
    "&.Mui-checked": {
      "& + .MuiSwitch-track": {
        backgroundColor: "rgb(204, 0, 0)",
        opacity: 1,
        height: "19px",
        marginTop: "-2px",
        borderRadius: "10px"
      }
    },
    "& .MuiSwitch-input": {
      width: '100%',
      marginLeft: '50px'
    }
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: 'white',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
  },
  "& .MuiSwitch-track": {
    height: '19px',
    marginTop: '-2px',
    borderRadius: '10px',
  }
}))

const StyledTabs = styled(Tabs)(() => ({
  '& div.MuiTabs-scroller': {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
      width: '50%'
    }
  }
}))

const StyledPaper = styled(Paper)(() => ({
  '&.MuiPaper-root': {
    backgroundColor: '#f0f0f0',
    width: '80%',
    color: 'black',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  }
}))

const StyledAutocomplete = styled(Autocomplete)(() => ({
  '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
    padding: '9px',
    color: 'black',
  },
  '&.MuiAutocomplete-listbox.MuiAutocomplete-option': {
    fontSize: '20px !important'
  },
}))

const StyledTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'black',
      borderWidth: '1px'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#3f51b5',
      borderWidth: '2px'
    },
  }
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Search({
  handleChange,
  resetState,
  setHasChange,
}) {
  const dispatch = useDispatch();
  const { isLoading, isError, errorStatus, errorMessage } = useShallowSelector(state => state.search.bainIds);
  const searchText = useShallowSelector(state => state.search.searchText);
  const isLuceneQuery = useShallowSelector(state => state.search.isLuceneQuery);
  const searchQueries = useShallowSelector(state => state.search.searchQueries);
  const currency = useShallowSelector(state => state.customScreen.currency);
  const isTableLoading = useShallowSelector(state => state.customScreen.isLoading);
  const {
    handleOpen: openAdvancedSearchDialog
  } = useModal(MODALS.ADVANCED_SEARCH);

  const [typeAheadList, setTypeAheadList] = useState([]);
  const [helpTabValue, setHelpTabValue] = useState(0);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [reset, setResetState] = useState(resetState);
  const [type, setType] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleSearch = () => {
    if (resetState == true) {
      setTypeAheadList([]);
      setHelpTabValue(0);
      setHasChange(false);
      setResetState(false);

      dispatch(actions.setBainIds([]));
      dispatch(actions.setSearchText(''));
    }

    const getState = {
      checked: isLuceneQuery,
      typeAheadList: typeAheadList,
      helpTabValue: helpTabValue,
      isLoading: isLoading,
      resetState: reset
    }

    if (handleChange) {
      handleChange(getState);
    }
  }

  const handleClearSearch = () => {
    dispatch(actions.setSearchText(''));
    dispatch(actions.setBainIds([]));
    dispatch(actions.setSearchQueries([]));
    dispatch(actions.setIsError({ value: false, key: "bainIds", status: null, message: null }));
    setTypeAheadList([]);
    setHasChange(true);
  };

  useEffect(() => {
    dispatch(actions.setIsError({ value: false, key: "bainIds", errorStatus: null, errorMessage: null }));

    const subscription = clearSearchSubject.subscribe(() => {
      handleClearSearch();
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    handleSearch();
  }, [isLoading, resetState]);

  useEffect(() => {
    let typingTimer = setTimeout(() => {
      if (type === "change") {
        updateTypeAhead(searchText);
      }
    }, 300);

    return () => clearTimeout(typingTimer);
  }, [searchText])

  const openHelpDialog = () => {
    setHelpDialogOpen(true);
  };

  const closeHelpDialog = () => {
    setHelpDialogOpen(false);
  };

  const handleTabChange = (_, value) => {
    setHelpTabValue(value);
  };

  const updateTypeAhead = async (value) => {
    let typeAheadValues = [];

    if (value.length >= 3 && !isLuceneQuery) {
      let response;
      try {
        response = await queryCompanySearch({ company: value, currency });
      } catch (err) {
        // await setThrowError(true);
      }

      if (response) {
        Object.values(response).forEach((item) => {
          if (item) {
            typeAheadValues.push(item);
          }
        });
      }

      if (typeAheadValues == undefined || !value) {
        setTypeAheadList([]);
      } else if (typeAheadValues.length < 1) {
        typeAheadValues.push(`"${value}"`);
        setTypeAheadList(typeAheadValues);
      } else {
        typeAheadValues[0].unshift(`"${value}"`);
        setTypeAheadList(typeAheadValues[0]);
      } // wipe Id's and start clean
    }
  }

  const handleInputChange = (event, value) => {
    if (event && event?.code !== 'Enter') {
      dispatch(actions.setSearchText(value));
      dispatch(actions.setHasChange(true));
      setType(event?.type);
    }
  }

  const handleOnChange = (event, value) => {
    if (event.type === 'click') {
      const idx = +event.target.dataset?.optionIndex;
      const isUniq = idx !== 0;
      const text = isUniq ? value : searchText;

      searchSubject.next({ text, isUniq });

      setTypeAheadList([]);
      setIsFocused(false);
    }
  }

  const handleEnter = (event) => {
    if (event.code === 'Enter') {
      event.preventDefault();
      const preparedText = _.trim(searchText);

      if (!preparedText) return;

      const result = preparedText ? [...searchQueries, ...preparedText.split('AND')] : searchQueries;

      searchSubject.next({
        text: isLuceneQuery ? result.join(' AND ') : preparedText,
      });

      setTypeAheadList([]);
      setIsFocused(false);

      if (isLuceneQuery) {
        dispatch(actions.setSearchText(''));
      }

      if (isLuceneQuery && preparedText) {
        dispatch(actions.setSearchQueries(result));
      }
    }
  };

  const handleSearchSettings = (event) => {
    event.stopPropagation();

    openAdvancedSearchDialog();
  };

  const handleSwitchToggle = ({ target: { checked } }) => {
    dispatch(actions.setIsLuceneQuery(checked));
  };

  return (
    <div className="flex flex-col">
      <div className="flex">
        <Box sx={{ display: "flex" }} ml={1}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Enter 3 Characters or More">
              <StyledAutocomplete
                disabled={isTableLoading}
                open={isFocused && !isLuceneQuery}
                id="company-name"
                freeSolo={true}
                inputValue={searchText}
                className="search-bar"
                options={typeAheadList}
                onInputChange={handleInputChange}
                onChange={handleOnChange}
                onKeyDown={handleEnter}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                autoHighlight
                renderOption={(props, option) => {
                  const isFirstOption = props.id === 'company-name-option-0';
                  const length = searchText.length;
                  const result = [option.substring(0, length), option.substring(length, Infinity)];

                  return (
                    <>
                      <li
                        {...props}
                        className={classnames('text-sm font-normal cursor-pointer', {
                          'text-[#999999] italic py-2.5 mx-2.5 border-b-[#ddd] border-b': isFirstOption,
                          'p-2.5 autocomplete-option': !isFirstOption,
                        })}
                      >
                        <span>{result[0]}</span>
                        <b>{result[1]}</b>
                      </li>
                      {isFirstOption && (
                        <div style={{ fontSize: 6 }} className="mx-2.5 mt-2.5 uppercase text-[#999]">Companies</div>
                      )}
                    </>
                  );
                }}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    error={isError && errorStatus === 400}
                    helperText={errorMessage}
                    width="300"
                    size="small"
                    id="name-query"
                    label={isLuceneQuery ? "Please enter a Lucene query, see help for explanation" : "Name, URL or Description"}
                    variant="outlined"
                    multiline
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 14 },
                      autoComplete: "off", // disable autocomplete and autofill
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start" className="m-0">
                          <IconSearch />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end" className="m-0">
                          {isFocused && searchText && (
                            <IconButton onClick={handleClearSearch}>
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          )}
                          {!hideAdvancedSearch && isLuceneQuery && (
                            <IconButton onClick={handleSearchSettings} disabled={isTableLoading}>
                              <IconFilterSettings />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                classes={{
                  popper: 'mt-1 drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]'
                }}
              />
            </Tooltip>

            <Tooltip title="Enable Lucene Querying">
              <FormControlLabel
                id="switch-control"
                label=""
                style={{ marginRight: isLuceneQuery ? '-10px' : '16px' }}
                control={
                  <StyledSwitch
                    checked={isLuceneQuery}
                    onChange={(e) => handleSwitchToggle(e)}
                    value="checked"
                    size="normal"
                    id="switch-toggle"
                  />
                }
              />
            </Tooltip>
          </Box>
        </Box>

        {isLuceneQuery &&
          <Button
            fontSize="large"
            ml={-2}
            onClick={openHelpDialog}
            id="open-help-dialog"
          >
            <HelpOutline className="material-icons" fontSize="small" id="help-outline" />
          </Button>
        }

        <Dialog
          open={helpDialogOpen}
          onClose={closeHelpDialog}
          maxWidth="md"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          title="Text based query help"
          disableEnforceFocus
          hideBackdrop
          enableBackdropClick
          scroll="paper"
          className={styles.dialogBox}
          id="dialog-tab-box"
          PaperProps={{
            style: {
              backgroundColor: 'white',
              color: 'black',
              maxWidth: '960px',
              boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)'
            },
          }}
        >
          <StyledTabs value={helpTabValue} onChange={(e, v) => handleTabChange(e, v)} id="tab-container">
            <Tab label={"Lucene Query Examples"} id="lucene-query" className={helpTabValue === 0 ? styles.luceneTabSelected : styles.luceneTabUnselected} />
            <Tab label={"Searchable Columns"} id="searchable-columns" className={helpTabValue === 1 ? styles.luceneTabSelected : styles.luceneTabUnselected} />
          </StyledTabs>
          <TabPanel value={helpTabValue} index={0}>
            <Typography variant="h6" component="h2" gutterBottom sx={{ fontSize: '20px' }}>
              Lucene Querying
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ letterSpacing: '0.15px' }}>
              Lucene queries allow the user to search using advanced queries as opposed to basic strings used within the free text search.
              For example, the queries can contain operators such as boolean 'AND' 'OR' and 'NOT'.
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ letterSpacing: '0.15px' }}>
              For more advanced queries than what is found below, or more detail on general queries, you can find <a href="https://lucene.apache.org/core/2_9_4/queryparsersyntax.html" id="lucene-link" target="_blank" rel="noopener noreferrer">Lucene documentation here</a>.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom className={styles.padding10} sx={{ fontSize: '20px' }}>
              Basic Strings
            </Typography>
            <Typography variant="body1" gutterBottom className={styles.typographyStyle}>
              The most basic component of a Lucene query is a word or multiple words wrapped by double quotes.
            </Typography>
            <StyledPaper>
              <Typography variant="body2" gutterBottom className={styles.padding4}>
                "Bain and Company"
              </Typography>
            </StyledPaper>
            <Typography variant="body1" gutterBottom className={styles.typographyStyle}>
              Returns only companies within the quoted string.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom className={styles.padding10} sx={{ fontSize: '20px' }}>
              AND, OR, and NOT
            </Typography>
            <Typography variant="body1" gutterBottom className={styles.typographyStyle}>
              Another important component of a Lucene query are operators, such as AND, OR, and NOT, that are used alongside strings.
            </Typography>
            <StyledPaper>
              <Typography variant="body2" gutterBottom className={styles.padding4}>
                "Bain and Company" AND "South Africa"
              </Typography>
            </StyledPaper>
            <Typography variant="body1" gutterBottom className={styles.typographyStyle}>
              Returns companies with both of the quoted strings.
            </Typography>
            <StyledPaper>
              <Typography variant="body2" gutterBottom className={styles.padding4}>
                "Bain and Company" OR "South Africa"
              </Typography>
            </StyledPaper>
            <Typography variant="body1" gutterBottom className={styles.typographyStyle}>
              Returns companies with either of the quoted strings.
            </Typography>
            <StyledPaper>
              <Typography variant="body2" gutterBottom className={styles.padding4}>
                "Bain and Company" NOT "South Africa"
              </Typography>
            </StyledPaper>
            <Typography variant="body1" gutterBottom className={styles.typographyStyle}>
              Returns companies with the first quoted string and not the second quoted string.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom className={styles.padding10} sx={{ fontSize: '20px' }}>
              Columns
            </Typography>
            <Typography variant="body1" gutterBottom className={styles.typographyStyle}>
              Lucene queries return data from a select number of columns. If no column is included, the search results will be from any of the available columns. If a column name is included, the search rewsults will be from the specified column or columns. All supported columns can be found in the "Searchable Columns" tab.
            </Typography>
            <StyledPaper>
              <Typography variant="body2" gutterBottom className={styles.padding4}>
                self_firmo_name___:(SA) OR self_firmo_name___:(South AND Africa)
              </Typography>
            </StyledPaper>
            <Typography variant="body1" gutterBottom className={styles.typographyStyle}>
              Returns SA or South Africa that are only found within the self_firmo_name___ column.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom className={styles.padding10} sx={{ fontSize: '20px' }}>
              Wildcards
            </Typography>
            <Typography variant="body1" gutterBottom className={styles.typographyStyle}>
              Wildcards can be used for a single character or multiple characters. **Please note that the ? and * cannot be used at the start of the search term.
            </Typography>
            <StyledPaper>
              <Typography variant="body2" gutterBottom className={styles.padding4}>
                fi?e
              </Typography>
            </StyledPaper>
            <Typography variant="body1" gutterBottom className={styles.typographyStyle}>
              Returns results for a single letter, represented by the ? wildcard, such as fire, file, etc.
            </Typography>
            <StyledPaper>
              <Typography variant="body2" gutterBottom className={styles.padding4}>
                fil*
              </Typography>
            </StyledPaper>
            <Typography variant="body1" gutterBottom className={styles.typographyStyle}>
              Returns results for any words containing the prefix fil.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom className={styles.padding10} sx={{ fontSize: '20px' }}>
              Fuzzy Search
            </Typography>
            <Typography variant="body1" gutterBottom className={styles.typographyStyle}>
              Fuzzy searches are supported, which is a process that finds non-exact matches to the search value such as similarly spelled words or correct spelling for misspelled words. Specifically, Lucene uses the Levenshtein Distance or Edit Distance algorithms to find the results.
            </Typography>
            <StyledPaper >
              <Typography variant="body2" gutterBottom className={styles.padding4}>
                room~
              </Typography>
            </StyledPaper>
            <Typography variant="body1" gutterBottom className={styles.typographyStyle}>
              Returns results such as rooms, roomy, or loom.
            </Typography>
          </TabPanel>
          <TabPanel value={helpTabValue} index={1}>
            <Typography variant="body1" gutterBottom>
              The free text search allows for the searching of specific
              columns. Below are the column names from the table and the
              corresponding backend name to be used within the search:
            </Typography>
            <Table aria-label="search column table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={styles.tableHeader} align="left">Table Column</TableCell>
                  <TableCell className={styles.tableHeader} align="left">Lucene Search Column</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={styles.tableStyle}>Parent Company Name</TableCell>
                  <TableCell className={styles.tableStyle}>parent_firmo_name___</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles.tableStyle}>Company Name</TableCell>
                  <TableCell className={styles.tableStyle}>self_firmo_name___</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles.tableStyle}>Company Description</TableCell>
                  <TableCell className={styles.tableStyle}>self_firmo_description___</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles.tableStyle}>Webpage</TableCell>
                  <TableCell className={styles.tableStyle}>self_firmo_webpage___</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles.tableStyle}>Sector</TableCell>
                  <TableCell className={styles.tableStyle}>self_sector_sector___</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles.tableStyle}>Industry</TableCell>
                  <TableCell className={styles.tableStyle}>self_sector_industry___</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles.tableStyle}>Industry Group</TableCell>
                  <TableCell className={styles.tableStyle}>self_sector_industryGroup___</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles.tableStyle}>Sub Industry</TableCell>
                  <TableCell className={styles.tableStyle}>self_sector_subIndustry___</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabPanel>
        </Dialog>
        <ErrorDialog throwError={isError && errorStatus !== 400} />
      </div>
      {isLuceneQuery && (
        <SavedQueries />
      )}
    </div>
  );
}

export default Search;
