import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { SelectCurrency } from '../../SelectCurrency';
import { useShallowSelector } from '../../../hooks/use-shallow-selector';
import { loggingCaseCode } from '../../../slices/case-code/thunks/logging-case-code';
import { CASE_TYPES, IS_DEV } from '../../../constants';

import "./index.css";

const InputProps = {
  classes: {
    root: 'text-black',
  }
};

const FORM_CONFIGURATION = {
  defaultValues: {
    caseCode: '',
    caseType: '',
    projectName: '',
    clientName: '',
    projectType: 'external',
  }
};

const FIELDS_TO_RESET = ['caseType', 'projectName', 'clientName', 'projectType'];

export const CaseCodeDialog = () => {
  const dispatch = useDispatch();
  const [hasCode, setHasCode] = useState(true);
  const { isDialogOpen } = useShallowSelector(state => state.caseCode);
  const { control, register, handleSubmit, resetField } = useForm(FORM_CONFIGURATION);

  useEffect(() => {
    hasCode ? FIELDS_TO_RESET.forEach(resetField) : resetField('caseCode');
  }, [hasCode]);

  const onSubmit = formData => dispatch(loggingCaseCode({ hasCode, formData }))
  const handleHasCode = () => setHasCode((prev) => !prev);

  return (
    <Dialog
      open={IS_DEV ? false : isDialogOpen}
      classes={{ paper: 'bg-white p-5' }}
    >
      <DialogTitle classes={{ root: 'dialog-title' }}>
        Welcome to Helix!
      </DialogTitle>
      <form className="flex flex-col text-black">
        <div className="flex flex-row gap-4">
          <TextField
            {...register('caseCode', { required: hasCode })}
            classes={{ root: 'my-2 basis-1/2' }}
            label="Case code *"
            variant="outlined"
            disabled={!hasCode}
            InputProps={InputProps}
          />

          <Button
            className="my-2 font-normal bg-cred-50 basis-1/2"
            variant="contained"
            onClick={handleHasCode}
          >
            {hasCode ? "I don't have a case code" : "I have a case code"}
          </Button>
        </div>

        <Controller
          name="caseType"
          control={control}
          rules={{ required: !hasCode }}
          render={({ field }) => (
            <FormControl>
              <InputLabel disabled={hasCode}>Case type *</InputLabel>
              <Select
                {...field}
                label="Case type *"
                className='text-black'
                disabled={hasCode}
                MenuProps={{
                  classes: {
                    paper: 'bg-white text-black',
                  }
                }}
              >
                {CASE_TYPES.map(({ value, label }) => (
                  <MenuItem key={label} value={value}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        <TextField
          {...register('projectName', { required: !hasCode })}
          classes={{ root: 'my-2' }}
          id="project-name"
          label="Project name *"
          variant="outlined"
          InputProps={InputProps}
          disabled={hasCode}
        />

        <TextField
          {...register('clientName', { required: !hasCode })}
          classes={{ root: 'my-2' }}
          id="client-name"
          label="Client name *"
          variant="outlined"
          InputProps={InputProps}
          disabled={hasCode}
        />

        <Controller
          name="projectType"
          control={control}
          render={({ field }) => (
            <FormControl disabled={hasCode}>
              <FormLabel>Project type *</FormLabel>
              <RadioGroup
                {...field}
                row
                aria-labelledby="project-type-buttons"
                name="project-type-buttons"
              >
                <FormControlLabel value="external" control={<Radio />} label="External" />
                <FormControlLabel value="internal" control={<Radio />} label="Internal" />
              </RadioGroup>
            </FormControl>
          )}
        />

        <SelectCurrency
          showLabel
          parentClassNames="w-56 my-6"
        />
      </form>

      <div className='text-stone-600 pb-4 pt-2 text-xs max-w-xl'>
        <div className='pb-2'>
          By continuing to use the platform, you agree to the below:
        </div>

        <div className='pb-2'>
          - Helix Find platform access is provided free of charge for client development,
          due diligence, and similar use cases that require high-level insights or
          information on a small number of companies.
        </div>

        <div className='pb-2'>
          - All sectors screening, deal sourcing, and corporate M&A projects are asked to
          work with our CoE. These engagements will automatically incur a charge.
          Please contact <a href="mailto:helixfind@bain.com" className="text-cred-50">Helix Find Team</a> to discuss your needs.
        </div>

        <div className='pb-2'>
          - Company Profile and Financial Benchmarking slide deck exports will incur
          a fee of $250 per company, capped at a maximum of $1500 per case.
          First export is free of charge for every case.
        </div>

        <div className='pb-2'>
          - Under no circumstances, bulk raw data exported from the platform can be
          shared directly with parties outside of Bain or used in other data products
          without prior approval of the <a href="mailto:helixfind@bain.com" className="text-cred-50">Helix Find Team</a>.
        </div>
      </div>

      <Button
        variant="contained"
        className="bg-cred-50"
        onClick={handleSubmit(onSubmit)}
      >
        Log in
      </Button>
    </Dialog>
  );
};
