/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React, { Component } from "react";
import { Dialog, DialogActions, DialogTitle, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Redirect } from "react-router-dom";

class ErrorDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  redirectToHome = async () => {
    await this.setState({ redirect: true });
  };

  render() {
    let isOpen = this.props.throwError;
    if (this.state.redirect) {
      return <Redirect to="/homePage" />;
    } else {
      return (
        <Dialog open={isOpen} onClose={this.dialogHandleClose} title="Error" id="dialog-error">
          <DialogTitle sx={{ backgroundColor: 'white', color: 'black', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '70px' }}>
            <Typography><b>Error</b></Typography>
            <Typography>
              Woops! Looks like something went wrong. Please return to homepage
            </Typography>
          </DialogTitle>
          <DialogActions sx={{ width: '525px', backgroundColor: 'white', color: 'black', padding: '15px' }}>
            <Button
              onClick={() => this.redirectToHome()}
              variant="contained"
              style={{
                color: "rgb(255, 255, 255)",
                backgroundColor: "rgb(204, 0, 0)",
              }}
              id="error-dialog-button"
            >
              Home
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }
}

export default ErrorDialog;
