/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React, { Component } from "react";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FilterComponent from "../Shared/FilterComponent";
import styled from "styled-components";

const StyledTypography = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-body1": {
    margin: "0px",
    whiteSpace: "normal",
    fontSize: "14px",
    lineHeight: "1.6",
    fontWeight: "600",
    fontFamily: "Graphik, sans-serif",
    color: "rgb(102, 102, 102)",
    letterSpacing: '0.00938em'
  }
}))

const StyledGrid = styled(Grid)(({ groupLength }) => ({
  "&.MuiGrid-root": {
    "@media screen and (min-width: 1320px)": {
      flexGrow: '0',
      maxWidth: '25%',
      flexBasis: '25%',
    },
    "@media screen and (max-width : 1320px)": {
      flexGrow: '0',
      maxWidth: groupLength > 3 ? '25%' : '33.333333%',
      flexBasis: groupLength > 3 ? '25%' : '33.333333%',
    }
  },
  // "&.MuiGrid-grid-xs-3": {
  //   flexGrow: '0',
  //   maxWidth: '25%',
  //   flexBasis: '25%'
  // },
  // "&.MuiGrid-grid-xs-4": {
  //   flexGrow: '0',
  //   maxWidth: '33.333333%',
  //   flexBasis: '33.333333%'
  // }
}))

class FiltersGroupComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterOverrides: this.props.filterOverrides
    };
    this.updateParentValues = this.updateParentValues.bind(this);
    this.setHasChange = this.setHasChange.bind(this);
  }

  // Passes data through
  updateParentValues(title, value, includeNull) {
    this.props.updateParent(title, value, includeNull);
  }

  setHasChange = (hasChange) => {
    this.props.setHasChange(hasChange);
  };

  setFilterState = (bool) => {
    this.props.setFilterState(bool);
  };

  buildFilterComponent(
    filterKey,
    filtersData,
    columnMapperData,
    initialFilters,
    initialFilterData,
    filterOverrides
  ) {
    let filterData;
    let disabledFilter = false;
    filtersData.forEach((filter) => {
      if (filterKey === "self_firm_typeL1___") {
        // Unusable key, need to hardcode
        filterData = ["Private"];
        disabledFilter = true;
      } else if (filterOverrides.includes(filterKey)){
        filterData = filter[filterKey];
        disabledFilter = true;
      }
      else if (filterKey === Object.keys(filter)[0]) {
        filterData = filter[filterKey];
      }
    });

    return (
      <FilterComponent
        filterData={filterData}
        title={[filterKey]}
        key={filterKey}
        columnMapperData={columnMapperData}
        initialFilters={initialFilters}
        initialFilterData={initialFilterData}
        styleType={"Standard"}
        updateParent={this.updateParentValues}
        disabledFilter={disabledFilter}
        setHasChange={this.setHasChange}
        setFilterState={this.setFilterState}
        includeNull={true}
      />
    );
  }

  createFilterOverrideList = (filterOverrides) => {
    let foList = [];
    if(filterOverrides && filterOverrides.length > 0) {
      filterOverrides.forEach((filterOverride) => {
        let key = Object.keys(filterOverride)[0];
        foList.push(key);
      })
    }

    return foList;
  }

  render() {
    let filterGroups = this.props.filterGroups;
    let filtersData = this.props.filtersData;
    let columnMapperData = this.props.columnMapperData;
    let initialFilters = this.props.initialFilters;
    let initialFilterData = this.props.initialFilterData;
    let filterOverrides = this.createFilterOverrideList(this.props.filterOverrides);
    
    return (
      <Grid container spacing={1}>
        {filterGroups &&
          filterGroups.map((filterGroup) => (
              <StyledGrid item xs={filterGroups.length > 3 ? 3 : 4} xl={3} groupLength={filterGroups.length}>
              <StyledTypography>
                {filterGroup.title}
              </StyledTypography>
              {filterGroup &&
                filterGroup.includeFilters.map((filter) =>
                  this.buildFilterComponent(
                    filter,
                    filtersData,
                    columnMapperData,
                    initialFilters,
                    initialFilterData,
                    filterOverrides
                  )
                )}
              </StyledGrid>
          ))}
      </Grid>
    );
  }
}

export default FiltersGroupComponent;
