import React from "react";
import PropTypes from "prop-types";

const LIST = ["AND", "OR", "NOT", "~", "*"];

export const Keywords = ({ editor }) => {
  const handleClick = (event) => {
    const { id } = event.target.dataset;

    editor.insertText(` ${id} `);

    document.getElementById('query-editor').focus();
  };

  return (
    <div className="absolute flex gap-1 bottom-[8px] left-[8px]">
      {LIST.map((key) => (
        <span
          key={key}
          role="button"
          onClick={handleClick}
          data-id={key}
          className="min-w-0 w-8 bg-[#eae6ff] font-bold inline-block rounded text-center text-xs cursor-pointer"
        >
          {key}
        </span>
      ))}
    </div>
  );
};

Keywords.propTypes = {
  editor: PropTypes.any.isRequired,
};
