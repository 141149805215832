/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React from "react";
import Button from '@mui/material/Button';
import "../App.css";
import { numberFormatterCustom } from "./Shared/common";

//rangeContent for the Custom Screen
export const CustomRangeContent = ({
  rangeData,
  numericType,
  rangeStyleType,
  displayName
}) => {
  let minValues = numberFormatterCustom(rangeData.min);
  let maxValues = numberFormatterCustom(rangeData.max);
  const btnStyle = {
    padding: "0px 6px 0px 6px",
    fontSize: "12px",
    fontWeight: "normal",
    backgroundColor: "#f2f2f2",
    color: 'rgb(72, 72, 72)',
    minWidth: 'auto',
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px'
  };
  let result;
  if (rangeStyleType === "Standard") {
    result = (
      <span id="standard-range">
        {minValues} - {maxValues}{" "}
      </span>
    );
  } else {
    if (numericType === "$" || numericType === "€") {
      result = (
        <div data-testid='selectedFiltersWithNumeric' className="selected-filters" id="numeric-div">
          <Button style={btnStyle} variant="contained" id={`${displayName.replace(/\s/g,'')}-nummin`}>
            {numericType}
            {minValues}
          </Button>{" "}
          -{" "}
          <Button style={btnStyle} variant="contained" id={`${displayName.replace(/\s/g,'')}-nummax`}>
            {numericType}
            {maxValues}
          </Button>
        </div>
      );
    } else {
      result = (
        <div data-testid='selectedFiltersWithoutNumeric' className="selected-filters" id="nonnumeric-div">
          <Button style={btnStyle} variant="contained" id={`${displayName.replace(/\s/g,'')}-min`}>
            {minValues}
            {numericType}
          </Button>{" "}
          -{" "}
          <Button style={btnStyle} variant="contained" id={`${displayName.replace(/\s/g,'')}-max`}>
            {maxValues}
            {numericType}
          </Button>
        </div>
      );
    }
  }

  return <>{minValues && maxValues && <>{result}</>}</>;
};
