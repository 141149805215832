/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React, { Component } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FiltersGroupComponent from "./FiltersGroupComponent";
import Box from '@mui/material/Box';
import PropTypes from "prop-types";
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ marginTop: '15px' }}>
          <Typography style={{ display: 'flex' }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = theme => ({
  root: {
    '& div.MuiTabs-scroller': {
      '& .MuiTabs-flexContainer': {
        justifyContent: 'space-around'
      }
    }
  }
});

class TabsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {currentTab: 0};
    this.updateParentValues = this.updateParentValues.bind(this);
    this.setHasChange = this.setHasChange.bind(this);
  }

  // Passes data through to parent
  updateParentValues(title, value, includeNull) {
    this.props.updateParent(title, value, includeNull);
  }

  setHasChange = (hasChange) => {
    this.props.setHasChange(hasChange);
  };

  setFilterState = (bool) => {
    this.props.setFilterState(bool);
  };

  handleTabChange = (event, value) => {
    console.log(value)
    this.setState({currentTab: value})
  }

  render() {
    let screenerData = this.props.screenerData;
    let filtersData = this.props.filtersData;
    let columnMapperData = this.props.columnMapperData;
    let initialFilters = this.props.initialFilters;
    let initialFilterData = this.props.initialFilterData;
    let filterOverrides = this.props.filterOverrides;
    const { currentTab } = this.state;
    const { classes } = this.props;

    const styledTabs = {};
    console.log(screenerData)
    console.log('filtersData: ', filtersData)
    console.log('columnMapperData: ', columnMapperData)
    console.log('initialFilters: ', initialFilters)
    console.log('initialFilterData: ', initialFilterData)
    console.log('filterOverrides: ', filterOverrides)

    return (
      <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs id="tabContainer" value={currentTab} onChange={this.handleTabChange}  className={classes.root}>
        {screenerData.sectionTabs &&
          screenerData.sectionTabs.map((tab, i) => (
            console.log(screenerData.sectionTabs),
            <Tab label={tab.tabTitle} id={`tab-${tab.tabTitle}`} value={i} style={{ minWidth: `${(100/screenerData.sectionTabs.length)}%`, fontSize: '17px', color: currentTab === i ? '#E50627' : 'rgb(102, 102, 102)', fontWeight: currentTab === i ? '600' : '100' }}/>
          ))}
      </Tabs>
      </Box>
      {screenerData.sectionTabs &&
          screenerData.sectionTabs.map((tab, i) => (
            console.log(tab),
            <TabPanel value={currentTab} index={i}>
                <FiltersGroupComponent
                screenerData={screenerData}
                filterGroups={tab.filterGroups}
                filtersData={filtersData}
                columnMapperData={columnMapperData}
                initialFilters={initialFilters}
                initialFilterData={initialFilterData}
                updateParent={this.updateParentValues}
                setHasChange={this.setHasChange}
                filterOverrides={filterOverrides}
                setFilterState={this.setFilterState}
                />  
            </TabPanel>
          ))}
    </Box>
    );
  }
}

export default withStyles(useStyles)(TabsComponent);
