import React from "react";

export const IconGridDensity = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 4h7c.55 0 1 .45 1 1s-.45 1-1 1H3c-.55 0-1-.45-1-1s.45-1 1-1Zm3 7h4c.55 0 1 .45 1 1s-.45 1-1 1H6c-.55 0-1-.45-1-1s.45-1 1-1Zm4 7H8c-.55 0-1 .45-1 1s.45 1 1 1h2c.55 0 1-.45 1-1s-.45-1-1-1Zm9-1.356 1.305-1.26a1 1 0 0 1 1.39 1.439l-3 2.897a1.005 1.005 0 0 1-1.402-.013l-3-3a.999.999 0 1 1 1.414-1.414L17 16.586v-9.23l-1.305 1.26a1 1 0 0 1-1.39-1.44l3-2.896a1.002 1.002 0 0 1 1.402.013l3 3a1 1 0 0 1-1.414 1.414L19 7.414v9.23Z"
      fill="#666"
    />
  </svg>
);
