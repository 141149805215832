import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector
} from "@mui/x-data-grid-pro";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import _ from "lodash";

import { IconGridDensity } from "../../../Icons/IconGridDensity";
import { IconTableColumns } from "../../../Icons/IconTableColumns";

import { Component as DataGridPro } from "../styles";

function CustomToolbar({
  handleSwitch,
  isChecked,
}) {
  return (
    <GridToolbarContainer className="ml-2 flex justify-between table-toolbar">
      <div className="flex gap-3">
        <Tooltip title="Columns">
          <GridToolbarColumnsButton className="min-w-0 m-0 toolbar-button rounded-full" />
        </Tooltip>

        <Tooltip title="Row height">
          <GridToolbarDensitySelector className="min-w-0 m-0 toolbar-button rounded-full" />
        </Tooltip>
      </div>

      {!JSON.parse(process.env.REACT_APP_HIDE_CONFIDENCE_LEVEL) && (
        <FormControlLabel
          className="text-sm confidence-level-switcher"
          control={(
            <Switch
              checked={isChecked}
              onChange={handleSwitch}

              classes={{
                track: isChecked ? 'bg-cred-50 opacity-100' : 'bg-black',
                checked: 'text-white'
              }}
            />
          )}
          label="Show confidence level"
          classes={{
            label: 'text-xs text-cgray-50'
          }}
        />
      )}
    </GridToolbarContainer>
  );
}

export const TableGridUI = ({
  isLoading,
  columns,
  rows,
  pageSize,
  isConfidenceLevelChecked,
  pageNumber,
  sortModel,
  totalNumRows,
  handleSwitch,
  countText,
  handlePageChange,
  selectionModel,
  handleSortChange,
  getCheckboxBainIds,
  handleRowClick,
  handleColumnHeader,
}) => {
  return (
    <DataGridPro
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      checkboxSelection
      autoHeight={false}
      rowHeight={35}
      width={"auto"}
      rowCount={
        totalNumRows < 5000 ? totalNumRows : 5000
      }
      loading={_.isEmpty(rows) && isLoading}
      pagination
      showToolbar
      localeText={{
        toolbarColumns: "",
        toolbarDensity: "",
      }}
      components={{
        ColumnSelectorIcon: IconTableColumns,
        DensityComfortableIcon: IconGridDensity,
        DensityStandardIcon: IconGridDensity,
        DensityCompactIcon: IconGridDensity,
        Toolbar: () => (
          <CustomToolbar
            handleSwitch={handleSwitch}
            isChecked={isConfidenceLevelChecked}
          />
        ),
        NoRowsOverlay: () => (
          <div className="h-full flex justify-center items-center italic text-[#66666d]">
            No results found. Please try different search criteria.
          </div>
        ),
      }}
      countText={countText}
      classes={{ cell: 'relative' }}
      disableColumnPinning={true}
      disableColumnSelector={false}
      page={pageNumber}
      paginationMode="server"
      onPageChange={(params) => {
        handlePageChange(params);
      }}
      sortingMode="server"
      sortModel={sortModel}
      selectionModel={selectionModel}
      onSortModelChange={handleSortChange}
      onSelectionModelChange={(selections) => {
        getCheckboxBainIds(selections, rows);
      }}
      onRowClick={handleRowClick}
      onColumnHeaderClick={handleColumnHeader}
    />
  );
};

TableGridUI.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(Object).isRequired,
  rows: PropTypes.arrayOf(Object).isRequired,
  pageSize: PropTypes.number.isRequired,
  isConfidenceLevelChecked: PropTypes.bool.isRequired,
  pageNumber: PropTypes.number.isRequired,
  sortModel: PropTypes.arrayOf(Object).isRequired,
  totalNumRows: PropTypes.number.isRequired,
  handleSwitch: PropTypes.func.isRequired,
  countText: PropTypes.string.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  selectionModel: PropTypes.arrayOf(Object).isRequired,
  handleSortChange: PropTypes.func.isRequired,
  getCheckboxBainIds: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  handleColumnHeader: PropTypes.func.isRequired,
};

export const TableGrid = memo(TableGridUI);
