/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React from "react";
import Tooltip from "@mui/material/Tooltip";

// Used to update the resetable values on numeric filters
export async function updateInitialFilterList(filterNames, selectedFilters, initialFilterData) {
    let returnVal;
    filterNames.forEach((filterName) => {
        initialFilterData.forEach((filter) => {
            if(filter[filterName] === undefined) {
              // new filter needs to be added
              returnVal = selectedFilters.filter(function (filter) {
                return Object.keys(filter)[0] === filterName;
              });
              returnVal = JSON.parse(JSON.stringify(returnVal[0]));
            }
        })
    })

    if(!initialFilterData.includes(returnVal)) {
        initialFilterData.push(returnVal);
      }

    return initialFilterData;
  }


  export function numberFormatterCustom(num) {
    const si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "Q" },
      { value: 1e18, symbol: "E" },
    ];
  
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    // for negative value
    for (i = si.length - 1; i > 0; i--) {
      if (Math.abs(num) >= si[i].value) {
        break;
      }
    }
    let numDigits = Math.round(num / si[i].value).toString().length;
    let decimalTwo = si[i].symbol === 'B' && numDigits === 1;
    let decimalOne = si[i].symbol === 'B' && numDigits === 2;

    let toFixedValue = decimalTwo ? 2 : decimalOne ? 1 : 0;
  
    return (
      (num / si[i].value)
        .toFixed(toFixedValue)
        + si[i].symbol
    );
  }

  export function dataNotAvailable() {
    return (
      <Tooltip title={"Data not available"} data-testid='dataNotAvailable' id="data-not-available">
        <span style={{ position: "relative" }}>
          <span
            style={{
              borderRadius: "6px",
              backgroundColor: "#d3d3d3",
              padding: 0,
              height: "6px",
              width: "20px",
              position: "absolute",
              left: "5px",
            }}
          ></span>
        </span>
      </Tooltip>
    );
  }

  export function dataNotAvailablePage() {
    return (
      <Tooltip title={"Data not available"} data-testid='dataNotAvailablePage' id="data-not-available-page">
        <span style={{ position: "relative" }}>
          <span
            style={{
              borderRadius: "6px",
              backgroundColor: "#d3d3d3",
              padding: 0,
              height: "6px",
              width: "20px",
              position: "absolute",
              top: "8px",
              left: "5px"
            }}
          ></span>
        </span>
      </Tooltip>
    );
  }

export function financialRevenueValue(revenue, minRevenue, maxRevenue, currency = 'EUR') {
  let ret = revenue ? `${currencySign(currency)}${numberFormatterCustom(revenue)}` : "";
  const min = numberFormatterCustom(minRevenue);
  const max = numberFormatterCustom(maxRevenue);
  return (min && max && min !== "0" && max !== "0" && min !== max) ? `~${ret} (${min}-${max})` : ret;
}

export function currencySign(currency) {
  switch (currency) {
    case "EUR":
      return "€";
    case "USD":
      return "$";
    default:
      return "";
  }
}
