import React from 'react';
import PropTypes from "prop-types";

export const Main = ({ children }) => (
  <main data-testid="appComponent">
    {children}
  </main>
);

Main.propTypes = {
  children: PropTypes.node.isRequired,
};
