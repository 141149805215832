/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React, { Component } from "react";
import { connect } from "react-redux";
import API from "@aws-amplify/api-rest";
import { Chart, CategoryScale, LinearScale, BarController, BarElement, Tooltip } from 'chart.js';

Chart.register(CategoryScale, LinearScale, BarController, BarElement, Tooltip);

const colors = [
  "#63b598",
  "#ce7d78",
  "#ea9e70",
  "#a48a9e",
  "#c6e1e8",
  "#648177",
  "#0d5ac1",
  "#f205e6",
  "#1c0365",
  "#14a9ad",
  "#4ca2f9",
  "#a4e43f",
  "#d298e2",
  "#6119d0",
  "#d2737d",
  "#c0a43c",
  "#f2510e",
  "#651be6",
  "#79806e",
  "#61da5e",
  "#cd2f00",
  "#9348af",
  "#01ac53",
  "#c5a4fb",
  "#996635",
  "#b11573",
  "#4bb473",
  "#75d89e",
  "#2f3f94",
  "#2f7b99",
  "#da967d",
  "#34891f",
  "#b0d87b",
  "#ca4751",
  "#7e50a8",
  "#c4d647",
  "#e0eeb8",
  "#11dec1",
  "#289812",
  "#566ca0",
  "#ffdbe1",
  "#2f1179",
  "#935b6d",
  "#916988",
  "#513d98",
  "#aead3a",
  "#9e6d71",
  "#4b5bdc",
  "#0cd36d",
  "#250662",
  "#cb5bea",
  "#228916",
  "#ac3e1b",
  "#df514a",
  "#539397",
  "#880977",
  "#f697c1",
  "#ba96ce",
  "#679c9d",
  "#c6c42c",
  "#5d2c52",
  "#48b41b",
  "#e1cf3b",
  "#5be4f0",
  "#57c4d8",
  "#a4d17a",
  "#225b8",
  "#be608b",
  "#96b00c",
  "#088baf",
  "#f158bf",
  "#e145ba",
  "#ee91e3",
  "#05d371",
  "#5426e0",
  "#4834d0",
  "#802234",
  "#6749e8",
  "#0971f0",
  "#8fb413",
  "#b2b4f0",
  "#c3c89d",
  "#c9a941",
  "#41d158",
  "#fb21a3",
  "#51aed9",
  "#5bb32d",
  "#807fb",
  "#21538e",
  "#89d534",
  "#d36647",
  "#7fb411",
  "#0023b8",
  "#3b8c2a",
  "#986b53",
  "#f50422",
  "#983f7a",
  "#ea24a3",
  "#79352c",
  "#521250",
  "#c79ed2",
  "#d6dd92",
  "#e33e52",
  "#b2be57",
  "#fa06ec",
  "#1bb699",
  "#6b2e5f",
  "#64820f",
  "#1c271",
  "#21538e",
  "#89d534",
  "#d36647",
  "#7fb411",
  "#0023b8",
  "#3b8c2a",
  "#986b53",
  "#f50422",
  "#983f7a",
  "#ea24a3",
  "#79352c",
  "#521250",
  "#c79ed2",
  "#d6dd92",
  "#e33e52",
  "#b2be57",
  "#fa06ec",
  "#1bb699",
  "#6b2e5f",
  "#64820f",
  "#1c271",
  "#9cb64a",
  "#996c48",
  "#9ab9b7",
  "#06e052",
  "#e3a481",
  "#0eb621",
  "#fc458e",
  "#b2db15",
  "#aa226d",
  "#792ed8",
  "#73872a",
  "#520d3a",
  "#cefcb8",
  "#a5b3d9",
  "#7d1d85",
  "#c4fd57",
  "#f1ae16",
  "#8fe22a",
  "#ef6e3c",
  "#243eeb",
  "#1dc18",
  "#dd93fd",
  "#3f8473",
  "#e7dbce",
  "#421f79",
  "#7a3d93",
  "#635f6d",
  "#93f2d7",
  "#9b5c2a",
  "#15b9ee",
  "#0f5997",
  "#409188",
  "#911e20",
  "#1350ce",
  "#10e5b1",
  "#fff4d7",
  "#cb2582",
  "#ce00be",
  "#32d5d6",
  "#17232",
  "#608572",
  "#c79bc2",
  "#00f87c",
  "#77772a",
  "#6995ba",
  "#fc6b57",
  "#f07815",
  "#8fd883",
  "#060e27",
  "#96e591",
  "#21d52e",
  "#d00043",
  "#b47162",
  "#1ec227",
  "#4f0f6f",
  "#1d1d58",
  "#947002",
  "#bde052",
  "#e08c56",
  "#28fcfd",
  "#bb09b",
  "#36486a",
  "#d02e29",
  "#1ae6db",
  "#3e464c",
  "#a84a8f",
  "#911e7e",
  "#3f16d9",
  "#0f525f",
  "#ac7c0a",
  "#b4c086",
  "#c9d730",
  "#30cc49",
  "#3d6751",
  "#fb4c03",
  "#640fc1",
  "#62c03e",
  "#d3493a",
  "#88aa0b",
  "#406df9",
  "#615af0",
  "#4be47",
  "#2a3434",
  "#4a543f",
  "#79bca0",
  "#a8b8d4",
  "#00efd4",
  "#7ad236",
  "#7260d8",
  "#1deaa7",
  "#06f43a",
  "#823c59",
  "#e3d94c",
  "#dc1c06",
  "#f53b2a",
  "#b46238",
  "#2dfff6",
  "#a82b89",
  "#1a8011",
  "#436a9f",
  "#1a806a",
  "#4cf09d",
  "#c188a2",
  "#67eb4b",
  "#b308d3",
  "#fc7e41",
  "#af3101",
  "#ff065",
  "#71b1f4",
  "#a2f8a5",
  "#e23dd0",
  "#d3486d",
  "#00f7f9",
  "#474893",
  "#3cec35",
  "#1c65cb",
  "#5d1d0c",
  "#2d7d2a",
  "#ff3420",
  "#5cdd87",
  "#a259a4",
  "#e4ac44",
  "#1bede6",
  "#8798a4",
  "#d7790f",
  "#b2c24f",
  "#de73c2",
  "#d70a9c",
  "#25b67",
  "#88e9b8",
  "#c2b0e2",
  "#86e98f",
  "#ae90e2",
  "#1a806b",
  "#436a9e",
  "#0ec0ff",
  "#f812b3",
  "#b17fc9",
  "#8d6c2f",
  "#d3277a",
  "#2ca1ae",
  "#9685eb",
  "#8a96c6",
  "#dba2e6",
  "#76fc1b",
  "#608fa4",
  "#20f6ba",
  "#07d7f6",
  "#dce77a",
  "#77ecca",
];

const ITEM_HEIGHT = 90;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 440,
    },
  },
};


class NewsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: [],
      barDataSets: [],
      subData: [],
      popUpTitle: '',
      popUpDate: ''
    }
    this.newsChart = null
    this.subNewsChart = null
    this.handleToolTip = this.handleToolTip.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  chartRef = React.createRef();
  subChartRef = React.createRef();

  getChartData = async() => {
    let data = this.props.news;
    const labels = [];
    const barDataSets = [];
    let testSet = [];
    data.forEach((item, index) => {
      // Unique list of labels
      if (!labels.includes(item.end_date)) {
        labels.push(item.end_date);
      }
      // Unique list of topic groups
      if (!barDataSets.some(bds => bds.label === item.topic_grouping)) {
        const itemColumn = {
          label: item.topic_grouping,
          data: [],
          backgroundColor: colors[index]
        };
        barDataSets.push(itemColumn);
        testSet.push(JSON.parse(JSON.stringify(itemColumn)));
      }
    });

    const dataArr = new Array(labels.length).fill(0);
    barDataSets.forEach(function(bds) {
      bds.data = dataArr
    })

    labels.forEach(function (labelDate, labelIndex) {
      data.forEach(dataItem => { 
          if(dataItem.end_date === labelDate){
            // can have multiple data items
            barDataSets.forEach(function (bds, index) {
              if(bds.label === dataItem.topic_grouping) {
                // only 4 bar data sets
                let newArr = JSON.parse(JSON.stringify(bds.data))
                newArr[labelIndex] = dataItem.hit_count
                barDataSets[index].data = newArr
              } 
            }) 
          } 
        })
    });
    await this.setState({ barDataSets: barDataSets, labels: labels });
  }

  async setupPage(){
    if (this.props.news) {
      await this.getChartData();
      let { labels, barDataSets } = this.state;
      const barChartRef = this.chartRef.current.getContext("2d");
      if(window.newsChart instanceof Chart){
        window.newsChart.destroy();
      }
      window.newsChart = new Chart(barChartRef, {
          type: 'bar',
          id:'newsChart',
          data: {
            labels: labels,
            datasets: barDataSets
          },
          options: {
            interaction: {
              mode: 'nearest'
            },
            onClick: (e) => {
              try{
                let date = e.chart.tooltip.title[0]
                let category = e.chart.tooltip.body[0].lines[0].split(':')[0]

                this.handleToolTip(date, category);
        
                let modal = document.getElementById("myModal");
                modal.style.display = "block";
              }
              catch(e) {
                console.log(e)
                console.log('No News data')
              }
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: true,
            }
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          }
      }
    })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.news !== this.props.news) {
      this.setupPage();
    }
  }

  async componentDidMount() {
    await this.setupPage()
  }

  async handleToolTip(date, category) {
    let bainId = this.props.bainId;
    let toolTip = []
    if(date && category) {
      let requestConfig = { 
        queryStringParameters: { 
          topic_grouping: category,
          end_date: date,
          currency: this.props.currency,
        },
      };
      await API.get("CDPAPI", `/company_profile/news/${bainId}`, requestConfig).then((response) => {
        const returnData = response.data;
        this.setState({subData: returnData, popUpDate: date, popUpTitle: category})
    })
    }
  
    return toolTip.length > 0 ? toolTip : 'Loading...'
  }

  handleModalOpen() {
    let modal = document.getElementById("myModal");
    modal.style.display = "block";
  }

  handleModalClose(){
    let modal = document.getElementById("myModal");
    this.setState({subData: [], popUpDate: '', popUpTitle: ''})
    modal.style.display = "none";
  }

  renderSubDataChart(subData){
    if(subData.length > 0 && this.subChartRef.current){
      if(window.subNewsChart instanceof Chart){
        window.subNewsChart.destroy();
      }
      let subChartNewsRef = this.subChartRef.current.getContext("2d");
      let labels = []
      let dataSets = []
      subData.forEach((item) => {
        labels.push(item.subject_name)
        dataSets.push(item.HitCount)
      })

      window.subNewsChart = new Chart(subChartNewsRef, {
        type: 'bar',
        id:'subNewsChart',
        data: {
          labels: labels,
          dataSets: dataSets,
          datasets: [{
            data: dataSets,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 205, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(201, 203, 207, 0.2)'
            ],
            borderColor: [
              'rgb(255, 99, 132)',
              'rgb(255, 159, 64)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)',
              'rgb(54, 162, 235)',
              'rgb(153, 102, 255)',
              'rgb(201, 203, 207)'
            ],
            borderWidth: 1
          }]
        },
        options: {
          interaction: {
            mode: 'nearest'
          },
          plugins: {
            legend: {
              display: true,
              labels: {
                color: 'rgb(255, 99, 132)'
            }
            },
            tooltip: {
              enabled: true,
              }
            },
            responsive: true
        }
      })
    }
  }

  render() {
    let { subData, popUpTitle, popUpDate } = this.state;

    if (this.props.news) {
      this.renderSubDataChart(subData);

      return (
        <div>
          <canvas id="barChart" width="1200" height="480" ref={this.chartRef}></canvas>
          <div id="myModal" className="modal">
            <div className="modal-content">
              <span className="close" onClick={this.handleModalClose} id="close-span">&times;</span>
              {
                popUpDate && popUpTitle &&
                <div>
                <p>Topic: <b>{popUpTitle}</b></p>
                <p>Date: <b>{popUpDate}</b></p>
                </div>
              }
              {
                !subData.length > 0 &&
                <p id="modalInnerText">Loading...</p>
              }
              {/* <button onClick={this.filterSubChart}>Test Filter</button> */}
              <canvas id="subNewsChart" width="1200" height="480" ref={this.subChartRef}></canvas> 
            </div>
          </div>
        </div>
      );
    }
    else {
      return (
        <div>Generating Chart...</div>
      )
    }
  }
}

export default connect(
  (state) => ({
    currency: state.customScreen.currency,
  }),
)(NewsTab);