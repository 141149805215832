import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const {
  reducer, actions,
} = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, { payload: { id, data } }) => {
      state[id] = { isOpen: true, data };
    },
    closeModal: (state, { payload: { id, data } }) => {
      state[id] = { isOpen: false, data };
    },
  },
});
