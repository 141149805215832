import { createAsyncThunk } from '@reduxjs/toolkit';
import { LicenseInfo } from "@mui/x-data-grid-pro";
import API from "@aws-amplify/api-rest";

import { addPendoScript } from '../../../services/pendo';
import { actions } from '../index';

export const fetchSecrets = createAsyncThunk(
  'system/fetchSecrets',
  async (_, { dispatch }) => {
    try {
      const result = await API.get('CDPAPI', '/user_interface_secrets');

      LicenseInfo.setLicenseKey(result?.mui);
      await addPendoScript(result?.pendo);

      dispatch(actions.setSecrets(result));
    } catch (error) {
      alert(error);
    }
  },
);
