import { createSlice } from "@reduxjs/toolkit";

import { fetchBainIds } from "./thunks/fetch-bain-ids";

const initialState = {
  bainIds: { isLoading: false, isError: false, errorStatus: null, errorMessage: null, data: [] },
  searchText: "",
  searchQueries: [],
  hasChange: false,
  isLuceneQuery: true
};

export const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setIsLoading: (state, { payload: { value, key } }) => {
      state[key].isLoading = value;
    },
    setIsError: (state, { payload: { value, key, status = null, message = null } }) => {
      state[key].isError = value;
      state[key].errorStatus = status;
      state[key].errorMessage = message;

    },
    setBainIds: (state, { payload }) => {
      state.bainIds.data = payload;
    },
    setSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    setIsLuceneQuery: (state, { payload }) => {
      state.isLuceneQuery = payload;
    },
    setSearchQueries: (state, { payload }) => {
      state.searchQueries = payload;
    },
    removeSearchQuery: (state) => {
      state.searchQueries = [];
    },
    setHasChange: (state, { payload }) => {
      state.hasChange = payload;
    },
  },
});

export const reducer = slice.reducer;
export const actions = {
  ...slice.actions,
  fetchBainIds,
};
