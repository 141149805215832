/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React, { Component, createRef } from "react";
import { withRouter } from "react-router";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";
import { connect } from "react-redux";
import API from "@aws-amplify/api-rest";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { InfoOutlined } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import Skeleton from "@mui/material/Skeleton";
import _ from "lodash";

import { useModal } from "../../../hooks/use-modal";
import { currencySign, dataNotAvailable, financialRevenueValue, numberFormatterCustom } from "../../Shared/common";
import { TableAlert } from "../TableAlert";
import { actions } from "../../../slices/custom-screen";
import { TableGrid } from './TableGrid';
import { getIcon } from './utilts';
import { MODALS } from '../../../constants';

import "./index.scss";

const FIXED_ELASTIC_SEARCH_OUTPUT = 10000;
const TABLE_OFFSET = 16;

const OBSERVER_IDS = {
  customScreen: 'company-filters',
  Screener: 'screener-filters',
};

const CompanyCell = ({
  params,
}) => {
  const { handleOpen } = useModal(MODALS.COMPANY_PROFILE);

  const handleClick = () => {
    handleOpen({
      params: {
        row: params.row,
        value: params.value,
      },
    });
  };

  return (
    <span
      id="open-company-profile"
      onClick={handleClick}
    >
      <Tooltip title={params.value.length > 20 ? params.value : ""}>
        <Button
          className="min-w-0 normal-case text-black px-2.5 py-2"
          id={params.value}
        >
          {params.value}
        </Button>
      </Tooltip>
    </span>
  )
};

class ScreenerTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: this.props.filters,
      columns: [],
      includeNullFilters: this.props.includeNullFilters,
      columnMapperData: this.props.columnMapperData,
      showColumns: [
        "self_firmo_name___",
        "self_firmo_description___",
        "self_firmo_webpage___",
        "self_location_country___",
        "self_sector_industry___",
        "self_firmo_employeeCount___",
        "self_financials_ebitda___",
        "self_financials_netIncome___",
        "self_financials_revenue___",
        "self_financials_revenueCagr_1yr__",
      ],
      columnSortOrder: [
        "self_firmo_name___",
        "self_firmo_description___",
        "self_firmo_webpage___",
        "self_location_country___",
        "self_sector_industry___",
        "self_firmo_employeeCount___",
        "self_financials_ebitda___",
        "self_financials_netIncome___",
        "self_financials_revenue___",
        "self_financials_revenueCagr_1yr__",
      ],
      showCompanyProfile: false,
      selectedCompanyParams: { row: {} },
      selectionModel: [],
      reqBody: {},
      sortModel: [],
      rows: [],
      isCompanyProfileLoading: false,
      pageNumber: 0,
      pageFrom: 0,
      pageSize: 50,
      totalNumRows: 9999,
      companyProfileDetails: [],
      companyProfileNews: [],
      companyProfileMultiYear: [],
      companyProfileSubsidiaries: [],
      companyProfileMa: [],
      companyBainId: null,
      isConfidenceLevelChecked: false,
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.parseTableData = this.parseTableData.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.getCheckboxBainIds = this.getCheckboxBainIds.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.observer$ = new MutationObserver(this.mutationObserver.bind(this));
    this.ref = createRef();
  }

  componentDidMount() {
    const { childRef } = this.props;

    childRef(this);

    const path = this.props.location.pathname.split('/')[1];
    const observerId = OBSERVER_IDS[path];
    const filtersNode = document.getElementById(observerId);

    this.observer$.observe(filtersNode, {
      childList: true,
      characterData: true,
      attributes: true,
      subtree: true
    });

    document.body.classList.add('overflow-hidden');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currency !== this.props.currency) {
      this.getTableData();
    }
  }

  componentWillUnmount() {
    const { childRef } = this.props;

    childRef(undefined);
    this.props.setSelectedCompanies([]);

    this.observer$.disconnect();

    document.body.classList.remove('overflow-hidden');
  }

  mutationObserver() {
    if (this.ref.current) {
      const rect = this.ref.current.getBoundingClientRect();
      const height = rect.top + TABLE_OFFSET;

      this.ref.current.style.height = `calc(100vh - ${height}px)`;
    }
  }

  async updateTotalPageCount(countDataResponse) {
    let totalCount = countDataResponse.data[0].row_count;
    this.setState({ totalNumRows: totalCount });
  }

  getCountText() {
    let countText;
    const bainIdsCount = this.props.bainIds.length;
    const totalNumRows = this.state.totalNumRows;

    if (bainIdsCount == FIXED_ELASTIC_SEARCH_OUTPUT && bainIdsCount == totalNumRows) {
      countText = `' (${totalNumRows.toLocaleString()}+ found)' `;
    } else if (totalNumRows > 5000) {
      countText = `' (${totalNumRows.toLocaleString()} found)' `;
    } else {
      countText = "";
    }

    return countText;
  }

  mapColumnName(colName) {
    let columnDisplayName;
    this.props.columnMapperData.forEach((data) => {
      if (colName === data["Backend Name"]) {
        columnDisplayName = data["Display Name"];
      }
    });

    return columnDisplayName;
  }

  renderCustomCell(params, displayName) {
    const currency = this.props.currency;

    let columnName = params["field"];
    let row = params["row"];
    let returnValue = params["row"][columnName];
    let resultConfidence = "";
    let resultMetadata = "";

    Object.keys(row).forEach(() => {
      let confidence = `${columnName}confidence`;
      let metadata = `${columnName}metadata`;
      resultConfidence = row[confidence];
      resultMetadata = row[metadata];
    });

    const {
      text: scoreText,
      description: scoreDescription,
      Icon: IconComponent,
      props,
    } = getIcon(resultConfidence);
    const shouldIconShowed = this.state.isConfidenceLevelChecked
      && !!IconComponent
      && !_.isNil(returnValue)
      && returnValue !== 'UNKNOWN';

    const Icon = shouldIconShowed && (
      <IconComponent
        color="error"
        fontSize="small"
        className="absolute"
        style={{ ...props.style, width: 15, left: 8, top: 7 }}
      />
    );

    let bold = {
      fontWeight: 900,
      fontSize: 11,
    };

    let cellColorStyle = {
      backgroundColor:
        returnValue > 0.5
          ? "#DEF3E5"
          : returnValue < -0.5
            ? "#FAC8CB"
            : "#DDDDDD",
      color:
        returnValue > 0.5
          ? "#4BBE70"
          : returnValue < -0.5
            ? "#CC2936"
            : "#8a8a8a",
    };

    const toolTipWindow = (
      <div>
        {!!resultConfidence && (
          <>
            <div>
              <b>{`Confidence score: (${resultConfidence}) ${scoreText}`} </b>
              {!!IconComponent && <IconComponent fontSize="small" style={{ width: 15 }} />}
              <br />
              <b>{scoreDescription}</b>
            </div>
            <br />
          </>
        )}
        <b style={bold}>{displayName}: </b> {params["row"][columnName]}

        <br />
        <b style={bold}>Origin: </b>
        {resultMetadata}
      </div>
    );

    let filterType;
    this.props.columnMapperData.forEach((data) => {
      if (displayName === data["Display Name"]) {
        filterType = data["NUMERIC_TYPE"];
      }
    });

    if (filterType === "%") {
      return (
        <Tooltip title={toolTipWindow}>
          <div style={cellColorStyle} className="percentageCellStyle">
            {Icon}
            <div className="pl-4">
              {returnValue === 0
                ? returnValue
                : returnValue === null
                  ? ""
                  : `${Math.round(returnValue)}%`}
            </div>
          </div>
        </Tooltip>
      );
    } else if ((filterType === "EUR" || filterType === "USD") && params["field"] === "self_financials_revenue___") {
      const value = returnValue ?
        financialRevenueValue(row["self_financials_revenue___"], row["self_financials_revenue___min"], row["self_financials_revenue___max"], currency)
        : dataNotAvailable();

      return (
        <Tooltip title={toolTipWindow}>
          <div>
            {Icon}
            <div className="pl-4">
              {value}
            </div>
          </div>
        </Tooltip>
      );
    } else if (filterType === "EUR" || filterType === "USD") {
      const value = returnValue ? currencySign(currency) + numberFormatterCustom(returnValue) : dataNotAvailable();

      return (
        <Tooltip title={toolTipWindow}>
          <div>
            {Icon}
            <div className="pl-4">{value}</div>
          </div>
        </Tooltip>
      );
    } else {
      let result = "";
      if (!returnValue || returnValue === "UNKNOWN") {
        result = <span>{dataNotAvailable()}</span>;
      }
      else if (typeof returnValue === 'number') {
        result = <span>{numberFormatterCustom(returnValue)}</span>
      } else {
        result = <span>{returnValue}</span>
      }

      return (
        <Tooltip title={toolTipWindow}>
          <div>
            {Icon}
            <div className="pl-4">
              {result}
            </div>
          </div>
        </Tooltip>
      );
    }
  }

  buildColumnsForTable(data) {
    const { columnMapperData } = this.props;
    let columns = Object.keys(data[0]);
    const backendKeysFromMapper = _.map(columnMapperData, 'Backend Name');
    const currency = this.props.currency;

    columns.forEach((column) => {
      if (!backendKeysFromMapper.includes(column) && column != "rownum") {
        columns = columns.filter((col) => col !== column);
      }
    });

    const idx1 = _.findIndex(columns, (item) => item === 'self_sector_industry___');
    const idx2 = _.findIndex(columns, (item) => item === 'self_sector_industryGroup___');

    [columns[idx2], columns[idx1]] = [columns[idx1], columns[idx2]];

    let COLUMNS = [
      {
        ...{ ...GRID_CHECKBOX_SELECTION_COL_DEF, headerName: 'Check', },
        renderHeader: () => {
          return (
            <Checkbox
              checked={this.props.isSelectedAll}
              disableRipple
              disableFocusRipple
            />
          )
        },
        renderCell: (params) => {
          const selectedCompanies = _.keyBy(this.props.selectedCompanies, 'id');

          return (
            <Checkbox
              checked={!!selectedCompanies[params.row.bain_id]}
              disableRipple
              disableFocusRipple
            />
          );
        }
      },
    ];
    columns = this.sortDefaultColumns(columns, this.state.columnSortOrder);

    for (let key in columns) {
      let columnName = columns[key];
      let colHeader = this.mapColumnName(columnName);

      let obj;
      if (columnName === "rownum") {
        obj = {
          field: "id",
          headerName: "ID",
          width: 200,
          hide: true,
          renderCell: (params) => (
            <Tooltip title={params["row"]["id"]}>
              <span>{params["row"]["id"]}</span>
            </Tooltip>
          ),
        };
      } // CompanyProfile popup
      else if (columnName === "self_firmo_name___") {
        obj = {
          field: columnName,
          headerName: colHeader,
          renderHeader: () => (
            <>
              <span
                className="MuiDataGrid-columnHeaderTitle"
                style={{ marginLeft: "10px", marginTop: '-2px' }}
              >
                {colHeader}
              </span>
              <Tooltip
                title={<b>Click on company's name for more details</b>}
                arrow
              >
                <span style={{ marginLeft: "4px" }}>
                  <InfoOutlined fontSize="small" id="info-outlined" />
                </span>
              </Tooltip>
            </>
          ),
          width: 210,
          renderCell: (params) => this.props.isLoading
            ? <Skeleton className="w-full h-full bg-[#0000000f]" />
            : <CompanyCell params={params} />
        };
      } else if (columnName === "self_financials_revenue___") {
        obj = {
          field: columnName,
          headerName: `${colHeader} (${currency})`,
          width: 200,
          renderCell: (params) => this.props.isLoading
            ? <Skeleton className="w-full h-full bg-[#0000000f]" />
            : this.renderCustomCell(params, colHeader),
        };
      } // next hide any columns not in original list
      else if (!this.state.showColumns.includes(columnName)) {
        obj = {
          field: columnName,
          headerName: colHeader,
          width: 200,
          hide: true,
          renderCell: (params) => this.props.isLoading
            ? <Skeleton className="w-full h-full bg-[#0000000f]" />
            : this.renderCustomCell(params, colHeader),
        };
      } else {
        obj = {
          field: columnName,
          headerName: colHeader,
          width: 200,
          renderCell: (params) => this.props.isLoading
            ? <Skeleton className="w-full h-full bg-[#0000000f]" />
            : this.renderCustomCell(params, colHeader),
        };
      }
      COLUMNS.push(obj);
    }

    this.setState({ columns: COLUMNS });
  }

  async getTableData(newFilters = false, filterOverrides = null, updateTotalPageCount = true) {
    let { bainIds, currency } = this.props;

    await this.updateActiveColumns();
    this.props.setIsLoading(true);

    if (newFilters === true) {
      this.setState({ pageFrom: 0, pageNumber: 0 });
    }

    let body = {
      sql_query: true,
      page_from: this.state.pageFrom,
      page_size: this.state.pageSize,
    };

    //bain ids
    if (bainIds.length > 0) {
      body["self_ids_id__cdp_"] = bainIds;
    }

    // Add Sorting
    if (this.state.sortModel.length > 0) {
      if (
        this.state.sortModel[0].field != undefined &&
        this.state.sortModel[0].sort != undefined
      ) {
        body["sort_key"] = this.state.sortModel[0].field;
        body["sort_order"] = this.state.sortModel[0].sort;
      }
    }

    // Add Filters
    let includeNullList = this.props.includeNullFilters;
    this.props.filters.forEach(function (item) {
      let itemValues = Object.values(item)[0];
      let itemKey = Object.keys(item)[0];
      // numerical filter
      if ("min" in itemValues && !Array.isArray(itemValues)) {
        body[itemKey] = itemValues;
      }
      // select filter
      if (Array.isArray(itemValues)) {
        if (filterOverrides != null && filterOverrides.includes(itemKey)) {
          body[itemKey] = itemValues;
        } else if (!itemValues.includes("All") && itemValues.length !== 0) {
          body[itemKey] = itemValues;
        }
      }
    });
    // Add null list to body if present
    if (includeNullList.length > 0) {
      body["include_null_list"] = includeNullList;
    }

    let bodyWithCount = Object.assign({}, body);
    bodyWithCount["get_count"] = true;

    const tableDataRequest = API.post("CDPAPI", `/midtier?currency=${currency}`, {
      body: body
    });

    const requests = updateTotalPageCount ? [API.post("CDPAPI", `/midtier?currency=${currency}`, {
      body: bodyWithCount
    }), tableDataRequest] : [tableDataRequest];

    // Query RDS Data and Count
    await Promise.all(requests).then((response) => {
      if (updateTotalPageCount) {
        this.updateTotalPageCount(response[0]);
      }
      const tableData = response[updateTotalPageCount ? 1 : 0].data;
      this.buildColumnsForTable(tableData);
      this.parseTableData(tableData);

      this.setState(() => ({
        reqBody: body
      }));

      this.props.setIsLoading(false);
    });
  }

  async handlePageChange(newPage) {
    let currentPage = this.state.pageNumber;
    // New page greater
    if (!this.props.isLoading && newPage > currentPage) {
      // Prevent multiple page change - disable button until finished loading
      this.setState({
        pageNumber: newPage,
        pageFrom: this.state.pageFrom + this.state.pageSize + 1
      });
      await this.getTableData(false, null, false);
      // account for fix
      this.setState({ pageFrom: this.state.pageFrom - 1 });
    }
    // New Page less than
    else if (!this.props.isLoading && newPage < currentPage) {
      // Prevent multiple page change - disable button until finished loading
      this.setState({
        pageNumber: newPage,
        pageFrom: this.state.pageFrom - this.state.pageSize + 1
      });
      await this.getTableData(false, null, false);
      this.setState({ pageFrom: this.state.pageFrom - 1 });
    }
    // No Page change
    else {
      console.log("Loading data");
    }
  }

  async handleSortChange(params) {
    try {
      let newSortModel = [{
        field: params[0].field,
        sort: params[0].sort,
      }];

      this.setState({
        sortModel: newSortModel,
        pageFrom: 0,
        pageNumber: 0
      });
      console.log("sort change here with page: ", this.state.pageNumber);
      await this.getTableData();
    } catch {
      // No sorting, reset state for sort model
      this.setState({ sortModel: [], pageFrom: 0, pageNumber: 0 });
      await this.getTableData();
    }
  }

  sortDefaultColumns = (columns, showColumns) =>  [...showColumns, ..._.remove(columns, col => !_.includes(showColumns, col))];

  // Make this dynamic
  parseTableData(data) {
    const rows = data.reduce((acc, { bain_id, rownum, ...other }) => {
      if (bain_id) {
        acc.push({
          ...other,
          bain_id,
          id: rownum
        });
      }

      return acc;
    }, []);

    this.setState({ rows });
  }

  async updateActiveColumns() {
    let colHeaderClassName = "MuiDataGrid-columnHeaderTitle";
    let colElements = document.getElementsByClassName(colHeaderClassName);
    let columnMapperData = this.props.columnMapperData;
    let activeColumnDisplayNames = [];
    let activeColumns = [];

    colElements = Array.from(colElements);
    if (colElements.length > 0) {
      if (colElements.length > 0) {
        colElements.forEach((el) => {
          if (el.innerText === 'Revenue (EUR)' || el.innerText === 'Revenue (USD)') {
            activeColumnDisplayNames.push('Revenue');
          } else {
            activeColumnDisplayNames.push(el.innerText);
          }
        });
      }

      columnMapperData.forEach((item) => {
        let trimmedstr = item["Display Name"].replace(/ +$/, "");
        if (
          activeColumnDisplayNames.includes(item["Display Name"]) ||
          activeColumnDisplayNames.includes(trimmedstr)
        ) {
          activeColumns.push(item["Backend Name"]);
        }
      });

      this.setState({ showColumns: activeColumns });
    }
  }

  getCheckboxBainIds(rowIds, rows) {
    let companyProfileBainIds = [];
    rowIds.forEach((id) => {
      rows.forEach((row) => {
        if (row["id"] == id) {
          companyProfileBainIds.push(row["self_ids_id__cdp_"]);
        }
      })
    })
    this.props.updateParent(companyProfileBainIds);
  }

  handleSwitch = () => {
    this.setState((prevState) => ({ ...prevState, isConfidenceLevelChecked: !prevState.isConfidenceLevelChecked }));
  }

  handleRowClick = ({ row }, { target }) => {
    if (target.type === 'checkbox') {
      this.props.setSelectedCompany({
        id: row.bain_id,
        companyName: row['self_firmo_name___'],
      });
    }
  }

  handleColumnHeader = ({ field }, { target: { checked } }) => {
    if (field === '__check__') {
      const selectedCompanies = checked
        ? this.state.rows.map(({ bain_id, self_firmo_name___ }) => ({ id: bain_id, companyName: self_firmo_name___ }))
        : [];

      this.props.setSelectedCompanies(selectedCompanies);
    }
  }

  render() {
    const {
      isConfidenceLevelChecked,
      columns,
      rows,
      sortModel,
      pageSize,
      pageNumber,
      totalNumRows,
      selectionModel,
      showCompanyProfile,
    } = this.state;
    const {
      bainIds,
      isLoading,
      isSelectedAll,
      selectedCompanies
    } = this.props;
    const isMoreThenFixedOutput = bainIds.length === FIXED_ELASTIC_SEARCH_OUTPUT && bainIds.length === totalNumRows;

    if (this.props.filters.length < 1) {
      return <div>Rendering Data...</div>;
    } else {
      return (
        <div
          id="screen-grid"
          ref={this.ref}
        >
          <TableGrid
            isLoading={isLoading}
            columns={columns}
            rows={rows}
            pageSize={pageSize}
            isConfidenceLevelChecked={isConfidenceLevelChecked}
            pageNumber={pageNumber}
            sortModel={sortModel}
            totalNumRows={totalNumRows}
            selectionModel={selectionModel}
            handleSwitch={this.handleSwitch}
            countText={this.getCountText()}
            handlePageChange={this.handlePageChange}
            getCheckboxBainIds={this.getCheckboxBainIds}
            handleRowClick={this.handleRowClick}
            handleColumnHeader={this.handleColumnHeader}
            handleSortChange={this.handleSortChange}
            isSelectedAll={isSelectedAll}
            selectedCompanies={selectedCompanies}
          />
          <TableAlert
            totalNumRows={this.state.totalNumRows}
            isMoreThenFixedOutput={isMoreThenFixedOutput}
          />
        </div>
      );
    }
  }
}

export default withRouter(connect(
  ({ customScreen }) => ({
    isLoading: customScreen.isLoading,
    selectedCompanies: customScreen.selectedCompanies,
    isSelectedAll: customScreen.isSelectedAll,
    currency: customScreen.currency,
  }),
  {
    setIsLoading: actions.setIsLoading,
    setSelectedCompany: actions.setSelectedCompany,
    setSelectedCompanies: actions.setSelectedCompanines,
  },
)(ScreenerTable));
