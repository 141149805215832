import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useShallowSelector } from "../../../hooks/use-shallow-selector";
import { useModal } from "../../../hooks/use-modal";
import { MODALS } from "../../../constants";

export const SavedQueries = () => {
  const { handleOpen: openDeleteQueryModal } = useModal(MODALS.DELETE_QUERY);
  const { handleOpen: openAdvancedSearchModal } = useModal(
    MODALS.ADVANCED_SEARCH
  );
  const searchQueries = useShallowSelector(
    (state) => state.search.searchQueries
  );
  const query = searchQueries.join(" AND ");

  const handleRemove = (event) => {
    event.stopPropagation();

    const query = event.currentTarget.dataset?.query;

    openDeleteQueryModal({ query });
  };

  const handleClick = () => {
    openAdvancedSearchModal();
  };

  return query && (
    <div
      onClick={handleClick}
      className="w-fit mt-4 ml-2 flex items-center bg-[#eae6ff] p-1 rounded text-sm cursor-pointer"
      data-query={query}
    >
      <p className="text-left">{query}</p>

      <IconButton
        className="w-4 h-4 ml-2"
        onClick={handleRemove}
        data-query={query}
      >
        <CloseIcon className="w-4	h-4" />
      </IconButton>
    </div>
  );
};
