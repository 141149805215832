/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React, { Component } from "react";
import SelectedFilter from "./SelectedFilter";
import Typography from '@mui/material/Typography';
import styled from "styled-components";

const StyledTypography = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-h5": {
    margin: "16px 0px",
    whiteSpace: "normal",
    fontSize: "17px",
    lineHeight: "1.26",
    color: "#666666",
    fontWeight: "400",
    fontFamily: "Graphik, sans-serif",
    alignItems: "center",
    paddingBottom: "8px",
    paddingLeft: "16px",
    paddingTop: "2.6px",
    borderBottom: "2px solid",
    borderBottomColor: "#DDDDDD",
  }
}))

class SelectedFiltersComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialFilterData: null,
      filtersDiff: [],
      filtersSet: false,
      numericType: "",
    };
    this.createBaseFiltersDiff = this.createBaseFiltersDiff.bind(this);
    this.updateSelectedFilterArea = this.updateSelectedFilterArea.bind(this);
    this.updateFilterDiff = this.updateFilterDiff.bind(this);
  }

  createBaseFiltersDiff = () => {
    // Only create the reference object once
    if (this.props.filtersData != null) {
      if (this.state.filtersSet === false) {
        // Prevent creating a reference to same object
        let filtersData = JSON.parse(JSON.stringify(this.props.filtersData));

        this.setState(
          { initialFilterData: filtersData, filtersSet: true },
          () => {
            return;
          }
        );
      }
      // Create diff
      this.updateFilterDiff();
    }
  };

  mapSelectedFilterNames(updatedFilters) {
    let returnedFilterDiff = [];
    this.props.columnMapperData.forEach((cmData) => {
      let backendName = cmData["Backend Name"];
      let displayName = cmData["Display Name"];

      updatedFilters.forEach((filter) => {
        if (backendName === Object.keys(filter)[0]) {
          let returnFilter = {};
          let filterValue = Object.values(filter)[0];
          returnFilter[displayName] = filterValue;

          returnedFilterDiff.push(returnFilter);
        }
      });
    });

    return returnedFilterDiff;
  }

  updateFilterDiff = () => {
    if (this.state.initialFilterData != null) {
      let object = this.props.filtersData;
      let base = this.state.initialFilterData;

      let updatedFilterDiff = [];
      if (object != base) {
        base.forEach((filter) => {
          let baseKey = Object.keys(filter)[0];
          object.forEach((newFilter) => {
            let newKey = Object.keys(newFilter)[0];
            let newValues = Object.values(newFilter)[0];
            if (
              baseKey === newKey &&
              JSON.stringify(filter[baseKey]) !=
                JSON.stringify(newFilter[baseKey])
            ) {
              let changedFilter = {};
              changedFilter[newKey] = newValues;
              updatedFilterDiff.push(changedFilter);
            }
          });
        });
      }

      // map filter names per Column Mapper
      let filterDiff = this.mapSelectedFilterNames(updatedFilterDiff);
      this.setState({ filtersDiff: filterDiff }, () => {
        return;
      });
    }
  };

  updateSelectedFilterArea() {
    this.updateFilterDiff();
  }

  componentDidMount = () => {
    this.createBaseFiltersDiff();
  };

  render() {
    let { filtersDiff, numericType } = this.state;
    let filterOverrides = this.props.filterOverrides;
    if(filterOverrides && filterOverrides.length > 0) {
      filterOverrides = this.mapSelectedFilterNames(filterOverrides);
    }

    return (
      <>
        <StyledTypography
          my={2}
          variant="h5"
        >
          Selected Filters
        </StyledTypography>
        {filtersDiff.length > 0 &&
          filtersDiff.map((filter) => (
            <SelectedFilter filter={filter} numericType={numericType} override={false} />
          ))}
        {filterOverrides && filterOverrides.length > 0 &&
          filterOverrides.map((filterOverride) => (
            <SelectedFilter filter={filterOverride} numericType={numericType} override={true} />
          ))}
      </>
    );
  }
}

export default SelectedFiltersComponent;
