import React from "react";

export const IconTableColumns = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 19h-5v-9h6v8c0 .552-.449 1-1 1ZM5 18v-8h6v9H6c-.551 0-1-.448-1-1ZM6 5h12c.551 0 1 .448 1 1v2H5V6c0-.552.449-1 1-1Zm12-2H6C4.346 3 3 4.346 3 6v12c0 1.654 1.346 3 3 3h12c1.654 0 3-1.346 3-3V6c0-1.654-1.346-3-3-3Z"
      fill="#666"
    />
  </svg>
);
