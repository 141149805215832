/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import awsService from "../awsService";

const checkAuth = () => {
  return awsService.getAuthenticationStatus();
};

const GuardedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? <Component {...props} /> : <Redirect to="/" />
    }
  />
);

export default GuardedRoute;

GuardedRoute.propTypes = {
  component: PropTypes.elementType,
};
