import { createSlice } from '@reduxjs/toolkit';

import { fetchProfile } from './thunks/fetch-profile';

const initialState = {
  isLoading: false,
  profile: [],
  news: [],
  multiyear: [],
  subsidiaries: [],
  mergesAndAcquisitions: [],
};

export const slice = createSlice({
  name: 'companyProfile',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setCompanyData: (state, { payload: { profile, news, multiyear, subsidiaries, mergesAndAcquisitions } }) => {
      state.profile = profile;
      state.news = news;
      state.multiyear = multiyear;
      state.subsidiaries = subsidiaries;
      state.mergesAndAcquisitions = mergesAndAcquisitions;
    },
    clearProfile: (state) => {
      state.profile = [];
      state.news = [];
      state.multiyear = [];
      state.subsidiaries = [];
      state.mergesAndAcquisitions = [];
    },
  },
});

export const reducer = slice.reducer;
export const actions = {
  ...slice.actions,
  fetchProfile,
};
