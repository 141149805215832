import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: null,
};

export const {
  reducer, actions,
} = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
  },
});
