import _ from 'lodash';
import { Text, Transforms } from "slate";

import { SPECIAL_SEARCH_WORDS, SPECIAL_SEARCH_CHARS, QUOTES } from '../constants';

export const getTextLength = (token) => {
  if (_.isString(token)) {
    return token.length;
  } else if (_.isString(token.content)) {
    return token.content.length;
  } else {
    return token.content.reduce((l, t) => l + getTextLength(t), 0);
  }
};

const parseWithSpecialWords = (text) => text.split(/(\s+)/).map((word) => SPECIAL_SEARCH_WORDS.includes(word) ? { content: word, type: "keyword" } : word);
const parseWithSpecialChars = (items) => items.reduce((acc, item) => {
  const tst = _.isString(item)
    ? item.split('').map(char => SPECIAL_SEARCH_CHARS.includes(char) ? ({ content: char, type: 'keychar' }) : char)
    : item;

  return acc.concat(tst);
}, []);

export const tokenize = _.flow([parseWithSpecialWords, parseWithSpecialChars]);

/**
 * https://github.com/ianstormtaylor/slate/blob/main/site/examples/code-highlighting.tsx
 */
export const decorate = ([node, path]) => {
  const ranges = [];

  if (!Text.isText(node)) {
    return ranges;
  }

  const tokens = tokenize(node.text);

  let start = 0;
  for (const token of tokens) {
    const length = getTextLength(token);
    const end = start + length;

    if (!_.isString(token)) {
      ranges.push({
        [token.type]: true,
        anchor: { path, offset: start },
        focus: { path, offset: end },
      });
    }

    start = end;
  }

  return ranges;
};

// export const removeQuotes = (str) => str.replaceAll('"', '').replaceAll("'", '');

// export const withCustom = (editor) => {
//   const { insertText } = editor;

//   editor.insertData = (data) => {
//     const text = removeQuotes(data.getData("text/plain").trim());

//     Transforms.insertText(editor, text);
//   };

//   editor.insertText = (text) => {
//     if (QUOTES.includes(text)) {
//       return;
//     }

//     insertText(text)
//   }

//   return editor;
// };
