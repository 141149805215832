export const IS_DEV = process.env.NODE_ENV === 'development';

export const hideAdvancedSearch = JSON.parse(process.env.REACT_APP_HIDE_ADVANCED_SEARCH);

export const CASE_TYPES = [
  { value: 'peg-due-diligence', label: 'PEG - Due Diligence' },
  { value: 'peg-vendor-due-diligence', label: 'PEG - Vendor Due Diligence' },
  { value: 'peg-fund-strategy', label: 'PEG - Fund Strategy' },
  { value: 'peg-sector-screen', label: 'PEG - Sector Screen' },
  { value: 'peg-retainer', label: 'PEG - Retainer' },
  { value: 'peg-post-acquisition', label: 'PEG - Post Acquisition' },
  { value: 'gp-corp-ma-sector-screen', label: 'GP/Corp M&A - Sector Screen' },
  { value: 'gp-corp-ma-due-diligence', label: 'GP/Corp M&A - Due Diligence' },
  { value: 'gp-corp-ma-other', label: 'GP/Corp M&A - Other' },
  { value: 'client-development-peg', label: 'Client Development - PEG' },
  { value: 'client-development-GP', label: 'Client Development - GP' },
  { value: 'ip', label: 'IP' },
  { value: 'other', label: 'Other Case Type' },
];

export const MODALS = {
  COMPANY_OVERVIEW: 'companyOverview',
  TOO_MANY_COMPANIES: 'tooManyCompanies',
  ADVANCED_SEARCH: 'advancedSearch',
  DELETE_QUERY: 'deleteQuery',
  COMPANY_PROFILE: 'companyProfile',
};

export const SPECIAL_SEARCH_WORDS = ["NOT", "AND", "OR"];
export const SPECIAL_SEARCH_CHARS = ["?", "*", "~", "(", ")"];
export const QUOTES = ["'", '"'];

export const CURRENCIES = {
  USD: { value: 'usd', label: '$' },
  EUR: { value: 'euro', label: '€' },
};
