/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import { configureStore } from '@reduxjs/toolkit';

import { rootReducer } from './slices';
import { IS_DEV } from './constants';

export const store = configureStore({
  reducer: rootReducer,
  devTools: IS_DEV,
});
