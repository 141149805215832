/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { find, findIndex } from "lodash";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import DoubleArrow from '@mui/icons-material/DoubleArrow';
import ArrowRightRounded from '@mui/icons-material/ArrowRightRounded';
import ArrowDropDownRounded from '@mui/icons-material/ArrowDropDownRounded';
import Menu from '@mui/icons-material/Menu';
import styled from "styled-components";

import { SelectCurrency } from './SelectCurrency';
import mainLogo from "../assets/helix_find_logo.svg";
import classes from './Header.module.css'
import "../App.css";

const StyledNavigation = styled(Typography)(({ isActive }) => ({
  "&.MuiTypography-h3": {
    display: 'flex',
    margin: '0px 0px 0px 8px',
    whiteSpace: 'normal',
    color: isActive ? 'red' : '#666666',
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '1.6'
  }
}))

const SupportTypography = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-body1": {
    fontSize: '15px'
  }
}))

const EMAIL = 'helixfind@bain.com';
const LINK = 'https://bainandcompany.sharepoint.com/sites/ToolsServicesPortal/SitePages/HelixFind.aspx?OR=Teams-HL&CT=1643968453398&sourceId=&params=%7B%22AppName%22%3A%22Teams-Desktop%22%2C%22AppVersion%22%3A%2227%2F22010300408%22%7D';
const HEADER_LINKS = [
  { id: 1, href: `mailto:${EMAIL}?subject=Support`, text: 'Email us' },
  { id: 2, href: LINK, text: 'Help' },
  { id: 3, href: `mailto:${EMAIL}?subject=Training`, text: 'Request Training' },
];

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      breadCrumb: [],
      navigationScreens: [
        { title: "Quick Screen", isActive: false, routePath: "quickScreen" },
        { title: "Custom Screen", isActive: false, routePath: "customScreen" },
        { title: "Data", isActive: false, routePath: "" },
        { title: "Saved Screeners", isActive: false, routePath: "savedScreeners" },
        { title: "Swagger (API Documentation)", isActive: false, routePath: "swagger" },
      ],
      routerObject: [
        { title: "Home", routePath: "homePage" },
        { title: "Quick Screen", routePath: "quickScreen" },
        { title: "Custom Screen", routePath: "customScreen" },
        { title: "Saved Screeners", routePath: "savedScreeners" },
        { title: "Data", routePath: "" },
        { title: "Swagger (API Documentation)", routePath: "swagger" },
      ],
    };
  }

  componentDidMount = () => {
    const { history } = this.props;

    history.listen((location) => {
      let routeObject, SelectedRoute, isBreacumExist, navigationLinks;

      if (location.pathname === "/") {
        this.setState({ breadCrumb: [] });
      } else {
        SelectedRoute = location.pathname?.toString().replace("/", "");
        navigationLinks = [...this.state.breadCrumb];

        if (SelectedRoute.includes("Screener/")) {
          routeObject = {
            title: SelectedRoute.replace("Screener/", ""),
            routePath: SelectedRoute,
            isScreenerRoute: true,
          };
        } else {
          routeObject = find(this.state.routerObject, {
            routePath: SelectedRoute,
          });
        }

        isBreacumExist = find(navigationLinks, {
          routePath: routeObject.routePath,
        });

        if (!isBreacumExist) {
          navigationLinks.push(routeObject);
          this.setState({ breadCrumb: navigationLinks });
        } else {
          let routeIndex = routeObject.isScreenerRoute
            ? findIndex(this.state.breadCrumb, "isScreenerRoute")
            : findIndex(this.state.breadCrumb, {
              routePath: routeObject.routePath,
            });

          if (routeIndex >= 0) {
            navigationLinks.splice(routeIndex + 1);
            this.setState({ breadCrumb: navigationLinks });
          }
        }
      }
      //this.collapseItem(selectedPage);
    });
  };

  toggleMenu = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  collapseItem = (selectedPage) => {
    let dataCopy = this.state.navigationScreens;
    dataCopy.forEach((screener) => {
      if (screener.routePath === selectedPage) {
        screener.isActive = true;
      } else {
        screener.isActive = false;
      }
    });
    this.setState(
      {
        navigationScreens: dataCopy,
        breadCrumb: [{ title: "Home", routePath: "homePage" }],
        isOpen: false
      },
      () => {
        const { history, location } = this.props;
        if (selectedPage && location.pathname !== selectedPage)
          history.push(`/${selectedPage}`);
      }
    );
  };

  routeToSelectedPath = (link) => {
    const { history } = this.props;
    let RouteIndex = link.isScreenerRoute
      ? findIndex(this.state.breadCrumb, "isScreenerRoute")
      : findIndex(this.state.breadCrumb, { routePath: link.routePath });
    let navigationLinks = this.state.breadCrumb;
    navigationLinks.splice(RouteIndex);
    this.setState({ breadCrumb: navigationLinks }, () => {
      if (link.routePath) history.push(`/${link.routePath}`);
    });
  };

  handleOpen = () => {};

  render() {
    const { navigationScreens, isOpen, breadCrumb } = this.state;

    return (
      <>
        <div style={{ borderBottom: "1px solid #dddddd" }} data-testid="headerComponent">
          <Box p={1}>
            <Box sx={{ display: "flex" }}>
              {breadCrumb.length > 0 && (
                <div className="MenuIcon" onClick={this.toggleMenu} id="menu-div">
                  <Menu id="menu-icon" className="material-icons" fontSize="large" sx={{ cursor: 'pointer' }} />
                </div>
              )}
              <SwipeableDrawer
                position="relative"
                open={isOpen}
                onOpen={this.handleOpen}
                mt={6}
                onClose={this.toggleMenu}
                width={300}
                id="swipeable-drawer"
              >
                <Box sx={{ backgroundColor: 'white', height: '100%' }}>
                  <div className="container">
                    {navigationScreens &&
                      navigationScreens.map((screener, idx) => (
                        <div
                          key={idx}
                          onClick={(e) => this.collapseItem(screener.routePath)}
                          className="pointer"
                          id="collapse-item"
                        >
                          <Box sx={{ display: "flex", justifyContent: "space-between", width: '325px' }}>
                            <StyledNavigation
                              variant="h3"
                              isActive={screener.isActive}
                            >
                              {screener.title}
                            </StyledNavigation>
                            <span className="arrowIcon">
                              {screener.isActive ? (
                                <ArrowDropDownRounded id="down-arrow" className="material-icons" sx={{ fontSize: '60px', marginTop: '-15px', color: 'red' }} />
                              ) : (
                                <ArrowRightRounded id="right-arrow" className="material-icons" sx={{ fontSize: '60px', marginTop: '-17px' }} />
                              )}
                            </span>
                          </Box>
                        </div>
                      ))}
                  </div>
                </Box>
              </SwipeableDrawer>
              <div className="headerOptions">
                <div className="flex items-center">
                  <img
                    src={mainLogo}
                    className="mt-2"
                    style={{ width: 132, height: 46 }}
                    alt="logo"
                  />
                </div>

                {breadCrumb.length > 0 && (
                  <div className="gap-2	supportOptions">
                    {this.props.location.pathname === '/customScreen' && (
                      <SelectCurrency
                        selectClassNames="py-1 pl-2 pr-8"
                      />
                    )}

                    {HEADER_LINKS.map(({ id, href, text }) => (
                      <SupportTypography key={id} variant="body1">
                        <a href={href} className="helpLink" target="_blank" rel="noopener noreferrer">{text}</a>
                      </SupportTypography>
                    ))}
                  </div>
                )}
              </div>
            </Box>
          </Box>
        </div>
        <Box sx={{ display: "flex" }} mt={2} ml={4}>
          {breadCrumb &&
            breadCrumb.length > 1 &&
            breadCrumb.map((link, index) => (
              <div key={link.title} className="pointer">
                {link && (
                  <Box sx={{ display: "flex" }}>
                    <div
                      className={
                        index !== breadCrumb.length - 1 ? "textUnderline" : ""
                      }
                    >
                      <Typography
                        variant="small"
                        className={index === breadCrumb.length - 1 ? classes.breadcrumbSelected : classes.breadcrumbUnselected}
                        onClick={(e) => this.routeToSelectedPath(link)}
                        id={`route-${link.title}`}
                      >
                        {link.title}
                      </Typography>
                    </div>
                    {index !== breadCrumb.length - 1 && (
                      <Box mt={3.5} mb={1} mx={1} color="#666666" sx={{ marginTop: '3px' }}>
                        {" "}
                        <DoubleArrow className="material-icons" fontSize="small" />{" "}
                      </Box>
                    )}
                  </Box>
                )}
              </div>
            ))}
        </Box>
      </>
    );
  }
}

export default withRouter(Header);
