import API from "@aws-amplify/api-rest";

export const downloadCompanyOverview = async (target_id, competitor_ids, currency, email) => {
  return await API.post("CDPAPI", "/company_overview_slides", {
    body: {
      target_id,
      competitor_ids,
      currency,
      email
    }
  });
};
