/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React, { Component } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { KeyboardArrowDown, ArrowForwardIos } from '@mui/icons-material';
//import mainLogo from '../assets/PNG_AppName-TN_DIG_RED.png';
import "../App.css";
import { sectorScreenersData } from "../Data/sectorScreeners";
import { StyledButton, SavedScreenerButton, StyledTitle, StyledCardTitle, StyledCardBody, StyledStandardScreener } from './sectorScreeningStyles'

class sectorScreening extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = async () => {
    this.setState({ dataToRender: sectorScreenersData });
  };

  collapseItem = (box, selectedIndex) => {
    let dataCopy = this.state.dataToRender;
    console.log(box, selectedIndex, "data");
    dataCopy.forEach((sector, sectorIndex) => {
      if (selectedIndex === sectorIndex) {
        sector.isActive = true;
      } else {
        sector.isActive = false;
      }
    });
    this.setState({ dataToRender: dataCopy });
  };

  routeToSelectedScreener = (selectedScreener) => {
    const { history } = this.props;
    if (selectedScreener) history.push(`/Screener/${selectedScreener}`);
  };

  routeToSavedScreeners = () => {
    const { history } = this.props;
    history.push(`/savedScreeners`);
  };


  render() {
    let { dataToRender } = this.state;
    return (
      <Box m={3} position="relative" height="100%" data-testid='sectorScreeningComponent' id="sector-screening-component">
        <Box sx={{display: "flex"}} mb={2} id="box-container-1">
          <Box flex="4" id="box-container-2">
            <StyledTitle variant="h3" my={1}
              id="quick-screen"
            >
              Quick Screen
            </StyledTitle>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }} id="box-container-3">
            <Box id="box-conatiner-4">
              <SavedScreenerButton id="view-saved-screeners" mx={2} onClick={(e) => {this.routeToSavedScreeners();}}>
                View Saved Screeners
              </SavedScreenerButton>
            </Box>
          </Box>
        </Box>
        {dataToRender &&
          dataToRender.map((box, tabIndex) => (
            <Box
              sx={{borderRadius:2, backgroundColor:"#FAFAFA" }}
              p={2}
              mb={3}
              color="#666666"
              style={{boxShadow: 'rgb(47 48 50 / 14%) 0px 7px 24px'}}
              id="box-container-5"
            >
              <div onClick={(e) => this.collapseItem(box, tabIndex)} style={{ cursor: 'pointer' }} id="div-container">
                <Box sx={{display: "flex", justifyContent:"space-between"}} id="box-container-6">
                <Typography flex="5" variant="h4"
                    style={{ fontWeight: 600 }}
                    id={`${box.title}`}
                  >
                    {box.title}
                  </Typography>
                  <Box flex="1" textAlign="end" id="box-container-7">
                    <div className="arrowIcon" id="arrow-icons">
                      {box.isActive ? (
                        <KeyboardArrowDown className="material-icons" fontSize="large" id="arrow-down" />
                      ) : (
                        <ArrowForwardIos className="material-icons" id="arrow-forward" />
                      )}
                    </div>
                  </Box>
                </Box>
              </div>
              <Box sx={{display: "flex", flexDirection:"row"}} id="box-container-8">
                {box &&
                  box.isActive &&
                  box.cardData.map((card, tabIndex) => (
                    <Box p={4} id="box-conatiner-9">
                      <Card
                        variant="outlined"
                        sx={{ maxWidth: 375, 
                              height: '230px', 
                              padding: '24px', 
                              backgroundColor: 'white', 
                              color: 'rgb(102, 102, 102)', 
                              borderRadius: '16px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              boxShadow: 'rgb(47 48 50 / 14%) 0px 2px 8px' }}
                        id="card-container"
                      >
                        <CardContent sx={{ padding: '0px' }} id="card-content">
                        <StyledCardTitle variant="h5" mb={2}
                          id={`${card.title}`}
                        >
                          {card.title}
                        </StyledCardTitle>
                        <StyledCardBody
                          variant="body1"
                          mb={9}
                          id="card-body"
                        >
                          {card.bodyText}
                        </StyledCardBody>
                        </CardContent>
                        <CardActions id="card-actions">
                        <Box sx={{display: "flex", justifyContent:"space-between", width: '100%'}} id="box-container-10">
                          <StyledStandardScreener
                            variant="h6"
                            mb={2}
                            id="standard-screeners"
                          >
                            Standard Screeners
                          </StyledStandardScreener>
                          <StyledButton
                            id={`button-${card.title}`}
                            onClick={(e) => {
                              this.routeToSelectedScreener(
                                card.routeToScreener
                              );
                            }}
                          >
                            Explore
                          </StyledButton>
                        </Box>
                        </CardActions>
                      </Card>
                    </Box>
                  ))}
              </Box>
            </Box>
          ))}
      </Box>
    );
  }
}

export default sectorScreening;
