import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDialogOpen: false,
  formData: {},
};

export const {
  reducer, actions,
} = createSlice({
  name: 'caseCode',
  initialState,
  reducers: {
    openDialog: (state) => {
      state.isDialogOpen = true;
    },
    closeDialog: (state) => {
      state.isDialogOpen = false;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
  },
});
