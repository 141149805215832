import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { useShallowSelector } from "../../hooks/use-shallow-selector";
import { actions as customScreenActions } from "../../slices/custom-screen";
import { CURRENCIES } from "../../constants";

export const SelectCurrency = ({
  showLabel = false,
  parentClassNames = "",
  selectClassNames = "",
}) => {
  const dispatch = useDispatch();
  const { currency, isLoading } = useShallowSelector((state) => state.customScreen);

  const handleChange = (event) => {
    dispatch(customScreenActions.setCurrency(event.target.value));
  };

  return (
    <FormControl className={parentClassNames} disabled={isLoading}>
      {showLabel && (
        <FormLabel className="text-xs mb-2">Set currency</FormLabel>
      )}
      <Select
        className="text-black"
        id="select-currency"
        value={currency}
        onChange={handleChange}
        classes={{
          select: selectClassNames,
        }}
      >
        {Object.entries(CURRENCIES).map(([key]) => (
          <MenuItem key={key} value={key}>
            {key}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectCurrency.propTypes = {
  showLabel: PropTypes.bool,
  parentClassNames: PropTypes.string,
  selectClassNames: PropTypes.string,
};
