/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React, { Component } from "react";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { TextField, Box, Button, InputLabel } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Auth } from "@aws-amplify/auth";

import awsService from "../awsService";
import { actions as caseCodeActions } from '../slices/case-code';
import { actions as userActions } from '../slices/user';
import { fetchSecrets } from '../slices/system/thunks/fetch-secrets';

import classes from './login.module.css';
import "../App.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      isUserNameValid: false,
      userNameError: "Enter Valid username",
      passwordError: "Enter Valid password",
      isLoginFailed: false,
      loaded: false,
      loggingIn: false,
    };
  }
  // Handle flashing screen
  componentDidMount = async () => {
    const { history } = this.props;
    const res = await awsService.userLoggedIn();
    const { username: email } = await Auth.currentAuthenticatedUser();

    if (res) {
      console.log("User is logged in, redirecting");

      awsService.setAuthenticationStatus(true);
      this.setState({ loaded: true });
      history.push("/homePage");

      this.props.setEmail(email.split('_')[1]);
      this.props.openCaseCodeDialog();
      this.props.fetchSecrets();
    }
  };

  handleLogin = async () => {
    let { username, password } = this.state;
    this.setState({ loggingIn: true });
    let res = await awsService.authenticate(username, password);
    const { history } = this.props;
    if (!res) {
      console.log("Waiting for token...");
    } else if (res.status === "error") {
      this.setState({ isLoginFailed: true, loggingIn: false });
    } else {
      history.push("/homePage");
      this.setState({ loggingIn: false });
    }
  };
  handleKeypress = (event) => {
    var code = event.keyCode || event.which;
    //it triggers by pressing the enter key
    if (code === 13) {
      this.handleLogin();
    }
  };
  handleChange = (event) => {
    const name = event.target.name;
    const newValue = event.target.value;
    this.setState({ [name]: newValue });
  };
  render() {
    return (
      <LoadingOverlay
        active={this.state.loggingIn}
        spinner
        text="Loading..."
        styles={{
          overlay: (base) => ({
            ...base,
            zIndex: "9999",
          }),
        }}
      >
        <Box sx={{ display: "flex" }} p={20} data-testid='loginComponent'>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              boxShadow: "100",
              width: 315,
              borderRadius: 2
            }}
            px={3}
            py={5}
            mx="auto">
            <Typography
              variant="h1"
              mb={2}
              alignItems="center"
              fontWeight="bold"
              color="#666666"
              text-align="center"
              className={classes.loginTitle}
            >
              Welcome!
            </Typography>

            <InputLabel htmlFor="username" sx={{ fontSize: '13px', padding: '3px' }}>username</InputLabel >
            <TextField
              id="outlined-basic" variant="outlined"
              name="username"
              my="4px"
              value={this.state.username}
              onChange={this.handleChange}
              onKeyPress={this.handleKeypress}
              sx={{ backgroundColor: 'rgb(245, 245, 245) !important', maxWidth: '310px' }}
              InputProps={{
                className: 'text-input-material'
              }}
            // error={this.state.isUserNameValid}
            // helperText={this.state.isUserNameValid ? this.state.userNameError : ' '}
            />
            <Button
              id="login-button"
              mt={2}
              variant="contained"
              className="bg-cred-50"
              onClick={this.handleLogin}
              sx={{ marginTop: '20px', maxWidth: '310px' }}
            >
              Login Using SSO
            </Button>
            {this.state.isLoginFailed && (
              <Typography variant="h3" color="error" my={2} fontWeight="bold">
                Login Failed
              </Typography>
            )}
          </Box>
        </Box>
      </LoadingOverlay>
    );
  }
}

export default connect(
  null,
  {
    setEmail: userActions.setEmail,
    fetchSecrets,
    openCaseCodeDialog: () => caseCodeActions.openDialog(),
  },
)(Login);
