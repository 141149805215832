import { createAsyncThunk } from "@reduxjs/toolkit";

import { queryElasticSearchBainIds } from "../../../Api/search-api.service";
import { CURRENT_FILTERS } from "../../../Components/CompanyResearch/CompanyResearch";
import { actions } from "../index";
import _ from "lodash";

export const fetchBainIds = createAsyncThunk(
  "search/baindIds",
  async ({ searchText, unique_company = null }, { dispatch, getState }) => {
    dispatch(actions.setIsError({ value: false, key: "bainIds", status: null, message: null}));

    try {
      const {
        customScreen: { currency },
        search: { isLuceneQuery }
      } = getState();

      dispatch(actions.setIsLoading({ value: true, key: 'bainIds' }));

      const result = await queryElasticSearchBainIds({
        company_search: searchText,
        filters: CURRENT_FILTERS,
        unique_company,
        lucene_query: isLuceneQuery,
        currency,
      });

      dispatch(actions.setBainIds(result.bain_id));
      dispatch(actions.setIsLoading({ value: false, key: 'bainIds' }));
    } catch (error) {
      dispatch(actions.setIsLoading({ value: false, key: "bainIds" }));
      const hasMessage = _.hasIn(error, ['response', 'data', 'user_friendly_error', 0])
      const defaultErrorMessage = "Something's not quite right with your search."
      const message = hasMessage ? error.response.data['user_friendly_error'][0] : defaultErrorMessage;

      dispatch(actions.setIsError({ value: true, key: "bainIds", status: error.response.status, message}));
    }
  },
);
