/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
export const sectorScreenersData = [
  {
    title: "PE Primaries",
    isActive: true,
    cardData: [
      {
        title: "PE Primaries/Basic",
        bodyText:
          "Identify private companies not owned by a PE investor today. \n Allow screening by location, sector, size,  and growth                                                          ",
        routeToScreener: "PEPrimaries",
      },
      {
        title: "Carve outs/Basic",
        bodyText:
          "Identify carve-out opportunities among subsidiaries of public and private companies. Allow screening by location, sector, size,  parent profile (subsidiary count, growth, margin)",
        routeToScreener: "CarveoutsBasic",
      },
    ],
  },
  {
    title: "PE Secondaries",
    cardData: [
      {
        title: "PE Secondaries",
        bodyText:
          "Identify private companies owned by a PE investor today. Allow screening by location, sector, size, growth, and PE ownership period",
        routeToScreener: "PESecondaries",
      },
    ],
  },
  /*,
    {
        title: 'Blackstone GSO Specific',
        cardData: [
            {
                title: 'CEO/CFO Change',
                bodyText: 'Identify companies that have recently changed their CEO or CFO. Allow screening by location, sector, size,  C-level hiring & departures, news signals on financing need. ',
                routeToScreener: "CEOCFO Change",
            },
        ]
    }
    */
];
