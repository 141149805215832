/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React, { Component } from "react";
import { connect } from "react-redux";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { numberFormatterCustom, dataNotAvailable } from "../../Shared/common";
import { FormGroup, FormControl, FormControlLabel, Switch, Grid, Paper, TableContainer } from '@mui/material';
import styled from "styled-components";
import _ from "lodash";

import { CURRENCIES } from '../../../constants';

const TABLE_ORDER = ['Annual_IS', 'Annual_BS', 'Annual_OTHER', 'Quarterly_IS', 'Quarterly_BS', 'Quarterly_OTHER'];

const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    padding: '9px',
    position: 'absolute',
    color: '#fff',
    "&.Mui-checked": {
      color: '#f50057'
    },
    "&.Mui-checked": {
      "& + .MuiSwitch-track": {
        backgroundColor: '#E50627',
      }
    }
  },
  ".MuiSwitch-colorPrimary.Mui-checked": {
    color: '#E50627'
  },
  "& .MuiSwitch-thumb": {
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
  }
}))

const DisabledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    padding: '9px',
    position: 'absolute',
    color: '#bdbdbd',
  }
}))

const StyledPaper = styled(Paper)`
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

class MultiyearFinancialsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      financialPeriod: "Annual",
      tablesData: [],
      financialPeriodsAvailable: []
    }
  }

  formatNumber(val) {
    if (!val) {
      return dataNotAvailable()
    }
    else if (isNaN(val)) {
      return val
    } else {
      return numberFormatterCustom(val)
      // return val.toFixed(2).toLocaleString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  handleStatementSwitch = (event) => {
    if (this.state.financialPeriod == 'Annual') {
      this.setState({ financialPeriod: 'Quarterly' })
    } else {
      this.setState({ financialPeriod: 'Annual' })
    }
  }

  checkForQuarterlyData = () => {
    let currentTitles = this.state.financialPeriodsAvailable
    if (currentTitles.includes("Quarterly_BS") || currentTitles.includes("Quarterly_IS") || currentTitles.includes("Quarterly_OTHER")) {
      return true;
    }
    return false
  }

  formatTableTitle = (title) => {
    if (title === 'Annual_BS') {
      return 'Annual Balance Sheet'
    } else if (title === 'Annual_IS') {
      return 'Annual Income Statement'
    } else if (title === 'Quarterly_BS') {
      return 'Quarterly Balance Sheet'
    } else if (title === 'Quarterly_IS') {
      return 'Quarterly Income Statement'
    }
  }

  buildDataTable(data) {
    const { currency } = this.props;
    let columns = [];
    let rows = [];
    let title = data[0];
    let currentTitles = this.state.financialPeriodsAvailable;
    const currencyLabel = CURRENCIES[currency].label;

    if (!currentTitles.includes(title)) {
      currentTitles.push(title)
      this.setState({ financialPeriodsAvailable: currentTitles })
    }

    Object.entries(data[1]).forEach(dataPoint => {
      if (dataPoint[0] != 'statement') {
        let headerName = dataPoint[0].toUpperCase()
        if (headerName[4] == 'Q') {
          headerName = headerName.substring(0, 4) + " " + headerName.substring(4, headerName.length);
        }
        let column = {
          field: dataPoint[0],
          headerName: headerName,
          width: 150,
          renderCell: (params) => {
            const isCurrency = ['USD', 'EUR'].includes(params.row.units);
            const isComponent = !(typeof params["value"] === 'string');

            if (dataPoint[0] === 'units') {
              return <span>{isCurrency ? currency : params["value"]}</span>;
            }

            if (dataPoint[0] !== 'metric' && dataPoint[0] !== 'units') {
              return (
                <span>
                  {isComponent && params["value"]}
                  {(!isComponent && isCurrency) && `${currencyLabel}${params["value"]}`}
                  {(!isComponent && !isCurrency) && params["value"]}
                </span>
              )
            }

            return <span>{params["value"]}</span>;
          }
        }
        columns.push(column)

        let rowVals = Object.values(dataPoint[1]),
          row = {},
          x = 0,
          newKey = [dataPoint[0]]
        rowVals.forEach(rv => {
          if (rows[x] && 'id' in rows[x] && rows[x].id == x + 1) {
            rows[x][newKey] = this.formatNumber(rv)
            x++;
          } else {
            x++;
            row = {
              id: x,
              [newKey]: this.formatNumber(rv)
            }
            rows.push(row)
          }
        })
      }
    })

    //sort columns
    columns.sort(function (a, b) {
      let compareVal1 = a['field']
      let compareVal2 = b['field']
      let numbersRegex = /\d/g;
      if (!numbersRegex.test(compareVal1) || !numbersRegex.test(compareVal2)) {
        if (compareVal1 == 'metric' || compareVal1 == 'units') {
          return compareVal2.length - compareVal1.length
        }
        else if (compareVal2 == 'metric' || compareVal2 == 'units') {
          return compareVal1.length - compareVal2.length
        }
      }
      else if (numbersRegex.test(compareVal1) && numbersRegex.test(compareVal2)) {
        return compareVal1 - compareVal2
      } else {
        return compareVal1.length - compareVal2.length
      }
    })

    if (rows[0] && rows[0].hasOwnProperty('id') && title.startsWith(this.state.financialPeriod)) {
      return (
        <Grid container spacing={1} style={{ marginBottom: "20px" }}>
          <span
            id={`${this.formatTableTitle(data[0])}`}
            className="w-1/4 bg-[#fafafa] text-[#cc0000] mt-2 mb-1 text-center"
          >
            {this.formatTableTitle(data[0])}
          </span>
          <TableContainer component={StyledPaper}>
            <DataGridPro
              rows={rows}
              columns={columns}
              autoHeight={true}
              rowHeight={35}
              width={"auto"}
              disableColumnPinning={true}
            />
          </TableContainer>
        </Grid>
      )
    }
    else {
      return (
        <div></div>
      )
    }
  }

  render() {
    const { multiYear, isCompanyProfileLoading } = this.props;
    const isQuarterlyData = this.checkForQuarterlyData();

    return (
      <>
        {isCompanyProfileLoading && <div>Loading Data...</div>}
        {(!isCompanyProfileLoading && _.isEmpty(multiYear)) && <div>No multiyear data available</div>}
        {(!isCompanyProfileLoading && !_.isEmpty(multiYear)) && (
          <div>
            <FormControl id="field-set">
              <FormGroup aria-label="position" id="position" row>
                <FormControlLabel
                  value="start"
                  control={
                    isQuarterlyData
                      ? <StyledSwitch onChange={this.handleStatementSwitch} id="quarterly" />
                      : <DisabledSwitch onChange={this.handleStatementSwitch} disabled id="annual" />
                  }
                  label={this.state.financialPeriod}
                  labelPlacement="start"
                  id={isQuarterlyData ? 'start-0' : 'start-1'}
                  disabled={!isQuarterlyData}
                />
              </FormGroup>
            </FormControl>

            {TABLE_ORDER.map((tableName) => {
              const tableData = multiYear[tableName];

              return tableData && this.buildDataTable([tableName, tableData]);
            })}
          </div>
        )}
      </>
    );
  }
};

export default connect(
  (state) => ({
    currency: state.customScreen.currency,
  }),
)(MultiyearFinancialsTab);
