import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  isLoading: false,
  selectedCompanies: [],
  isSelectedAll: false,
  currency: 'USD',
  columnMapper: [],
};

export const {
  reducer, actions
} = createSlice({
  name: 'customScreen',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setSelectedCompany: (state, { payload }) => {
      const item = _.find(state.selectedCompanies, { id: payload.id });

      const result = item
        ? _.reject(state.selectedCompanies, { id: payload.id })
        : _.concat(state.selectedCompanies, payload);

      state.selectedCompanies = result;
    },
    setSelectedCompanines: (state, { payload }) => {
      state.selectedCompanies = payload;
      state.isSelectedAll = !_.isEmpty(payload);
    },
    reset: (state) => {
      state.selectedCompanies = [];
      state.isSelectedAll = false;
    },
    setCurrency: (state, { payload }) => {
      state.currency = payload;
    },
    setColumnMapper: (state, { payload }) => {
      state.columnMapper = payload;
    },
  },
});
