import React from "react";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Button from "@mui/material/Button";

import { actions } from "../../../slices/search";
import { useModal } from "../../../hooks/use-modal";
import { MODALS } from "../../../constants";

export const DeleteQueryDialog = () => {
  const dispatch = useDispatch();
  const { isOpen, handleClose } = useModal(MODALS.DELETE_QUERY);
  const { handleOpen: openAdvancedSearchDialog } = useModal(
    MODALS.ADVANCED_SEARCH
  );

  const handleRemoveSearchQuery = () => {
    dispatch(actions.removeSearchQuery());
    handleClose();
  };

  const handleOpenAdvancedSearchDialog = () => {
    openAdvancedSearchDialog();
    handleClose();
  };

  const handleCloseDialog = () => {
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      classes={{ paper: "m-0 w-[440px]" }}
    >
      <DialogTitle className="flex justify-between px-4">
        <div className="flex items-center">
          <InfoOutlinedIcon />
          <span className="ml-2.5 text-lg">Delete your query?</span>
        </div>

        <CloseIcon
          fontSize="small"
          className="opacity-40 cursor-pointer"
          onClick={handleCloseDialog}
        />
      </DialogTitle>

      <DialogContent className="px-4 pt-0 text-sm">
        <DialogContentText>
          If you delete your advanced search query we have no way of retreiving
          it for you.
        </DialogContentText>
        <DialogContentText className="mt-5">
          Would you like to edit your query instead?
        </DialogContentText>

        <div className="flex justify-end gap-2 mt-7">
          <Button
            variant="contained"
            className="text-black bg-[#ddd] font-medium"
            onClick={handleRemoveSearchQuery}
          >
            Delete
          </Button>
          <Button
            onClick={handleOpenAdvancedSearchDialog}
            variant="contained"
            className="bg-[#cc0000] font-medium"
          >
            Edit query
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
