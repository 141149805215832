/**************************************************************************
*
*     Copyright Bain & Company. 2020, 2021
*                                       
**************************************************************************/
import React from "react";
import "./NotFound.css";

export default () => (
  <div className="NotFound" data-testid="notFound">
    <h3>Sorry, page not found!</h3>
  </div>
);
