import styled from "styled-components";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export const StyledButton = styled(Button)`
  background-color: rgb(204, 0, 0);
  font-size: 14px;
  font-weight: bold;
  max-height: 35px; 
  text-transform: none;
  color: white;
  :hover {
    background-color: rgb(204, 0, 0);
  }
`;

export const SavedScreenerButton = styled(Button)`
  background-color: rgb(204, 0, 0);
  padding: 9px 50px;
  color: white;
  width: 250px;
  border-color: #cc0000;
  text-transform: none;
  font-weight: 600;
  :hover {
    background-color: rgb(204, 0, 0);
  }
`;

export const StyledTitle = styled(Typography)(({ isActive }) => ({
  "&.MuiTypography-h3": {
    lineHeight: '1.26',
    color: '#666666',
    whiteSpace: 'normal',
    fontSize: '26px'
  }
}))

export const StyledCardTitle = styled(Typography)(({ isActive }) => ({
  "&.MuiTypography-h5": {
    fontWeight: '600',
    letterSpacing: '0.15px'
  }
}))

export const StyledCardBody = styled(Typography)(({ isActive }) => ({
  "&.MuiTypography-body1": {
    whiteSpace: 'normal',
    color: '66666',
    fontWeight: '400',
    letterSpacing: '0.15px',
    marginBottom: '0px'
  }
}))

export const StyledStandardScreener = styled(Typography)(({ isActive }) => ({
  "&.MuiTypography-h6": {
    color: '#0484E7',
    whiteSpace: 'normal',
    display: 'block',
    marginTop: '6px',
    letterSpacing: '0.15px'
  }
}))