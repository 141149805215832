/**************************************************************************
 *
 *     Copyright Bain & Company. 2020, 2021
 *
 **************************************************************************/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Box, Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import Typography from "@mui/material/Typography";
import "../../App.css";
import LoadingOverlay from "react-loading-overlay";
import commonUtil from "../../Utils/commonUtil";
import ScreenerTable from "../CompanyResearch/ScreenerTable";
import ExportComponent from "../ExportComponent";
import TabsComponent from "./TabsComponent";
import API from "@aws-amplify/api-rest";
import SelectedFiltersComponent from "./SelectedFiltersComponent";
import ErrorDialog from "../Shared/ErrorDialog";
import styled from "styled-components";
import { queryElasticSearchBainIds } from "../../Api/search-api.service";

const StyledButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-root": {
    backgroundColor: 'rgb(221, 221, 221)',
    textTransform: 'none',
    fontWeight: '600',
    color: 'rgb(72, 72, 72)',
    boxShadow: 'none',
    padding: '8px 15px',
    borderRadius: '4px',
    "&:hover": {
      backgroundColor: 'rgb(221, 221, 221)'
    }
  },
}))

const StyledSearchButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-root": {
    backgroundColor: 'rgb(204, 0, 0)',
    textTransform: 'none',
    fontWeight: '600',
    color: 'white',
    padding: '8px 15px',
    borderRadius: '4px',
    "&:hover": {
      backgroundColor: 'rgb(204, 0, 0)'
    },
    "&:disabled": {
      backgroundColor: 'rgb(221, 221, 221)',
      color: 'rgb(72, 72, 72)',
      opacity: '0.5',
      cursor: 'not-allowed'
    }
  },
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-body1": {
    margin: "8px 0px 0px",
    whiteSpace: "normal",
    fontSize: "21px",
    lineHeight: "1.6",
    fontWeight: "600",
    fontFamily: "Graphik, sans-serif",
    letterSpacing: '0.00938em'
  }
}))

class StandardScreener extends Component {
  constructor(props) {
    super(props);
    this.selectedFiltersRef = React.createRef();
    this.state = {
      activeFilters: [
        "self_location_region___",
        "self_location_country___",
        "self_sector_sector___",
      ],
      initialFilters: [],
      columnMapperData: [],
      modifiedFilters: [],
      filtersWithoutTransformation: [],
      companyName: "",
      bainIds: [],
      selectedFilters: [],
      initialFilterData: [], // Used to reset a singular filters' value to it's initial state
      includeNullFilters: [],
      isLoading: true,
      throwError: false,
      hasChange: false,
      isASFPopOpen: false,
      isApplyActive: false,
      filterState: false,
      filterSorting: [
        "self_location_region___",
        "self_location_country___",
        "self_sector_sector___"
      ],
      filtersDiff: [],
      companyProfileBainIds: 0
    };
    this.updateFilterFromChild = this.updateFilterFromChild.bind(this);
    this.setHasChange = this.setHasChange.bind(this);
    this.screenerData = {};
    this.filterOverrides = null;
    this.updateParentCompanyProfileBainIds = this.updateParentCompanyProfileBainIds.bind(this);
    this.standardPages = ["PEPrimaries", "PESecondaries", "CarveoutsBasic"];
    this.callForBaneIdsUpdate = this.callForBaneIdsUpdate.bind(this);
  }

  callForBaneIdsUpdate = async () => {
    try {
      let response;

      if (this.state.checked && this.state.companyName != "") {
        response = await queryElasticSearchBainIds(this.state.companyName, this.state.selectedFilters, null, true);
      } else if (!this.state.checked && this.state.companyName != "") {
        response = await queryElasticSearchBainIds(this.state.companyName, this.state.selectedFilters, null);
      } else {
        response = null;
      }

      if (response) {
        delete response["id_count"];
        let bainIdsList = response ? Object.values(response)[0] : [];
        this.setState({ bainIds: [...bainIdsList] });
      } else {
        this.setState({ bainIds: [] });
      }
    } catch (err) {
      if (err.response.status === 502) {
        setThrowError(true);
      }
    }
  };

  updateSelectedFiltersComponent = () => {
    this.selectedFiltersRef.current.updateSelectedFilterArea();
  };

  setHasChange = async (bool) => {
    await this.setState({ hasChange: bool });
  };

  fetchFilterData = async () => {
    const { currency } = this.props;
    let data;
    let reqBody = this.buildFilterRequest();
    try {
      data = await commonUtil.fetchFiltersData(reqBody, `filters?currency=${currency}`);
    } catch (err) {
      await this.setState({ throwError: true });
    }
    let filterList = [];
    for (const [key, value] of Object.entries(data)) {
      let returnObj = {};
      returnObj[key] = value;

      filterList.push(returnObj);
    }

    let initialFilters = JSON.parse(JSON.stringify(filterList));
    if (this.filterOverrides != null) {
      initialFilters = this.handleFilterOverrides(initialFilters);
    }
    this.setState({ filtersWithoutTransformation: initialFilters }, () => {
      return;
    });

    return filterList;
  };

  async setFiltersForPage() {
    let filterNamesForPage = [];
    this.screenerData.sectionTabs.forEach((tab) => {
      tab.filterGroups.forEach((filterGroup) => {
        filterGroup.includeFilters.forEach((filter) => {
          filterNamesForPage.push(filter);
        });
      });
    });
    this.setState(
      { initialFilters: filterNamesForPage, activeFilters: filterNamesForPage },
      () => {
        return;
      }
    );
  }

  setInitialNullFilters() {
    let initialNullFilters = [];
    this.state.columnMapperData.forEach((cmdata) => {
      if (
        this.state.activeFilters.includes(cmdata["Backend Name"]) &&
        cmdata["NUMERIC_TYPE"] != null
      ) {
        initialNullFilters.push(cmdata["Backend Name"]);
      }
    });

    this.setState({ includeNullFilters: initialNullFilters }, () => {
      return;
    });
  }

  buildFilterRequest() {
    let { selectedFilters, activeFilters } = this.state;
    let returnObj = {};
    // first pass what filters to get
    returnObj["include_filters"] = activeFilters;
    // next pass the values from current filters
    if (selectedFilters) {
      selectedFilters.forEach((filter) => {
        // check for numerical
        let key = Object.keys(filter)[0];
        let value = Object.values(filter)[0];
        if (activeFilters.includes(key)) {
          if (Array.isArray(value)) {
            if (value.length != 0 && !value.includes("All")) {
              returnObj[key] = value;
            }
          } else {
            returnObj[key] = value;
          }
        }
      });
    }

    return returnObj;
  }

  async updateFilterFromChild(title, value, includeNull) {
    // update nulls first
    let currentNullFilters = this.state.includeNullFilters;
    if (includeNull) {
      if (!currentNullFilters.includes(title[0])) {
        currentNullFilters.push(title[0]);
      }
    } else {
      if (currentNullFilters.includes(title[0])) {
        currentNullFilters = currentNullFilters.filter(
          (item) => item !== title[0]
        );
      }
    }

    // handle loading here
    this.setState({ isLoading: true }, () => {
      return;
    });
    let currentFilters = this.state.selectedFilters;

    let updatedFilter;
    // Update the according filter from child by keyname
    currentFilters.forEach((filter) => {
      if (title in filter) {
        filter[title] = value;
        updatedFilter = Object.assign({}, filter);
      }
    });
    // Remove old filter
    currentFilters = currentFilters.filter((item) => !(title in item));
    // update with new filter
    currentFilters.push(updatedFilter);
    // Keep same sorting here since just updating filter value
    let sortingFilter = this.state.filterSorting;
    currentFilters.sort(function (a, b) {
      a = Object.keys(a)[0];
      b = Object.keys(b)[0];
      return sortingFilter.indexOf(a) - sortingFilter.indexOf(b);
    });
    // update Parent state
    this.setState(
      {
        selectedFilters: currentFilters,
        isLoading: false,
        includeNullFilters: currentNullFilters,
      },
      () => {
        // Set initial Data if first load, and create fitler diff
        this.updateSelectedFiltersComponent();
        return;
      }
    );
  }

  fetchSavedScreener = async (screener_id) => {
    let response = {};
    try {
      response = await API.get("CDPAPI", `/screeners/custom/${screener_id}`);

      return response;
    } catch (err) {
      await this.setState({ throwError: true });
    }
  };

  async setupPage() {
    let page = window.location.pathname.split("/");
    page = page[page.length - 1]
    let screenerData;
    if (this.standardPages.includes(page)) {
      page = page + ".json";
      try {
        screenerData = require(`../../Pages/${page}`);
      } catch (e) {
        console.log("Error Retrieving page: ", e);
        this.setState({ throwError: true });
      }
    } else {
      let data = await this.fetchSavedScreener(page);
      screenerData = data['ScreenerData']
      try {
        this.filterOverrides = data['FilterOverrides'] //Optional Param
      } catch (e) {
        console.log(e)
      }
    }
    this.screenerData = screenerData;
  }

  componentDidMount = async () => {
    await this.setupPage();
    await this.populateColumnMapperData();
    await this.resetFilters();
  };

  handleFilterOverrides = (filterList) => {
    filterList.forEach((filterItem) => {
      this.filterOverrides.forEach((filterOverride) => {
        let key = Object.keys(filterOverride)[0];
        let values = Object.values(filterOverride)[0];

        filterItem[key] = values
      })
    });

    return filterList
  }

  resetFilters = async () => {
    // ADD INTIAL FILTERS HERE
    // Reset state for filter sorting
    this.setFiltersForPage();
    //Include null filters here
    this.setInitialNullFilters();

    let initialFilters = this.state.initialFilters;
    this.setState({ isLoading: true, selectedFilters: [] }, () => {
      return;
    });
    let filterList = await this.fetchFilterData();
    // Remove all except region & country
    let initialFilterList = [];

    // Handle filter overrides
    if (this.filterOverrides != null) {
      filterList = this.handleFilterOverrides(filterList);
    }

    filterList.forEach((item) => {
      // Only 1 key in each object so grab the first
      let key = Object.keys(item)[0];
      // optionalFilterList.push(key)
      if (initialFilters.includes(key)) {
        initialFilterList.push(item);
      }
    });

    //sort results
    initialFilterList.sort(function (a, b) {
      a = Object.keys(a)[0];
      b = Object.keys(b)[0];
      return initialFilters.indexOf(a) - initialFilters.indexOf(b);
    });

    let initialFilterData = JSON.parse(JSON.stringify(initialFilterList));

    await this.setState(
      {
        selectedFilters: initialFilterList,
        initialFilterData: initialFilterData,
        isLoading: false,
        activeFilters: initialFilters,
        bainIds: [],
      },
      () => {
        this.applyFilters(true);
      }
    );
  };

  async applyFilters(isReset) {
    if (this.state.isApplyActive || isReset === true) {
      try {
        if (this.filterOverrides != null) {
          let filterOverrides = []
          this.filterOverrides.forEach((filter) => {
            filterOverrides.push(Object.keys(filter)[0])
          })
          await this.child.getTableData(true, filterOverrides);
        } else {
          await this.child.getTableData(true);
        }
        this.setState({ hasChange: false });
      } catch (error) {
        console.log("error occured while applying filters", error);
        await this.setState({ throwError: true });
      }
    }
  }

  populateColumnMapperData = async () => {
    const { currency } = this.props;
    let response = {};
    try {
      response = await API.get("CDPAPI", `/column_mapper?currency=${currency}`).then((response) => {
        // build optional filters here with FILTER_COLUMN attribute
        let allFilterNames = [];
        response["data"].forEach((item) => {
          if (item["FILTER_COLUMN"] === "Y") {
            allFilterNames.push(item);
          }
        });

        this.setState({ columnMapperData: response["data"] }, () => {
          return;
        });
      });
    } catch (err) {
      await this.setState({ throwError: true });
    }
  };

  setFilterState = async (bool) => {
    await this.setState({ filterState: bool });
  };

  updateParentCompanyProfileBainIds = (ids) => {
    this.setState({
      companyProfileBainIds: ids.map(String)
    }, () => {
      return;
    })
  };

  render() {
    let {
      selectedFilters,
      isLoading,
      filtersWithoutTransformation,
      includeNullFilters,
      columnMapperData,
      bainIds,
      throwError,
      initialFilterData
    } = this.state;

    const boxStyleRight = {
      marginBlockEnd: "auto",
      justifyContent: 'space-between',
      width: '170px'
    };

    return (
      <div>
        <Dialog
          open={this.state.isExport}
          onClose={() => this.setState({ isExport: false })}
          id="dialog-box-1"
        >
          <DialogTitle sx={{
            backgroundColor: 'white',
            color: 'black',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '115px'
          }}>
            <Typography><b>Export</b></Typography>
            <Typography>
              Would you like to export and download your results? Please note this
              launches a new pop-up/window. Please ensure pop-ups are enabled for
              this site.
            </Typography>
          </DialogTitle>
          <DialogActions sx={{ width: '570px', backgroundColor: 'white', color: 'black', padding: '15px' }}>
            <Button
              variant="outline"
              onClick={() => this.setState({ isExport: false })}
              id="cancel-button"
            >
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={() => this.exportTableauData()} id="dialog-export-button">
              Export
            </Button>
          </DialogActions>
        </Dialog>
        <ErrorDialog throwError={throwError} />

        <Box mx={3} my={2}>
          <LoadingOverlay
            active={isLoading}
            spinner
            text="Loading..."
            styles={{
              overlay: (base) => ({
                ...base,
                zIndex: "9999",
              }),
            }}
            id="loading-overlay"
          >
            <Box sx={{ display: "flex" }} mx={2}>
              <Box color="#666666" flex="90%" ml={2}>
                <StyledTypography id={`screen-${this.screenerData.pageName}`}>
                  {this.screenerData.pageName}
                </StyledTypography>
              </Box>
              <Box flex="none" display="flex" mt="16px" style={boxStyleRight}>
                <StyledButton onClick={(e) => this.resetFilters()} id="reset-button">
                  Reset
                </StyledButton>
                <StyledSearchButton
                  // color={this.state.hasChange ? "primary" : "secondary"}
                  disabled={!this.state.hasChange ? true : false}
                  onClick={(e) => this.applyFilters(true)}
                  id="search-button"
                >
                  Search
                </StyledSearchButton>
              </Box>
              <Box
                color="#666666"
                flex="none"
                marginTop="16px"
                marginRight="20px"
                mx={2}
              >
                <ExportComponent
                  selectedFilters={(this.state.companyProfileBainIds.length > 0) ? [] : selectedFilters}
                  includeNullFilters={(this.state.companyProfileBainIds.length > 0) ? [] : includeNullFilters}
                  bainIds={(this.state.companyProfileBainIds.length > 0) ? [] : bainIds}
                  companyProfileIds={(this.state.companyProfileBainIds.length > 0) ? this.state.companyProfileBainIds : []}
                  isCompanyProfile={false}
                />
              </Box>
            </Box>
            {/* TAB CONTENT HERE */}
            <Box id="screener-filters" sx={{ display: "flex", label: "Title here", justifyContent: "center" }} mt={1} ml={4} mr={4}>
              <Box width="66%">
                {columnMapperData.length > 0 && selectedFilters.length > 0 && (
                  <TabsComponent
                    screenerData={this.screenerData}
                    filtersData={selectedFilters}
                    columnMapperData={columnMapperData}
                    initialFilters={filtersWithoutTransformation}
                    updateParent={this.updateFilterFromChild}
                    setHasChange={this.setHasChange}
                    initialFilterData={initialFilterData}
                    filterOverrides={this.filterOverrides}
                    setFilterState={this.setFilterState}
                  />
                )}
              </Box>
              <Box width="34%" marginLeft="15px">
                {columnMapperData.length > 0 && selectedFilters.length > 0 && (
                  <SelectedFiltersComponent
                    filtersData={selectedFilters}
                    ref={this.selectedFiltersRef}
                    columnMapperData={columnMapperData}
                    filterOverrides={this.filterOverrides}
                  />
                )}
              </Box>
            </Box>
            {/* Data Table Starts Here */}
            <Box borderTop="1px solid #dddddd" my={2} mx={[1, 2, 3, 4]}>
              <ScreenerTable
                filters={selectedFilters}
                bainIds={bainIds}
                columnMapperData={columnMapperData}
                includeNullFilters={includeNullFilters}
                childRef={(ref) => (this.child = ref)}
                updateParent={this.updateParentCompanyProfileBainIds}
              />
            </Box>
          </LoadingOverlay>
        </Box>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    currency: state.customScreen.currency,
  }),

)(StandardScreener);

