import { withStyles } from "@mui/styles";

export const GlobalCss = withStyles({
  "@global": {
    ".MuiDataGrid-root": {
      color: 'black',
    },
    ".MuiDataGrid-overlay": {
      backgroundColor: 'white'
    },
    ".MuiPaper-root": {
      color: 'rgba(0, 0, 0, 0.87)',
      backgroundColor: 'white'
    },
    ".MuiMenuItem-root.Mui-selected, .MuiButtonBase-root-MuiMenuItem-root:hover": {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    ".MuiMenuItem-root.Mui-selected": {
      "&:hover": {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
    ".MuiDataGrid-row.Mui-selected": {
      backgroundColor: 'rgba(63, 81, 181, 0.08) !important'
    },
    ".MuiNativeSelect-select option": {
      backgroundColor: 'white !important'
    },
    ".MuiInput-root:hover:not(.Mui-disabled):before": {
      borderBottom: '2px solid black'
    },
    ".MuiDataGrid-paper": {
      backgroundColor: 'white',
      boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',

      "& .MuiSwitch-root": {
        overflow: 'inherit',
      },
      "& .MuiSwitch-switchBase": {
        position: 'absolute !important'
      },
      "& .MuiSwitch-colorPrimary": {
        color: 'white'
      },
      "& .MuiSwitch-colorPrimary.Mui-checked": {
        color: '#3f51b5'
      },
      "& .MuiSwitch-thumb": {
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      },
      "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
        backgroundColor: '#3f51b5'
      },
      "& .MuiButton-textPrimary": {
        color: '#3f51b5',
        textTransform: 'uppercase',
      },
      "& .MuiButton-textSizeSmall": {
        fontSize: '13px',
        textTransform: 'uppercase',
        padding: '4px 5px'
      },
      "& .MuiButton-root": {
        fontWeight: '500',
        "&:hover": {
          backgroundColor: 'rgba(63, 81, 181, 0.04)'
        }
      },
      "& .MuiInput-input": {
        color: 'black'
      },
      "& .MuiFormLabel-root.Mui-focused": {
        fontSize: '16px'
      },
    },
  },
})(() => null);
